import React, { PropsWithChildren, useEffect, useState } from 'react';
import {
  BrowserRouter,
  HashRouter,
  Navigate,
  Route,
  Routes,
  useNavigate,
} from 'react-router-dom';
// import { Aaaa } from '../../../_template/dist/lib';
import {
  Mode4,
  AppProvider,
  Mode1,
  Mode2,
  Mode3,
  Mode5,
  Mode6,
} from '@mono/display-clock';
import { RickAndMortyApp } from '@mono/rick-and-morty';
import { Mode8, Mode8With } from '@mono/form';
import { fonts } from '@mono/fonts';
import '@mono/fonts/dist/lib/index.css';

const aaa = new URL('@mono/story/dist/index.html', import.meta.url).href;
import { Providers } from '@mono/rick-and-morty';
// import s from '@mono/storybrouchure';
const rawHTML = `
<div>
  <p>The <strong>rat</strong> hates the <strong>cat</strong></p>
  <p><i>This is something special</i></p>
  <div>
  </div>
  <h1>H1</h1>
  <h2>H2</h2>
  <h3>H3</h3>
  <h4>Just Another Heading</h4>
</div>
`;
const Story = () => {
  const [htmlFileString, setHtmlFileString] = useState('');

  async function fetchHtml() {
    setHtmlFileString(await (await fetch(`./dist/index.html`)).text());
  }
  useEffect(() => {
    fetchHtml();
    // setHtmlFileString(rawHTML);
  }, []);

  return (
    <div
      className='App'
      style={{
        color: 'red',
      }}
    >
      <iframe dangerouslySetInnerHTML={{ __html: htmlFileString }} />
    </div>
  );
};

const Story2 = () => {
  // window.location.href = '/path-to-your-html-file.html';

  // This does not work when building locally, then serve the build folder
  // It works when the app is served from a server
  // It works when running locally
  return (
    <div>
      <iframe
        // src={aaa}
        src={`story/index.html`}
        style={{ width: '100%', height: '100vh', border: 'none' }}
      />
    </div>
  );
};
const rickandmorty = '/rick-and-morty';
const mode1 = '/mode-1';
const mode2 = '/mode-2';
const mode3 = '/mode-3';
const mode4 = '/clock';
const mode5 = '/mode-5';
const mode6 = '/mode-6';
const mode8 = '/form';
const Navigator = () => {
  const navigate = useNavigate();

  return (
    <div>
      <button onClick={() => navigate('/')}>Root</button>
      <button onClick={() => navigate(rickandmorty)}>Rick</button>
      <button onClick={() => navigate(mode1)}>Mode-1</button>
      <button onClick={() => navigate(mode2)}>Mode-2</button>
      <button onClick={() => navigate(mode3)}>Mode-3</button>
      <button onClick={() => navigate(mode4)}>Mode-4</button>
      <button onClick={() => navigate(mode5)}>Mode-5</button>
      <button onClick={() => navigate(mode6)}>Mode-6</button>
      <button onClick={() => navigate(mode8)}>Mode-8</button>
    </div>
  );
};
export function AppRouter(): JSX.Element {
  return (
    <HashRouter>
      {/* <Navigator /> */}
      <Routes>
        {/* <Route path={'/'} element={<div>bbb</div>} /> */}
        <Route path='/story' element={<Story2 />} />
        {/* <Route path={'/a'} element={<Aaaa />} /> */}
        <Route
          path={rickandmorty}
          element={
            <Providers>
              <div style={{ fontSize: '16px' }}>
                <RickAndMortyApp />
              </div>
            </Providers>
          }
        />

        <Route
          path={mode1}
          element={
            <div style={{ fontFamily: fonts.font6 }}>
              <AppProvider>
                <div style={{ fontSize: '16px' }}>
                  <Mode1 />
                </div>
              </AppProvider>
            </div>
          }
        />
        <Route
          path={mode2}
          element={
            <div style={{ fontFamily: fonts.font6 }}>
              <AppProvider>
                <div style={{ fontSize: '16px' }}>
                  <Mode2 />
                </div>
              </AppProvider>
            </div>
          }
        />
        <Route
          path={mode3}
          element={
            <div style={{ fontFamily: fonts.font6 }}>
              <AppProvider>
                <div style={{ fontSize: '16px' }}>
                  <Mode3 />
                </div>
              </AppProvider>
            </div>
          }
        />
        <Route
          path={mode4}
          element={
            <div style={{ fontFamily: fonts.font6 }}>
              <AppProvider>
                <div style={{ fontSize: '16px' }}>
                  <Mode4 />
                </div>
              </AppProvider>
            </div>
          }
        />
        <Route
          path={mode5}
          element={
            <div style={{ fontFamily: fonts.font6 }}>
              <AppProvider>
                <div style={{ fontSize: '16px' }}>
                  <Mode5 />
                </div>
              </AppProvider>
            </div>
          }
        />
        <Route
          path={mode6}
          element={
            <div style={{ fontFamily: fonts.font6 }}>
              <AppProvider>
                <div style={{ fontSize: '16px' }}>
                  <Mode6 />
                </div>
              </AppProvider>
            </div>
          }
        />
        <Route
          path={mode8}
          element={
            <div style={{ fontFamily: fonts.font6 }}>
              <div>
                <Mode8With />
              </div>
            </div>
          }
        />
        <Route path={'/'} element={<Navigate replace to={mode4} />} />
      </Routes>
    </HashRouter>
  );
}
