'use client'
var __defProp = Object.defineProperty;
var __name = (target, value) => __defProp(target, "name", { value, configurable: true });
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};

// src/use-debounce.ts
import { useEffect, useState } from "react";
function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);
    return () => clearTimeout(handler);
  }, [value, delay]);
  return debouncedValue;
}
__name(useDebounce, "useDebounce");

// src/use-local-storage/use-local-storage.ts
import { useEffect as useEffect2, useState as useState2 } from "react";
function useLocalStorage(name, initialValue) {
  const windowGlobal = typeof window !== "undefined" && window;
  const [value, setValue] = useState2(() => {
    if (windowGlobal) {
      if (initialValue) {
        const currentValue = windowGlobal.localStorage.getItem(name);
        if (currentValue != null && currentValue !== "") {
          return currentValue ? JSON.parse(currentValue) : initialValue;
        }
      }
    }
    return initialValue;
  });
  useEffect2(() => {
    if (windowGlobal) {
      if (value) {
        windowGlobal.localStorage.setItem(name, JSON.stringify(value));
      }
    }
  }, [name, value, windowGlobal]);
  return [value, setValue];
}
__name(useLocalStorage, "useLocalStorage");

// src/use-press-key/use-key-press.ts
import { useCallback, useEffect as useEffect3, useState as useState3 } from "react";
function useKeyPress(targetKey) {
  const [keyPressed, setKeyPressed] = useState3(false);
  const onKeyDown = useCallback(
    (_0) => __async(this, [_0], function* ({ key }) {
      if (key === targetKey) {
        setKeyPressed(true);
      }
    }),
    [targetKey]
  );
  const onKeyUp = useCallback(
    (_0) => __async(this, [_0], function* ({ key }) {
      if (key === targetKey) {
        setKeyPressed(false);
      }
    }),
    [targetKey]
  );
  useEffect3(() => {
    window.addEventListener("keydown", onKeyDown);
    window.addEventListener("keyup", onKeyUp);
    return () => {
      window.removeEventListener("keydown", onKeyDown);
      window.removeEventListener("keyup", onKeyUp);
    };
  }, [onKeyDown, onKeyUp]);
  return keyPressed;
}
__name(useKeyPress, "useKeyPress");

// src/date-ref/date-ref.tsx
import React, { useCallback as useCallback2 } from "react";
import { useEffect as useEffect4, useState as useState4 } from "react";
import { jsx } from "react/jsx-runtime";
function useDateRef(p) {
  const [date, setDate] = useState4(p.mockDate ? p.mockDate : p.date);
  useEffect4(() => {
    const timerID = setInterval(() => tick(), p.interval);
    return /* @__PURE__ */ __name(function cleanup() {
      clearInterval(timerID);
    }, "cleanup");
  });
  return { date };
  function tick() {
    if (p.mockDate) {
      setDate(p.mockDate + p.interval);
    } else {
      setDate((/* @__PURE__ */ new Date()).valueOf());
    }
  }
  __name(tick, "tick");
}
__name(useDateRef, "useDateRef");
export {
  useDateRef,
  useDebounce,
  useKeyPress,
  useLocalStorage
};
