'use client'
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __name = (target, value) => __defProp(target, "name", { value, configurable: true });
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};

// src/locale/get-local-files.ts
import { getLocaleFiles as getIntlLocaleFiles } from "@mono/locale";

// src/locale/translations/languages/sv.json
var sv_default = {
  "common.other.dark": "--- Cancel",
  "common.other.light": "--- OK"
};

// src/locale/translations/languages/en.json
var en_default = {
  "common.other.dark": "Cancel",
  "common.other.light": "OK"
};

// src/locale/get-local-files.ts
function getLocaleFiles() {
  const localeMessages = getIntlLocaleFiles();
  return {
    sv: __spreadValues(__spreadValues({}, localeMessages.sv), sv_default),
    en: __spreadValues(__spreadValues({}, localeMessages.en), en_default)
  };
}
__name(getLocaleFiles, "getLocaleFiles");

// src/app/Providers.tsx
import { LocaleProvider } from "@mono/locale";
import { jsx } from "react/jsx-runtime";
var Providers = /* @__PURE__ */ __name((props) => {
  const translations = getLocaleFiles();
  return /* @__PURE__ */ jsx(LocaleProvider, { translations, children: props.children });
}, "Providers");

// src/features/rick-and-morty/RickAndMortyWrapper.tsx
import { Provider } from "react-redux";

// src/features/rick-and-morty/theme/global-style/GlobalStyle.ts
import { createGlobalStyle } from "styled-components";

// src/features/rick-and-morty/theme/global-style/root-style.ts
import { css } from "styled-components";
var rootStyle = css`
  body {
    background-color: ${(props) => props.theme.color.foundation};
    color: ${(props) => props.theme.color.primary};
    margin: 0px;
    padding: 0px;
    font-family: monospace, monospace;
    overflow: hidden;
  }
`;

// src/features/rick-and-morty/theme/global-style/scrollbar-style.ts
import { css as css2 } from "styled-components";
var scrollbarStyle = /* @__PURE__ */ __name((params) => css2`
  /* SCROLLBARS -- START */
  /* https://www.digitalocean.com/community/tutorials/css-scrollbars */

  /* Works on Firefox */
  * {
    scrollbar-gutter: unset;
    scrollbar-gutter: auto;
    //scrollbar-width: thin;
    scrollbar-color: ${params.thumb} ${params.track};
  }

  /* Works on Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 12px;
    background: green;
  }

  *::-webkit-scrollbar-track {
    background: ${params.track};
    width: 5px;
  }

  *::-webkit-scrollbar-thumb {
    background-color: ${params.thumb};
    border-radius: 20px;
    // width: 5px;
    //border: 3px solid ${(props) => props.theme.color.foundation};
  }

  /* *::-webkit-scrollbar-thumb:hover {
    background: rgb(100, 100, 100);
    border-radius: 10px;
  } */

  /* SCROLLBARS -- END */
`, "scrollbarStyle");

// src/features/rick-and-morty/theme/global-style/GlobalStyle.ts
var RMGlobalStyle = createGlobalStyle`
  ${rootStyle}

  ${(props) => scrollbarStyle({
  thumb: props.theme.color.bg1,
  track: props.theme.color.foundation
})}
`;

// src/features/rick-and-morty/RickAndMortyPage.tsx
import React6, {
  useEffect as useEffect4,
  useMemo,
  useRef as useRef2,
  useState as useState5
} from "react";

// src/@app/@app-api/src/axios.ts
import axios from "axios";
var axiosGet = axios.create({
  // baseURL: config.baseUrl,
  // params: {
  //   key: config.apiKey,
  // },
  // headers: getHeaders(),
});

// src/@app/@app-api/src/get/get.ts
function apiGet(params) {
  return __async(this, null, function* () {
    try {
      const { url, query } = params;
      const response = yield axiosGet.get(url, { params: query });
      return response.data;
    } catch (error) {
      console.error(error);
      throw "Request error";
    }
  });
}
__name(apiGet, "apiGet");

// src/features/rick-and-morty/api/urls.ts
var urls = {
  baseUrl: "https://rickandmortyapi.com/api",
  character: "/character"
};

// src/features/rick-and-morty/api/get/character.api.ts
function getCharacter(params) {
  return __async(this, null, function* () {
    if (params) {
      const { query } = params;
      return apiGet({ url: `${urls.baseUrl}${urls.character}`, query });
    }
    return apiGet({ url: `${urls.baseUrl}${urls.character}` });
  });
}
__name(getCharacter, "getCharacter");

// src/features/rick-and-morty/hooks/use-query.tsx
import { useCallback, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
function useQueryWithLocationSearch() {
  return new URLSearchParams(useLocation().search);
}
__name(useQueryWithLocationSearch, "useQueryWithLocationSearch");

// src/features/rick-and-morty/RickAndMortyPage.tsx
import { useNavigate as useNavigate2 } from "react-router-dom";

// src/features/rick-and-morty/RickAndMortyContent.tsx
import { useState as useState3 } from "react";

// src/features/rick-and-morty/components/image/AppImage.tsx
import { useEffect as useEffect2, useState } from "react";

// src/features/rick-and-morty/components/image/styled.tsx
import styled, { css as css3 } from "styled-components";

// src/@app/@app-utils/src/convert-to-rgba.ts
function convertToRGB2(string) {
  let apa = string;
  if (apa.startsWith("#")) {
    apa = apa.replace("#", "");
  }
  if (apa.length != 6) {
    throw "Only six-digit hex colors are allowed.";
  }
  const aRgbHex = apa.match(/.{1,2}/g);
  const aRgb = [
    parseInt(aRgbHex[0], 16),
    parseInt(aRgbHex[1], 16),
    parseInt(aRgbHex[2], 16)
  ];
  return aRgb;
}
__name(convertToRGB2, "convertToRGB2");
function convertToRGBA(string, alpha) {
  if (alpha) {
    return `rgb(${convertToRGB2(string)},${alpha})`;
  }
  return `rgb(${convertToRGB2(string)})`;
}
__name(convertToRGBA, "convertToRGBA");

// src/@app/@app-utils/src/get-border-svg-url.tsx
var stroke = 4;
var borderRadious = 10;
var dashArray = [12, 4, 5];
var lineCap = "butt" /* butt */;
var a0 = `url(`;
var a1 = `"data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none'`;
var a2 = `rx='${borderRadious}' ry='${borderRadious}'`;
var a3 = /* @__PURE__ */ __name((c) => `stroke='${c}'`, "a3");
var a4 = `stroke-width='${stroke}'`;
var a5 = `stroke-dasharray='${dashArray.join("%2c")}'`;
var a6 = /* @__PURE__ */ __name((offset) => `stroke-dashoffset='${offset}'`, "a6");
var a7 = `stroke-linecap='${lineCap}'`;
var a8 = `/%3e%3c/svg%3e"`;
var a9 = `)`;
function getBorderSvgUrl(p) {
  return `
    ${a0}
    ${a1}
    ${a2}
    ${a3(p.color)}
    ${a4}
    ${a5}
    ${a6(p.offset)}
    ${a7}
    ${a8}
    ${a9}
    `;
}
__name(getBorderSvgUrl, "getBorderSvgUrl");

// src/features/rick-and-morty/components/image/styled.tsx
var imageCss = /* @__PURE__ */ __name((params) => css3`
  min-width: ${params.width};
  max-width: ${params.width};
  min-height: ${params.width};
  max-height: ${params.width};
  border-radius: 10px;
  //border: 1px ${(p) => p.theme.color.mark} dashed;
  background-color: ${(props) => params.isBright ? props.theme.color.bg1 : props.theme.color.bg1};
  //padding: 10px;
`, "imageCss");
var StyledFakeImage = styled.div`
  ${(p) => imageCss({ width: p.$width, isBright: p.$isBright })}
  display: flex;
  justify-content: center;
  align-items: center;
`;
var StyledImage = styled.img`
  ${(p) => imageCss({ width: p.$width, isBright: p.$isBright })}
  display: flex;
  justify-content: center;
  align-items: center;
`;
var StyledImageWrapped = styled.span`
  border: 1px ${(p) => p.theme.color.mark} dashed;
  padding: 2px;
  border-radius: 10px;
  min-height: 150px;
  max-height: 150px;
  background-image: ${(p) => getBorderSvgUrl({ color: convertToRGBA(p.theme.color.mark), offset: 3 })};
`;

// src/features/rick-and-morty/components/image/AppImage.tsx
import { jsx as jsx2 } from "react/jsx-runtime";
var AppImage = /* @__PURE__ */ __name((props) => {
  return /* @__PURE__ */ jsx2(StyledImageWrapped, { children: /* @__PURE__ */ jsx2(AppImageWrapped, __spreadValues({}, props)) });
}, "AppImage");
var AppImageWrapped = /* @__PURE__ */ __name((props) => {
  const { src, width, isBright, disableFetch = false } = props;
  if (disableFetch) {
    return /* @__PURE__ */ jsx2(StyledFakeImage, { $width: width, $isBright: isBright, children: "tmp" });
  }
  return /* @__PURE__ */ jsx2(AppImageWithFetch, { width, src, isBright });
}, "AppImageWrapped");
var AppImageWithFetch = /* @__PURE__ */ __name((props) => {
  const { width, src, isBright } = props;
  const [imgSrc, setImgSrc] = useState(src);
  const fetchImage = createFetchImage(src);
  useEffect2(() => {
    fetchImage().then((x) => setImgSrc(src && x));
  }, []);
  if (imgSrc === null) {
    return /* @__PURE__ */ jsx2(StyledFakeImage, { $width: width, $isBright: isBright, children: "tmp" });
  }
  return /* @__PURE__ */ jsx2(StyledImage, { $isBright: true, $width: width, src: imgSrc }, imgSrc);
}, "AppImageWithFetch");
function createFetchImage(src) {
  return () => __async(this, null, function* () {
    const res = yield fetch(src);
    const imageBlob = yield res.blob();
    return URL.createObjectURL(imageBlob);
  });
}
__name(createFetchImage, "createFetchImage");

// src/features/rick-and-morty/components/character/StyledCard.tsx
import styled2 from "styled-components";
var StyledCard = styled2.div`
  margin: ${(p) => p.theme.sizes.large};
  display: inline-block;
  background-color: ${(p) => p.theme.color.bg1};
  border: 1px ${(p) => p.theme.color.mark} dashed;

  border-radius: ${(p) => p.theme.sizes.larger};
  max-width: ${(p) => p.$maxWidth};
  min-width: ${(p) => p.$minWidth};

  background-image: ${(p) => getBorderSvgUrl({ color: convertToRGBA(p.theme.color.mark), offset: 4 })};
`;

// src/features/rick-and-morty/components/character/StyledRow.tsx
import styled3 from "styled-components";
var StyledRow = styled3.div`
  display: flex;
  flex-direction: row;
  justify-content: ${(p) => p.$justifyContent};
  margin: ${(p) => p.theme.sizes.large};
`;

// src/features/rick-and-morty/components/character/StyledColumn.tsx
import styled4 from "styled-components";
var StyledColumn = styled4.div`
  display: flex;
  flex-direction: column;
  /* justify-content: start; */
  /* align-items: flex-start; */
  margin-left: ${(p) => p.theme.sizes.large};
`;

// src/features/rick-and-morty/components/character/StyledDivider.tsx
import styled5 from "styled-components";
var StyledDivider = styled5.div`
  height: ${(p) => p.theme.sizes.medium};
`;

// src/features/rick-and-morty/components/StyledText.tsx
import styled6 from "styled-components";
var StyledText = styled6.span`
  font-size: ${"small" /* small */};
  border-radius: 0px;
  padding-left: ${(p) => p.theme.sizes.medium};
  white-space: normal;
  word-break: break-all;
`;

// src/features/rick-and-morty/components/text/BoldText.tsx
import { jsx as jsx3, jsxs } from "react/jsx-runtime";
var BoldText = /* @__PURE__ */ __name((props) => {
  const { text } = props;
  const style = {
    fontWeight: "bolder",
    fontSize: "small" /* small */
  };
  return /* @__PURE__ */ jsx3("span", { style, children: text });
}, "BoldText");
var TextWithLabel = /* @__PURE__ */ __name((props) => {
  const { label, description } = props;
  return /* @__PURE__ */ jsxs("span", { children: [
    /* @__PURE__ */ jsx3(BoldText, { text: label }),
    ":",
    /* @__PURE__ */ jsx3(StyledText, { children: description })
  ] });
}, "TextWithLabel");

// src/features/rick-and-morty/components/character/Character.tsx
import { Fragment, jsx as jsx4, jsxs as jsxs2 } from "react/jsx-runtime";
var Character = /* @__PURE__ */ __name(({
  character,
  isBright,
  onClick,
  extended = false,
  size: size2 = "550px"
}) => {
  return /* @__PURE__ */ jsxs2(
    StyledCard,
    {
      onClick,
      $maxWidth: size2,
      $minWidth: size2,
      children: [
        /* @__PURE__ */ jsxs2(StyledRow, { $justifyContent: "flex-start", children: [
          /* @__PURE__ */ jsx4(
            AppImage,
            {
              width: "150px",
              src: character.image,
              isBright,
              disableFetch: false
            },
            character.id
          ),
          /* @__PURE__ */ jsxs2(StyledColumn, { style: { backgroundColor: "unset" }, children: [
            /* @__PURE__ */ jsx4(BoldText, { text: character.name }),
            /* @__PURE__ */ jsx4(StyledDivider, {}),
            /* @__PURE__ */ jsx4(TextWithLabel, { label: "Gender", description: character.gender }),
            /* @__PURE__ */ jsx4(TextWithLabel, { label: "Status", description: character.status }),
            /* @__PURE__ */ jsx4(TextWithLabel, { label: "Species", description: character.species }),
            /* @__PURE__ */ jsx4(TextWithLabel, { label: "Id", description: character.id.toString() })
          ] })
        ] }),
        extended ? /* @__PURE__ */ jsx4(Extended, { character }) : /* @__PURE__ */ jsx4(Fragment, {})
      ]
    },
    character.id
  );
}, "Character");
var Extended = /* @__PURE__ */ __name((props) => {
  const { character } = props;
  return /* @__PURE__ */ jsxs2(StyledColumn, { style: { backgroundColor: "unset" }, children: [
    /* @__PURE__ */ jsx4(Origin, { label: "Origin", extended: character.origin }),
    /* @__PURE__ */ jsx4(StyledDivider, {}),
    /* @__PURE__ */ jsx4(TextWithLabel, { label: "episodes", description: `${character.episodes}` })
  ] });
}, "Extended");
var Origin = /* @__PURE__ */ __name((props) => {
  const { extended, label } = props;
  return /* @__PURE__ */ jsxs2(Fragment, { children: [
    /* @__PURE__ */ jsx4(BoldText, { text: label }),
    /* @__PURE__ */ jsxs2(StyledColumn, { style: { backgroundColor: "unset" }, children: [
      /* @__PURE__ */ jsx4(TextWithLabel, { label: "name", description: `${extended.name}` }),
      /* @__PURE__ */ jsx4(TextWithLabel, { label: "url", description: `${extended.url}` })
    ] })
  ] });
}, "Origin");

// src/features/rick-and-morty/components/StyledCenter2.tsx
import styled7 from "styled-components";
var StyledCenter2 = styled7.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  overflow: auto;
  height: ${(p) => `calc(${p.$size}px - 70px)`};
`;

// src/@app/@app-redux/hooks/use-redux.ts
import { useDispatch, useSelector } from "react-redux";
var useAppDispatch = /* @__PURE__ */ __name(() => useDispatch(), "useAppDispatch");
var useAppSelector = useSelector;

// src/@app/@app-redux/slices/screen/screen-slice.ts
import { createSlice } from "@reduxjs/toolkit";
var initialState = {
  height: null
};
var screenSizeSlice = createSlice({
  name: "screen-size",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setHeight: (state, action) => {
      console.warn("PAYLOAD", action.payload);
      state.height = action.payload;
    }
  }
});
var { setHeight } = screenSizeSlice.actions;
var screenReducer = screenSizeSlice.reducer;
var selectHeight = /* @__PURE__ */ __name((state) => state.screen.height, "selectHeight");

// src/@app/@app-theme/src/theme/brightness/brightness.constant.ts
var brightnessKey = `brightness`;

// src/features/rick-and-morty/pages/character/CharacterPage.tsx
import { jsx as jsx5 } from "react/jsx-runtime";
var CharacterModal = /* @__PURE__ */ __name((props) => {
  const { character } = props;
  const size2 = useAppSelector(selectHeight);
  const themeBright = useAppSelector((state) => state.theme.brightness);
  const isBright = themeBright === "light" /* light */;
  if (character === null) {
    return /* @__PURE__ */ jsx5("div", { children: "no character provided" });
  }
  return /* @__PURE__ */ jsx5(StyledCenter2, { $size: size2 || 0, children: /* @__PURE__ */ jsx5(
    Character,
    {
      onClick: () => console.log("clcik"),
      character,
      isBright,
      extended: true,
      size: "350px"
    }
  ) });
}, "CharacterModal");

// src/features/rick-and-morty/components/Modal.tsx
import { useState as useState2 } from "react";
import styled8 from "styled-components";
import { jsx as jsx6, jsxs as jsxs3 } from "react/jsx-runtime";
var Modal = /* @__PURE__ */ __name((props) => {
  const { handleClose, show } = props;
  const [showX, setShowX] = useState2(false);
  return /* @__PURE__ */ jsx6(StyledModal, { $show: show, onClick: handleClose, children: /* @__PURE__ */ jsxs3(StyledModalMain, { children: [
    /* @__PURE__ */ jsx6(
      RoundButton,
      {
        onMouseEnter: () => setShowX(!showX),
        onMouseLeave: () => setShowX(!showX),
        $showX: showX,
        onClick: handleClose,
        children: showX ? "x" : unicode.circle
      }
    ),
    /* @__PURE__ */ jsx6("div", { onClick: (e) => e.stopPropagation(), children: props.children })
  ] }) });
}, "Modal");
var StyledModal = styled8.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: ${(p) => p.$show ? "block" : "none"};
`;
var StyledModalMain = styled8.section`
  position: fixed;
  // background: white;

  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
var StyledRoundButton = styled8.div`
  /* padding: 20px; */
  border-radius: 100%;
  :hover {
    background-color: ${(p) => convertToRGBA(p.theme.color.mark, 0.3)};
  }
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
var StyledRoundButtonInner = styled8.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 30px;
  /* padding: 10px; */
  border-radius: 100%;
  color: ${(p) => p.theme.color.primary};
  background-color: ${(p) => p.theme.color.bg1};
  border: 1px ${(p) => p.theme.color.mark} solid;
  font-size: ${(p) => p.$showX ? "14px" : "10px"};
  /* display: flex; */
  /* align-items: center; */
  /* justify-content: center; */
  /* padding: 20px; */
`;
var RoundButton = /* @__PURE__ */ __name((props) => {
  const { $showX } = props;
  return /* @__PURE__ */ jsx6(StyledRoundButton, __spreadProps(__spreadValues({}, props), { children: /* @__PURE__ */ jsx6(StyledRoundButtonInner, { $showX, children: props.children }) }));
}, "RoundButton");
var unicode = {
  circle: `\u25CF`
};

// src/features/rick-and-morty/RickAndMortyContent.tsx
import { Fragment as Fragment2, jsx as jsx7, jsxs as jsxs4 } from "react/jsx-runtime";
var RickAndMortyContent = /* @__PURE__ */ __name((props) => {
  const { characters } = props;
  const size2 = useAppSelector(selectHeight);
  console.warn("SIZE", size2);
  const themeBright = useAppSelector((state) => state.theme.brightness);
  const [character, setCharacter] = useState3(null);
  const isBright = themeBright === "light" /* light */;
  return /* @__PURE__ */ jsxs4(Fragment2, { children: [
    /* @__PURE__ */ jsx7(Modal, { show: !!character, handleClose: () => setCharacter(null), children: /* @__PURE__ */ jsx7(CharacterModal, { character }) }),
    /* @__PURE__ */ jsx7(StyledCenter2, { $size: size2 || 100, children: characters.map((character2) => {
      return /* @__PURE__ */ jsx7(
        Character,
        {
          onClick: () => setCharacter(character2),
          character: character2,
          isBright,
          size: "350px"
        },
        character2.id
      );
    }) })
  ] });
}, "RickAndMortyContent");

// src/features/rick-and-morty/components/character/StyledCenter.tsx
import styled9 from "styled-components";
var StyledCenter = styled9.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

// src/features/rick-and-morty/components/Pagination.tsx
import { useState as useState4 } from "react";

// src/features/rick-and-morty/components/loading/styled.tsx
import styled10, { keyframes } from "styled-components";
var rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;
var Rotate = styled10.div`
  width: ${(p) => p.$width};
  height: ${(p) => p.$height};
  animation: ${rotate} 2s linear infinite;
  // padding: 60px;
`;

// src/static/placeholder-light.png
var placeholder_light_default = "./placeholder-light-4LCCSVEU.png";

// src/static/placeholder-dark.png
var placeholder_dark_default = "./placeholder-dark-QX5AWBAT.png";

// src/static/loading-svgrepo-com.svg
import * as React4 from "react";
import { jsx as jsx8, jsxs as jsxs5 } from "react/jsx-runtime";
var SvgLoadingSvgrepoCom = /* @__PURE__ */ __name((props) => /* @__PURE__ */ jsxs5("svg", __spreadProps(__spreadValues({ id: "Capa_1", xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink", x: "0px", y: "0px", width: "26.349px", height: "26.35px", viewBox: "0 0 26.349 26.35", style: {
  enableBackground: "new 0 0 26.349 26.35"
}, xmlSpace: "preserve" }, props), { children: [
  /* @__PURE__ */ jsx8("g", { children: /* @__PURE__ */ jsxs5("g", { children: [
    /* @__PURE__ */ jsx8("circle", { cx: 13.792, cy: 3.082, r: 3.082 }),
    /* @__PURE__ */ jsx8("circle", { cx: 13.792, cy: 24.501, r: 1.849 }),
    /* @__PURE__ */ jsx8("circle", { cx: 6.219, cy: 6.218, r: 2.774 }),
    /* @__PURE__ */ jsx8("circle", { cx: 21.365, cy: 21.363, r: 1.541 }),
    /* @__PURE__ */ jsx8("circle", { cx: 3.082, cy: 13.792, r: 2.465 }),
    /* @__PURE__ */ jsx8("circle", { cx: 24.501, cy: 13.791, r: 1.232 }),
    /* @__PURE__ */ jsx8("path", { d: "M4.694,19.84c-0.843,0.843-0.843,2.207,0,3.05c0.842,0.843,2.208,0.843,3.05,0c0.843-0.843,0.843-2.207,0-3.05 C6.902,18.996,5.537,18.988,4.694,19.84z" }),
    /* @__PURE__ */ jsx8("circle", { cx: 21.364, cy: 6.218, r: 0.924 })
  ] }) }),
  /* @__PURE__ */ jsx8("g", {}),
  /* @__PURE__ */ jsx8("g", {}),
  /* @__PURE__ */ jsx8("g", {}),
  /* @__PURE__ */ jsx8("g", {}),
  /* @__PURE__ */ jsx8("g", {}),
  /* @__PURE__ */ jsx8("g", {}),
  /* @__PURE__ */ jsx8("g", {}),
  /* @__PURE__ */ jsx8("g", {}),
  /* @__PURE__ */ jsx8("g", {}),
  /* @__PURE__ */ jsx8("g", {}),
  /* @__PURE__ */ jsx8("g", {}),
  /* @__PURE__ */ jsx8("g", {}),
  /* @__PURE__ */ jsx8("g", {}),
  /* @__PURE__ */ jsx8("g", {}),
  /* @__PURE__ */ jsx8("g", {})
] })), "SvgLoadingSvgrepoCom");
var loading_svgrepo_com_default = SvgLoadingSvgrepoCom;

// src/static/static.ts
var imgs = {
  svg: {
    loading: loading_svgrepo_com_default
  },
  placeholderDark: placeholder_dark_default,
  placeholderLight: placeholder_light_default
};

// src/features/rick-and-morty/components/loading/Loading.tsx
import SVG from "react-inlinesvg";
import styled11 from "styled-components";
import { jsx as jsx9 } from "react/jsx-runtime";
var Loading = /* @__PURE__ */ __name((props) => {
  const height = props.size;
  const width = props.size;
  return /* @__PURE__ */ jsx9("div", { children: /* @__PURE__ */ jsx9(Rotate, { $width: `${width}px`, $height: `${height}px`, children: /* @__PURE__ */ jsx9(
    StyledSVG,
    {
      src: imgs.svg.loading,
      style: {
        width,
        height
        // ...style,
      }
    }
  ) }) });
}, "Loading");
var StyledSVG = styled11(SVG)`
  fill: ${(p) => p.theme.color.mark};
`;

// src/@app/@app-components/src/buttons/src/styled.tsx
import styled12 from "styled-components";
var StyledButton = styled12.button`
  color: ${(p) => p.theme.color.getPrimary()};
  padding: 10px;
  margin: 10px;
  min-width: 80px;
  background-color: aliceblue;
`;
var StyledLoadingButton = styled12(StyledButton)`
  display: flex;
  flex-direction: ${(p) => p.$direction ? p.$direction : "row"};
  align-items: center;
  justify-content: center;
`;

// src/features/rick-and-morty/components/Pagination.tsx
import { Fragment as Fragment3, jsx as jsx10, jsxs as jsxs6 } from "react/jsx-runtime";
var Pagination = /* @__PURE__ */ __name(({
  disableNext,
  disablePrev,
  currentNumber,
  onNavClick
}) => {
  return /* @__PURE__ */ jsxs6(Fragment3, { children: [
    /* @__PURE__ */ jsx10(
      PageButton,
      {
        label: "<",
        onClick: () => onNavClick(currentNumber - 1),
        disable: disablePrev
      }
    ),
    /* @__PURE__ */ jsx10("span", { style: { paddingLeft: 5, paddingRight: 5 }, children: currentNumber }),
    /* @__PURE__ */ jsx10(
      PageButton,
      {
        label: ">",
        onClick: () => onNavClick(currentNumber + 1),
        disable: disableNext
      }
    )
  ] });
}, "Pagination");
var PageButton = /* @__PURE__ */ __name((props) => {
  const { label, disable, onClick, showLoading = true } = props;
  const [isLoading, setIsLoading] = useState4(false);
  return /* @__PURE__ */ jsxs6(
    StyledLoadingButton,
    {
      disabled: disable,
      onClick: () => {
        setIsLoading(true);
        onClick().finally(() => setIsLoading(false));
      },
      children: [
        /* @__PURE__ */ jsx10("span", { style: { width: 20, height: 20 } }),
        label,
        showLoading && isLoading ? /* @__PURE__ */ jsx10(Loading, { size: 20 }) : /* @__PURE__ */ jsx10("span", { style: { width: 20, height: 20 } })
      ]
    }
  );
}, "PageButton");

// src/@app/@app-theme/src/redux/brightness.selectors.ts
var selectBrightness = /* @__PURE__ */ __name((state) => state.theme.brightness, "selectBrightness");

// src/@app/@app-theme/src/redux/brightness.slice.ts
import { createSlice as createSlice2 } from "@reduxjs/toolkit";

// src/@app/@app-theme/src/utils/local-storage.ts
function writeBrightnessToLocalStorage(br) {
  window.localStorage.setItem(brightnessKey, br);
}
__name(writeBrightnessToLocalStorage, "writeBrightnessToLocalStorage");
function readBrightnessFromLocalStorage() {
  return window.localStorage.getItem(brightnessKey);
}
__name(readBrightnessFromLocalStorage, "readBrightnessFromLocalStorage");

// src/@app/@app-theme/src/redux/brightness.slice.ts
var initialState2 = {
  brightness: "dark" /* dark */
};
var themeSlice = createSlice2({
  name: "theme",
  initialState: initialState2,
  reducers: {
    chooseDark: (state) => {
      writeBrightnessToLocalStorage("dark" /* dark */);
      state.brightness = "dark" /* dark */;
    },
    chooseLight: (state) => {
      writeBrightnessToLocalStorage("light" /* light */);
      state.brightness = "light" /* light */;
    },
    flip: (state) => {
      const flippedState = state.brightness === "dark" /* dark */ ? "light" /* light */ : "dark" /* dark */;
      writeBrightnessToLocalStorage(flippedState);
      state.brightness = flippedState;
    }
  }
});
var { chooseDark, chooseLight, flip } = themeSlice.actions;
var themeReducer = themeSlice.reducer;

// src/app/app-bar/BrigthnessButton.tsx
import { messages, useMessage } from "@mono/locale";
import { jsx as jsx11, jsxs as jsxs7 } from "react/jsx-runtime";
function BrightnessButton() {
  const dispatch = useAppDispatch();
  const current = useAppSelector(selectBrightness);
  const isBright = current === "light" /* light */;
  const message = useMessage();
  return /* @__PURE__ */ jsxs7("div", { style: { height: 60 }, children: [
    /* @__PURE__ */ jsx11(StyledButton, { disabled: isBright, onClick: () => dispatch(chooseLight()), children: message(messages.theme.light) }),
    /* @__PURE__ */ jsx11(StyledButton, { disabled: !isBright, onClick: () => dispatch(chooseDark()), children: message(messages.theme.dark) })
  ] });
}
__name(BrightnessButton, "BrightnessButton");

// src/app/app-bar/styled.tsx
import styled13 from "styled-components";
var StyledTop = styled13.div`
  display: flex;
  height: ${(p) => p.theme.size.navigation.top};
  background-color: ${(p) => p.theme.color.bg1};
  border-bottom: 1px ${(p) => p.theme.color.primary} solid;
`;

// src/app/app-bar/AppBar.tsx
import { jsx as jsx12 } from "react/jsx-runtime";
var AppBar = /* @__PURE__ */ __name(() => {
  return /* @__PURE__ */ jsx12(StyledTop, { children: /* @__PURE__ */ jsx12(BrightnessButton, {}) });
}, "AppBar");

// src/@app/@app-utils/src/use-resize-listener.ts
import { useEffect as useEffect3 } from "react";

// src/@app/@app-theme/src/theme/palette.ts
var primary3 = "#008000";
var secondary = "#0f80ff";
var darkBg1 = "#000";
var darkBg2 = "#191919";
var darkColor = "#ccc";
var darkMark = primary3;
var darkLink = secondary;
var lightBg1 = "#fff";
var lightBg2 = "#e6e6e6";
var lightColor = "#333";
var lightMark = primary3;
var lightLink = secondary;

// src/@app/@app-theme/src/theme/theme.ts
var top = 50;
var extra = 2;
var unit = "px";
var size = {
  unit,
  navigation: {
    top,
    pxCalc: `calc(100vh - ${top + extra}px)`
    //calc: `100%`,
  },
  border: {
    radious: {
      px10: `10px`
    }
  },
  sizes: {
    xxLarge: "18px",
    xLarge: "16px",
    large: "14px",
    larger: "12px",
    medium: "10px",
    smaller: "8px",
    small: "6px",
    xSmall: "4px",
    xxSmall: "2px"
  }
};
var sizes = {
  xxLarge: "26px",
  xLarge: "22px",
  large: "18px",
  larger: "14px",
  medium: "10px",
  smaller: "8px",
  small: "6px",
  xSmall: "4px",
  xxSmall: "2px"
};
var darkColors = {
  getPrimary: () => lightColor,
  foundation: darkBg1,
  bg1: darkBg2,
  primary: darkColor,
  alt: lightColor,
  mark: darkMark,
  link: darkLink
};
var brightColors = {
  getPrimary: () => lightColor,
  foundation: lightBg1,
  bg1: lightBg2,
  primary: lightColor,
  alt: darkColor,
  mark: lightMark,
  link: lightLink
};
var darkTheme = {
  isDark: () => true,
  brightness: "dark" /* dark */,
  color: darkColors,
  size,
  sizes
};
var brightTheme = {
  isDark: () => false,
  brightness: "light" /* light */,
  color: brightColors,
  size,
  sizes
};

// src/@app/@app-utils/src/use-resize-listener.ts
function useSizeListener() {
  const dispatch = useAppDispatch();
  const size2 = useAppSelector(selectHeight);
  useEffect3(() => {
    if (!size2) {
      handleResize();
      setupListeners();
    }
    return cleanupListeners;
  }, []);
  function setupListeners() {
    console.warn("setup listener");
    window.addEventListener("resize", handleResize);
  }
  __name(setupListeners, "setupListeners");
  function cleanupListeners() {
    console.warn("clean listener");
    window.removeEventListener("resize", handleResize);
  }
  __name(cleanupListeners, "cleanupListeners");
  function handleResize() {
    console.warn("dispath listener", window.innerHeight - top - extra);
    dispatch(setHeight(window.innerHeight - top - extra));
  }
  __name(handleResize, "handleResize");
}
__name(useSizeListener, "useSizeListener");

// src/features/rick-and-morty/RickAndMortyPage.tsx
import { Fragment as Fragment4, jsx as jsx13, jsxs as jsxs8 } from "react/jsx-runtime";
var RickAndMortyPage = /* @__PURE__ */ __name(() => {
  const query = useQueryWithLocationSearch();
  const memoizedQuery = useMemo(() => query, []);
  useSizeListener();
  return /* @__PURE__ */ jsx13(RickAndMortyMemo, { query: memoizedQuery });
}, "RickAndMortyPage");
var RickAndMortyMemo = React6.memo(
  (props) => {
    var _a, _b, _c;
    const routeNavigate = useNavigate2();
    const query = props.query;
    const [characters, setCharacters] = useState5({});
    const currentNumber = useRef2(
      parseInt(query.get("page" /* page */) || "1")
    );
    useEffect4(() => {
      getCharacter({
        query: { page: currentNumber.current }
      }).then((x) => setCharacters({ [currentNumber.current]: x }));
    }, []);
    const disablePrev = !((_a = characters[currentNumber.current]) == null ? void 0 : _a.info.prev);
    const disableNext = !((_b = characters[currentNumber.current]) == null ? void 0 : _b.info.next);
    console.warn("aap", characters);
    return /* @__PURE__ */ jsxs8(Fragment4, { children: [
      /* @__PURE__ */ jsx13(AppBar, {}),
      /* @__PURE__ */ jsx13(StyledCenter, { children: /* @__PURE__ */ jsx13(
        Pagination,
        {
          currentNumber: currentNumber.current,
          disablePrev,
          disableNext,
          onNavClick: (pageNumber) => onNavClick(pageNumber)
        }
      ) }),
      /* @__PURE__ */ jsx13(
        RickAndMortyContent,
        {
          characters: ((_c = characters[currentNumber.current]) == null ? void 0 : _c.results) || []
        }
      )
    ] });
    function onNavClick(pageNumber) {
      return __async(this, null, function* () {
        currentNumber.current = pageNumber;
        routeNavigate(RickAndMortyPage.getPath({ pageNumber }));
        if (Object.keys(characters).includes(`${currentNumber.current}`)) {
          console.warn("included");
          return;
        }
        console.warn("fetsch");
        setCharacters(__spreadProps(__spreadValues({}, characters), {
          [pageNumber]: yield getCharacter({ query: { page: pageNumber } })
        }));
      });
    }
    __name(onNavClick, "onNavClick");
  }
);
RickAndMortyMemo.displayName = "RickAndMortyMemo";
RickAndMortyPage.path = "/rick-and-morty";
RickAndMortyPage.title = "Rick and morty";
RickAndMortyPage.getPath = (p) => `${RickAndMortyPage.path}?${"page" /* page */}=${p.pageNumber}`;

// src/@app/@app-theme/src/hooks/use-brightness.ts
import { useEffect as useEffect5 } from "react";
function useBrightness() {
  const currentBrightness = useAppSelector(selectBrightness);
  const brightnessLocalStorage = readBrightnessFromLocalStorage();
  const dispatch = useAppDispatch();
  useEffect5(() => {
    if (brightnessLocalStorage != null) {
      if (brightnessLocalStorage === "dark" /* dark */) {
        dispatch(chooseDark());
      }
      if (brightnessLocalStorage === "light" /* light */) {
        dispatch(chooseLight());
      }
    }
  }, []);
  return currentBrightness;
}
__name(useBrightness, "useBrightness");

// src/@app/@app-theme/src/theme/global-style.tsx
import { createGlobalStyle as createGlobalStyle2, css as css4 } from "styled-components";
var scrollbarStyle2 = /* @__PURE__ */ __name((params) => css4`
  /* SCROLLBARS -- START */
  /* https://www.digitalocean.com/community/tutorials/css-scrollbars */

  /* Works on Firefox */
  * {
    scrollbar-gutter: unset;
    scrollbar-gutter: auto;
    //scrollbar-width: thin;
    scrollbar-color: ${params.thumb} ${params.track};
  }

  /* Works on Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 12px;
    background: green;
  }

  *::-webkit-scrollbar-track {
    background: ${params.track};
    width: 5px;
  }

  *::-webkit-scrollbar-thumb {
    background-color: ${params.thumb};
    border-radius: 20px;
    // width: 5px;
    //border: 3px solid ${(props) => props.theme.color.foundation};
  }

  /* *::-webkit-scrollbar-thumb:hover {
    background: rgb(100, 100, 100);
    border-radius: 10px;
  } */

  /* SCROLLBARS -- END */
`, "scrollbarStyle");
var rootStyle2 = css4`
  body {
    background-color: ${(props) => props.theme.color.foundation};
    color: ${(props) => props.theme.color.primary};
    margin: 0px;
    padding: 0px;
    font-family: monospace, monospace;
    overflow: hidden;
  }
`;
var GlobalRickAndMortyStyle = createGlobalStyle2`
  ${rootStyle2}

  ${(props) => scrollbarStyle2({
  thumb: props.theme.color.bg1,
  track: props.theme.color.foundation
})}
`;

// src/features/rick-and-morty/theme/ThemeProvider.tsx
import { ThemeProvider } from "styled-components";
import { jsx as jsx14, jsxs as jsxs9 } from "react/jsx-runtime";
var StyledThemeProvider = /* @__PURE__ */ __name((props) => {
  const currentBrightness = useBrightness();
  if (currentBrightness === "dark" /* dark */) {
    return /* @__PURE__ */ jsx14(StyledThemeProviderWithTheme, { theme: __spreadValues({}, darkTheme), children: props.children });
  }
  return /* @__PURE__ */ jsx14(StyledThemeProviderWithTheme, { theme: __spreadValues({}, brightTheme), children: props.children });
}, "StyledThemeProvider");
var StyledThemeProviderWithTheme = /* @__PURE__ */ __name((props) => {
  return /* @__PURE__ */ jsxs9(ThemeProvider, { theme: props.theme, children: [
    /* @__PURE__ */ jsx14(GlobalRickAndMortyStyle, {}),
    props.children
  ] });
}, "StyledThemeProviderWithTheme");

// src/@app/@app-redux/store/store.ts
import { configureStore } from "@reduxjs/toolkit";

// src/@app/@app-redux/reducer/root-reducer.ts
import { combineReducers } from "@reduxjs/toolkit";

// src/features/rick-and-morty/redux/character-slice.ts
import { createSlice as createSlice3 } from "@reduxjs/toolkit";
var initialState3 = {
  height: null
};
var characterSlice = createSlice3({
  name: "character",
  initialState: initialState3,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setHeight_: (state, action) => {
      console.warn("PAYLOAD", action.payload);
      state.height = action.payload;
    }
  }
});
var { setHeight_ } = characterSlice.actions;
var characterReducer = characterSlice.reducer;

// src/features/react-time/src/redux/time/time.reducer.ts
import { createReducer } from "@reduxjs/toolkit";

// src/features/react-time/src/redux/time/time.action.ts
import { createAsyncThunk as createAsyncThunk3 } from "@reduxjs/toolkit";

// src/features/react-time/src/redux/time/time.constants.ts
var TIME_SET = "TIME_SET";

// src/features/react-time/src/redux/time/time.action.ts
var setTimeAction = createAsyncThunk3(
  TIME_SET,
  (data) => __async(void 0, null, function* () {
    return {
      result: data,
      status: "loading" /* loading */
    };
  })
);

// src/features/react-time/src/redux/time/time.state.ts
var initialTimeState = {
  time: (/* @__PURE__ */ new Date()).toJSON(),
  status: "idle" /* idle */
};
function getInitialTimeState() {
  return JSON.parse(JSON.stringify(initialTimeState));
}
__name(getInitialTimeState, "getInitialTimeState");

// src/features/react-time/src/redux/time/time.reducer.ts
var timeReducer = createReducer(getInitialTimeState(), (builder) => {
  builder.addCase(setTimeAction.fulfilled, (state, payload) => {
    return {
      time: payload.payload.result,
      status: "idle" /* idle */
    };
  });
});

// src/@app/@app-redux/reducer/root-reducer.ts
var appReducer = combineReducers({
  theme: themeReducer,
  screen: screenReducer,
  character: characterReducer,
  time: timeReducer
});
var rootReducer = /* @__PURE__ */ __name((state, action) => {
  return appReducer(state, action);
}, "rootReducer");

// src/@app/@app-redux/store/store.ts
var store = configureStore({
  reducer: rootReducer
});

// src/features/rick-and-morty/RickAndMortyWrapper.tsx
import { jsx as jsx15, jsxs as jsxs10 } from "react/jsx-runtime";
var RickAndMortyApp = /* @__PURE__ */ __name(() => {
  return /* @__PURE__ */ jsx15(Provider, { store, children: /* @__PURE__ */ jsxs10(StyledThemeProvider, { children: [
    /* @__PURE__ */ jsx15(RMGlobalStyle, {}),
    /* @__PURE__ */ jsx15(RickAndMortyPage, {})
  ] }) });
}, "RickAndMortyApp");
export {
  Providers,
  RickAndMortyApp,
  RickAndMortyPage
};
