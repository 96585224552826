'use client'
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __name = (target, value) => __defProp(target, "name", { value, configurable: true });
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};

// src/app/domains/modes/mode-1/Mode1.tsx
import { fonts } from "@mono/fonts";

// src/hooks/style/use-font-style.ts
import { useState } from "react";
function useFontStyle() {
  const [fontStyle, setFontStyle] = useState({
    // fontWeight: 'lighter',
    color: "transparent"
  });
  return {
    fontStyle,
    setFontStyle
  };
}
__name(useFontStyle, "useFontStyle");

// src/app/domains/modes/mode-1/Mode1.tsx
import moment2 from "moment";

// src/hooks/screen/use-window-size.ts
import { useEffect, useState as useState2 } from "react";
function useWindowSize() {
  const [windowSize, setWindowSize] = useState2({
    width: 0,
    height: 0
  });
  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
      });
    }
    __name(handleResize, "handleResize");
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return windowSize;
}
__name(useWindowSize, "useWindowSize");

// src/hooks/screen/use-screen-size.ts
var ratio = 0.5;
function useVH(size) {
  return `${size * ratio}vw`;
}
__name(useVH, "useVH");
function useREM(size) {
  const _size = useWindowSize();
  if (_size.height > _size.width) {
    return `${size * ratio}rem`;
  }
  return `${size * ratio}rem`;
}
__name(useREM, "useREM");

// src/lib/date/lib/date.ts
import {
  format,
  getDaysInMonth as gdim
} from "date-fns";
import { enGB } from "date-fns/locale";
import moment from "moment";
function getDate(date, sformat) {
  return moment(date || (/* @__PURE__ */ new Date()).valueOf()).format(sformat || "yyyy-MM-DD");
}
__name(getDate, "getDate");
function getDaysInMonth(date) {
  return gdim(date);
}
__name(getDaysInMonth, "getDaysInMonth");
function getDD(date) {
  return {
    year: getDate(date, "yyyy"),
    month: getDate(date, "MM"),
    day: getDate(date, "dd"),
    hour: getDate(date, "HH"),
    minute: getDate(date, "mm"),
    second: getDate(date, "ss")
  };
}
__name(getDD, "getDD");

// src/app/domains/modes/mode-8/use-style-context.tsx
import {
  createContext,
  useCallback,
  useContext,
  useState as useState3
} from "react";
import { jsx } from "react/jsx-runtime";
var StyleContext = createContext({
  style: {},
  setStyle: (style) => {
  }
});
function useStyle() {
  const context = useContext(StyleContext);
  if (!context)
    throw new Error("must be used within a Provider");
  const setStyle = useCallback(
    (style) => {
      context.setStyle(style);
    },
    [context.setStyle]
  );
  return {
    style: context.style,
    setStyle
  };
}
__name(useStyle, "useStyle");
var StyleProvider = /* @__PURE__ */ __name((props) => {
  const [style, setStyle] = useState3({});
  return /* @__PURE__ */ jsx(
    StyleContext.Provider,
    {
      value: {
        style,
        setStyle
      },
      children: props.children
    }
  );
}, "StyleProvider");

// src/app/use-init-style.tsx
function useStart(font) {
  const usestyle = useStyle();
}
__name(useStart, "useStart");

// src/app/domains/modes/mode-1/Mode1.tsx
import { jsx as jsx2, jsxs } from "react/jsx-runtime";
function Mode1() {
  const date = /* @__PURE__ */ new Date();
  useStart(fonts.font4);
  return /* @__PURE__ */ jsxs(
    "div",
    {
      style: {
        width: "210.2vh",
        height: "104px"
        // backgroundColor: colors.gr0,
      },
      children: [
        /* @__PURE__ */ jsx2(DateModule1, { date: date.valueOf() }),
        /* @__PURE__ */ jsx2("div", { style: { height: useVH(4) } })
      ]
    }
  );
}
__name(Mode1, "Mode1");
function DisplayFor1080(p) {
  return /* @__PURE__ */ jsx2(
    "div",
    {
      style: {
        width: useVH(22.92),
        //'212px',
        height: useVH(11.25),
        //'104px',
        // border: `1px solid ${colors.gr3}`,
        // backgroundColor: colors.gr1,
        fontFamily: "myfont1",
        fontSize: useVH(0.9)
      },
      children: /* @__PURE__ */ jsx2(DateModule1, { date: p.date.valueOf() })
    }
  );
}
__name(DisplayFor1080, "DisplayFor1080");
function DateModule1(p) {
  return /* @__PURE__ */ jsx2(
    "div",
    {
      ref: (reF) => {
        if (reF) {
        }
      },
      style: {
        display: "flex",
        // width: displaySize.widthPx,
        // height: displaySize.heightPx,
        //backgroundColor: 'pink',
        flexDirection: "row",
        justifyContent: "space-around",
        alignItems: "center",
        height: "100%",
        width: "100%"
        //height: '104px',
        //marginTop: '4px',
      },
      children: /* @__PURE__ */ jsx2(DateTimeColumnVertical, { date: p.date, montHformat: "MMM" })
    }
  );
}
__name(DateModule1, "DateModule1");
function DateTimeColumnVertical(p) {
  const wrapper = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    height: "100%"
  };
  const fontStyle = useFontStyle();
  const _fontStyle = __spreadProps(__spreadValues({}, fontStyle.fontStyle), {
    fontFamily: "myfont4",
    fontSize: useVH(1)
    // color: colors.text,
  });
  const dd = getDD(new Date(p.date).valueOf());
  return /* @__PURE__ */ jsxs("div", { style: wrapper, children: [
    /* @__PURE__ */ jsx2("span", { style: _fontStyle, children: moment2(p.date).format(p.montHformat) }),
    /* @__PURE__ */ jsx2("span", { style: _fontStyle, children: dd.year }),
    /* @__PURE__ */ jsxs("span", { style: _fontStyle, children: [
      dd.month,
      "-",
      dd.day
    ] }),
    /* @__PURE__ */ jsxs("span", { style: _fontStyle, children: [
      dd.hour,
      ":",
      dd.minute
    ] }),
    /* @__PURE__ */ jsx2("span", { style: _fontStyle, children: dd.second })
  ] });
}
__name(DateTimeColumnVertical, "DateTimeColumnVertical");

// src/app/domains/modes/mode-2/Mode2.tsx
import { fonts as fonts2 } from "@mono/fonts";

// src/components/calendar/components/CalendarTop.tsx
import moment3 from "moment";
import { jsx as jsx3, jsxs as jsxs2 } from "react/jsx-runtime";
function CalendarTop(p) {
  const month = `${moment3(p.date).format("MMMM")}`;
  const year = `${moment3(p.date).format("YYYY")}`;
  const fontStyle = useFontStyle();
  const textStyle = __spreadProps(__spreadValues({}, fontStyle.fontStyle), {
    fontSize: p.fontSize
  });
  return /* @__PURE__ */ jsxs2(
    "span",
    {
      id: CalendarTop.idName,
      style: __spreadProps(__spreadValues({}, textStyle), {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        flexShrink: "0",
        flexGrow: "1",
        //backgroundColor: 'red',
        //width: '100%',
        alignItems: "center",
        paddingLeft: p.padding,
        paddingRight: p.padding,
        paddingBottom: useREM(2),
        //backgroundColor: '#363636',
        //backgroundColor: colors.ground3,
        borderRadius: p.borderRadius
      }),
      children: [
        /* @__PURE__ */ jsx3(
          "span",
          {
            style: {
              //...textStyle,
              // color: colors.mark,
            },
            children: month
          }
        ),
        /* @__PURE__ */ jsx3(
          "span",
          {
            style: {
              //...textStyle,
              // color: colors.textSoft,
            },
            children: year
          }
        )
      ]
    }
  );
}
__name(CalendarTop, "CalendarTop");
CalendarTop.idName = "CalendarTop";

// src/components/base/BackgroundComponent.tsx
import styled, { useTheme } from "styled-components";
import { jsx as jsx4 } from "react/jsx-runtime";
var StyledBG = styled.div`
  width: ${({ $size: v }) => v};
  height: ${({ $size: v }) => v};
  /* background-color: aliceblue; */
`;
function BackgroundComponent(props) {
  const theme = useTheme();
  const { children, id, size } = props;
  return /* @__PURE__ */ jsx4(
    StyledBG,
    {
      $size: size,
      id,
      style: {
        // backgroundColor: useColors().gr1,
        // backgroundColor: theme.colors.background.base,
        // padding: useREM(3),
        // borderRadius: useREM(6),
        // margin: useREM(1),
      },
      children
    }
  );
}
__name(BackgroundComponent, "BackgroundComponent");

// src/lib/date/lib/cal-date.tsx
import { subMonths as subMonths_ } from "date-fns";
import moment4 from "moment";
function _getWeekNames() {
  const aa = moment4.weekdaysShort();
  const first = aa.shift();
  return [...aa, first].map((e) => {
    if (e) {
      return e.slice(0, 2);
    }
    return "";
  });
}
__name(_getWeekNames, "_getWeekNames");
function getWeekNames() {
  return _getWeekNames();
}
__name(getWeekNames, "getWeekNames");
function subMonths(date, n) {
  return subMonths_(new Date(date), n).valueOf();
}
__name(subMonths, "subMonths");
var getStartOfMonthWeekdayNumberFromDate = /* @__PURE__ */ __name((date) => {
  return moment4(date).startOf("month").isoWeekday();
}, "getStartOfMonthWeekdayNumberFromDate");
function getWeekNumber(d) {
  return moment4(d).isoWeek();
}
__name(getWeekNumber, "getWeekNumber");
function getWeekDay(d) {
  return moment4(new Date(d)).isoWeekday();
}
__name(getWeekDay, "getWeekDay");

// src/lib/components/calendar/cell-support/get-rows.tsx
function getRows(p) {
  const totalSlots = [...p.previousCells, ...p.dayInMonthCells, ...p.nextCells];
  const rows = [];
  let cells = [];
  let count = 0;
  totalSlots.forEach((cell) => {
    if (count === 0) {
      cells.push(__spreadProps(__spreadValues({}, cell), {
        weekNr: getWeekNumber(new Date(cell.date).valueOf()).valueOf()
      }));
    }
    cells.push(cell);
    count += 1;
    if (count === 6) {
      cell.weekend = true;
    }
    if (count === 7) {
      cell.weekend = true;
      rows.push(cells);
      cells = [];
      count = 0;
    }
  });
  return rows;
}
__name(getRows, "getRows");

// src/lib/components/calendar/cell-support/get-cells.tsx
import moment5 from "moment";
function getDaysInPreviousMonthAsArray(p) {
  const _i = getDaysInMonth(subMonths(p.date, 1).valueOf());
  return [...Array.from({ length: _i }, (_, i) => i + 1)].reverse();
}
__name(getDaysInPreviousMonthAsArray, "getDaysInPreviousMonthAsArray");
function cellDate(p) {
  const month = moment5(new Date(p.date)).format("MM").valueOf();
  const day = String(p.daysInMonthArray[p.index]).padStart(2, "0");
  const year = new Date(p.date).getFullYear();
  return (/* @__PURE__ */ new Date(
    `${year}-${month}-${day}`
    // Get month returns 0 for january
  )).valueOf();
}
__name(cellDate, "cellDate");
function getPreviousCells(p) {
  const cells = [];
  const daysInMonthArray = getDaysInPreviousMonthAsArray({
    date: p.date
  });
  for (let i = 0; i < getStartOfMonthWeekdayNumberFromDate(p.date) - 1; i++) {
    const apa = cellDate({
      date: moment5(p.date).subtract(1, "month").valueOf(),
      daysInMonthArray,
      index: i
    });
    cells.push({
      date: apa,
      previousMonth: true
    });
  }
  return cells.reverse();
}
__name(getPreviousCells, "getPreviousCells");
function getDateFromCalc(date, index) {
  if (index === 0) {
    throw new Error("Index cannot be 0");
  }
  const now = new Date(date);
  now.setMonth(now.getMonth() + 1);
  now.setDate(index);
  return moment5(now).valueOf();
}
__name(getDateFromCalc, "getDateFromCalc");
var gridSize = 43;
function getNextMonthDays(p) {
  const cells = [];
  for (let i = 1; i < gridSize - p.total; i++) {
    cells.push({
      date: getDateFromCalc(p.date, i),
      nextMonth: true
    });
  }
  return cells;
}
__name(getNextMonthDays, "getNextMonthDays");
function getThisMonthDays(p) {
  const cells = [];
  for (let d = 1; d <= getDaysInMonth(p.currentDay); d++) {
    const year = new Date(p.currentDay).getFullYear();
    const month = String(new Date(p.currentDay).getMonth() + 1).padStart(
      2,
      "0"
    );
    const day = String(d).padStart(2, "0");
    cells.push({
      date: (/* @__PURE__ */ new Date(`${year}-${month}-${day}`)).valueOf(),
      today: parseInt(moment5(p.currentDay).format("D")) === d,
      currentMonth: true
    });
  }
  return cells;
}
__name(getThisMonthDays, "getThisMonthDays");

// src/lib/components/calendar/CompactCalendar.tsx
import moment6 from "moment";
import { jsx as jsx5, jsxs as jsxs3 } from "react/jsx-runtime";
var CompactCalendar = /* @__PURE__ */ __name((p) => {
  const weekdayshort = getWeekNames();
  weekdayshort.unshift("");
  const previousCells = getPreviousCells({
    date: p.date
  });
  const dayInMonthCells = getThisMonthDays({
    currentDay: p.date
  });
  const nextCells = getNextMonthDays({
    total: previousCells.length + dayInMonthCells.length,
    date: p.date
  });
  return /* @__PURE__ */ jsx5("div", { id: CompactCalendar.idName, style: p.tableWrapperStyle, children: /* @__PURE__ */ jsxs3("table", { children: [
    /* @__PURE__ */ jsx5("thead", { children: /* @__PURE__ */ jsx5("tr", { children: weekdayshort.map((day, i) => {
      let isCurrentDay = false;
      const we = getWeekDay(new Date(p.date).valueOf());
      if (we === 0) {
        if (i === 7) {
          isCurrentDay = true;
        }
      } else {
        isCurrentDay = we === i;
      }
      const style = isCurrentDay ? p.activeWeekdayLabelStyle : p.weekdayLabelStyle;
      return /* @__PURE__ */ jsx5(WeekDayCell, { day, cellStyle: style }, i);
    }) }) }),
    /* @__PURE__ */ jsx5("tbody", { children: getRows({
      previousCells,
      dayInMonthCells,
      nextCells
    }).map((cells, i) => {
      return /* @__PURE__ */ jsx5("tr", { children: cells.map((cell, i2) => {
        const isCurrentWeek = getWeekNumber(new Date(cell.date).valueOf()).valueOf() === getWeekNumber(new Date(p.date).valueOf()).valueOf();
        if (cell.weekNr) {
          return /* @__PURE__ */ jsx5(
            WeekCell,
            {
              isCurrentWeek,
              cell,
              weekcell: p.weekNumberStyle,
              activeWeekcell: p.activeWeekNumberStyle
            },
            i2
          );
        }
        if (cell.previousMonth || cell.nextMonth) {
          return /* @__PURE__ */ jsx5(
            OtherCell,
            {
              cell,
              otherStyle: p.otherStyle
            },
            i2
          );
        }
        return /* @__PURE__ */ jsx5(
          DayCell,
          {
            cell,
            daycellStyle: p.daycellStyle,
            activeDaycellStyle: p.activeDaycellStyle,
            weekendCellStyle: p.weekendCellStyle
          },
          i2
        );
      }) }, i);
    }) })
  ] }) });
}, "CompactCalendar");
CompactCalendar.idName = "CompactCal";
function DayCell(p) {
  const bg1 = p.cell.today ? p.activeDaycellStyle : p.daycellStyle;
  if (p.cell.weekend && !p.cell.today) {
    return /* @__PURE__ */ jsx5("td", { children: /* @__PURE__ */ jsx5(
      BlockCell,
      {
        cellData: moment6(new Date(p.cell.date).valueOf()).format("D"),
        cellStyle: p.weekendCellStyle
      }
    ) });
  }
  return /* @__PURE__ */ jsx5("td", { children: /* @__PURE__ */ jsx5(
    BlockCell,
    {
      cellData: moment6(new Date(p.cell.date).valueOf()).format("D"),
      cellStyle: bg1
    }
  ) });
}
__name(DayCell, "DayCell");
function OtherCell(p) {
  return /* @__PURE__ */ jsx5("td", { children: /* @__PURE__ */ jsx5(
    BlockCell,
    {
      cellData: moment6(p.cell.date).format("D"),
      cellStyle: p.otherStyle
    }
  ) });
}
__name(OtherCell, "OtherCell");
function WeekCell(p) {
  const style = p.isCurrentWeek ? p.activeWeekcell : p.weekcell;
  return /* @__PURE__ */ jsx5("td", { children: /* @__PURE__ */ jsx5(BlockCell, { cellData: p.cell.weekNr, cellStyle: style }) });
}
__name(WeekCell, "WeekCell");
function WeekDayCell(p) {
  return /* @__PURE__ */ jsx5("td", { children: /* @__PURE__ */ jsx5(BlockCell, { cellData: p.day, cellStyle: p.cellStyle }) });
}
__name(WeekDayCell, "WeekDayCell");
function BlockCell(p) {
  return /* @__PURE__ */ jsx5(
    "span",
    {
      style: __spreadValues({
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
      }, p.cellStyle),
      children: p.cellData
    }
  );
}
__name(BlockCell, "BlockCell");

// src/components/calendar/Calendar.tsx
import { jsx as jsx6, jsxs as jsxs4 } from "react/jsx-runtime";
function getStyles(p) {
  const fontSize = useREM(p.size * 0.8);
  const fontStyle = useFontStyle().fontStyle;
  const styles = {
    weekNumber: () => {
      return __spreadProps(__spreadValues({}, fontStyle), {
        fontSize: useREM(p.size * 0.7),
        fontWeight: "lighter",
        paddingRight: useREM(0.5)
      });
    },
    weekNumberActive: () => {
      return __spreadValues({}, styles.weekNumber());
    },
    weekLabel: () => {
      return __spreadProps(__spreadValues({}, fontStyle), {
        // color: colors.gr4,
        fontSize: useREM(p.size * 0.7)
      });
    },
    weekLabelActive: () => {
      return __spreadValues({}, styles.weekLabel());
    },
    other: () => {
      return __spreadProps(__spreadValues({}, fontStyle), {
        // color: colors.gr2,
        fontSize: useREM(p.size * 0.7)
      });
    },
    daycell: () => {
      return __spreadProps(__spreadValues({}, fontStyle), {
        fontSize,
        paddingLeft: useREM(0.4),
        paddingRight: useREM(0.4)
        // color: colors.text,
      });
    },
    dayCellActive: () => {
      return __spreadProps(__spreadValues({}, styles.daycell()), {
        // color: colors.gr0,
        borderRadius: useREM(1)
        // backgroundColor: colors.mark,
      });
    },
    weekendCell: () => {
      return __spreadValues({}, styles.daycell());
    },
    tableWrapperStyle: () => {
      return {
        // ...styles.daycell(),
        padding: useREM(2),
        // color: colors.gr0,
        borderRadius: useREM(3)
        // border: `1px solid ${colors.gr3}`,
        // backgroundColor: colors.gr0,
      };
    }
  };
  return styles;
}
__name(getStyles, "getStyles");
var CalendarFull = /* @__PURE__ */ __name((p) => {
  const _size = p.width * 35e-4;
  const styles = getStyles({ size: _size });
  return /* @__PURE__ */ jsxs4(
    BackgroundComponent,
    {
      size: "1rem",
      children: [
        /* @__PURE__ */ jsx6(
          CalendarTop,
          {
            date: p.date
          }
        ),
        /* @__PURE__ */ jsx6(
          CompactCalendar,
          {
            date: p.date
          }
        )
      ]
    }
  );
}, "CalendarFull");
CalendarFull.idName = "CalendarFull";

// src/components/layout/support.tsx
import { jsx as jsx7 } from "react/jsx-runtime";
function WrapDirection(props) {
  const { id, style, children } = props;
  return /* @__PURE__ */ jsx7("div", { id, style, children });
}
__name(WrapDirection, "WrapDirection");

// src/components/layout/Center.tsx
import { jsx as jsx8 } from "react/jsx-runtime";
function Center(props) {
  const _a = props, { id, children } = _a, rest = __objRest(_a, ["id", "children"]);
  return /* @__PURE__ */ jsx8(
    WrapDirection,
    {
      id,
      style: __spreadValues({
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        // display: grid;
        placeItems: "center",
        height: "100%"
      }, rest),
      children
    }
  );
}
__name(Center, "Center");

// src/resources/clock/second-hand-01.svg
import * as React3 from "react";
import { jsx as jsx9 } from "react/jsx-runtime";

// src/resources/clock/face-01.svg
import * as React4 from "react";
import { jsx as jsx10 } from "react/jsx-runtime";

// src/resources/clock/second-hand-02.svg?react
import * as React5 from "react";
import { jsx as jsx11, jsxs as jsxs5 } from "react/jsx-runtime";
var SvgSecondHand02 = /* @__PURE__ */ __name((props) => /* @__PURE__ */ jsx11("svg", __spreadProps(__spreadValues({ xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 512 512" }, props), { children: /* @__PURE__ */ jsxs5("g", { fillRule: "evenodd", children: [
  /* @__PURE__ */ jsx11("circle", { cx: 256, cy: 256, r: 20 }),
  /* @__PURE__ */ jsx11("rect", { width: 12, height: 200, x: 250, y: 40, rx: 6 })
] }) })), "SvgSecondHand02");
var second_hand_02_default = SvgSecondHand02;

// src/resources/clock/minute-hand-02.svg?react
import * as React6 from "react";
import { jsx as jsx12, jsxs as jsxs6 } from "react/jsx-runtime";
var SvgMinuteHand02 = /* @__PURE__ */ __name((props) => /* @__PURE__ */ jsx12("svg", __spreadProps(__spreadValues({ xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 512 512" }, props), { children: /* @__PURE__ */ jsxs6("g", { fillRule: "evenodd", children: [
  /* @__PURE__ */ jsx12("path", { d: "M256 236a20 20 0 1 1 0 40 20 20 0 0 1 0-40Zm.5 12a7.5 7.5 0 1 0 0 15 7.5 7.5 0 0 0 0-15Z" }),
  /* @__PURE__ */ jsx12("rect", { width: 22, height: 154, x: 245, y: 43, rx: 10.5 }),
  /* @__PURE__ */ jsx12("rect", { width: 10, height: 79, x: 251, y: 161, rx: 5 }),
  /* @__PURE__ */ jsx12("rect", { width: 10, height: 25, x: 251, y: 172, rx: 5 })
] }) })), "SvgMinuteHand02");
var minute_hand_02_default = SvgMinuteHand02;

// src/resources/clock/hour-hand-02.svg?react
import * as React7 from "react";
import { jsx as jsx13, jsxs as jsxs7 } from "react/jsx-runtime";
var SvgHourHand02 = /* @__PURE__ */ __name((props) => /* @__PURE__ */ jsx13("svg", __spreadProps(__spreadValues({ xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 512 512" }, props), { children: /* @__PURE__ */ jsxs7("g", { fillRule: "evenodd", children: [
  /* @__PURE__ */ jsx13("path", { d: "M256 236a20 20 0 1 1 0 40 20 20 0 0 1 0-40Zm0 10a10 10 0 1 0 0 20 10 10 0 0 0 0-20Z" }),
  /* @__PURE__ */ jsx13("rect", { width: 22, height: 98, x: 245, y: 98, rx: 11 }),
  /* @__PURE__ */ jsx13("rect", { width: 10, height: 79, x: 251, y: 161, rx: 5 })
] }) })), "SvgHourHand02");
var hour_hand_02_default = SvgHourHand02;

// src/resources/clock/face-02.svg?react
import * as React8 from "react";
import { jsx as jsx14 } from "react/jsx-runtime";
var SvgFace02 = /* @__PURE__ */ __name((props) => /* @__PURE__ */ jsx14("svg", __spreadProps(__spreadValues({ xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 512 512" }, props), { children: /* @__PURE__ */ jsx14("path", { fillRule: "evenodd", d: "M437 0a75 75 0 0 1 75 75v362a75 75 0 0 1-75 75H75a75 75 0 0 1-75-75V75A75 75 0 0 1 75 0h362Zm-59 22H134C72.14 22 22 72.14 22 134v244c0 61.86 50.14 112 112 112h244c61.86 0 112-50.14 112-112V134c0-61.86-50.14-112-112-112Z" }) })), "SvgFace02");
var face_02_default = SvgFace02;

// src/resources/clock/center-02.svg?react
import * as React9 from "react";
import { jsx as jsx15, jsxs as jsxs8 } from "react/jsx-runtime";
var SvgCenter02 = /* @__PURE__ */ __name((props) => /* @__PURE__ */ jsx15("svg", __spreadProps(__spreadValues({ xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 512 512" }, props), { children: /* @__PURE__ */ jsxs8("g", { fillRule: "evenodd", children: [
  /* @__PURE__ */ jsx15("path", { d: "M256 243a13 13 0 1 1 0 26 13 13 0 0 1 0-26Zm0 3a10 10 0 1 0 0 20 10 10 0 0 0 0-20Z" }),
  /* @__PURE__ */ jsxs8("g", { transform: "translate(-206 -206)", children: [
    /* @__PURE__ */ jsxs8("g", { transform: "translate(124 124)", children: [
      /* @__PURE__ */ jsx15("circle", { cx: 256, cy: 29, r: 5, transform: "translate(82 82)" }),
      /* @__PURE__ */ jsx15("circle", { cx: 256, cy: 29, r: 5, transform: "rotate(6 -485.327 1079.327)" }),
      /* @__PURE__ */ jsx15("circle", { cx: 256, cy: 29, r: 5, transform: "rotate(12 -93.089 687.089)" }),
      /* @__PURE__ */ jsx15("circle", { cx: 256, cy: 29, r: 5, transform: "rotate(18 38.136 555.864)" }),
      /* @__PURE__ */ jsx15("circle", { cx: 256, cy: 29, r: 5, transform: "rotate(24 104.11 489.89)" })
    ] }),
    /* @__PURE__ */ jsxs8("g", { transform: "rotate(30 168.613 631.387)", children: [
      /* @__PURE__ */ jsx15("circle", { cx: 256, cy: 29, r: 5, transform: "translate(82 82)" }),
      /* @__PURE__ */ jsx15("circle", { cx: 256, cy: 29, r: 5, transform: "rotate(6 -485.327 1079.327)" }),
      /* @__PURE__ */ jsx15("circle", { cx: 256, cy: 29, r: 5, transform: "rotate(12 -93.089 687.089)" }),
      /* @__PURE__ */ jsx15("circle", { cx: 256, cy: 29, r: 5, transform: "rotate(18 38.136 555.864)" }),
      /* @__PURE__ */ jsx15("circle", { cx: 256, cy: 29, r: 5, transform: "rotate(24 104.11 489.89)" })
    ] }),
    /* @__PURE__ */ jsxs8("g", { transform: "rotate(60 292.613 507.387)", children: [
      /* @__PURE__ */ jsx15("circle", { cx: 256, cy: 29, r: 5, transform: "translate(82 82)" }),
      /* @__PURE__ */ jsx15("circle", { cx: 256, cy: 29, r: 5, transform: "rotate(6 -485.327 1079.327)" }),
      /* @__PURE__ */ jsx15("circle", { cx: 256, cy: 29, r: 5, transform: "rotate(12 -93.089 687.089)" }),
      /* @__PURE__ */ jsx15("circle", { cx: 256, cy: 29, r: 5, transform: "rotate(18 38.136 555.864)" }),
      /* @__PURE__ */ jsx15("circle", { cx: 256, cy: 29, r: 5, transform: "rotate(24 104.11 489.89)" })
    ] }),
    /* @__PURE__ */ jsxs8("g", { transform: "rotate(90 338 462)", children: [
      /* @__PURE__ */ jsx15("circle", { cx: 256, cy: 29, r: 5, transform: "translate(82 82)" }),
      /* @__PURE__ */ jsx15("circle", { cx: 256, cy: 29, r: 5, transform: "rotate(6 -485.327 1079.327)" }),
      /* @__PURE__ */ jsx15("circle", { cx: 256, cy: 29, r: 5, transform: "rotate(12 -93.089 687.089)" }),
      /* @__PURE__ */ jsx15("circle", { cx: 256, cy: 29, r: 5, transform: "rotate(18 38.136 555.864)" }),
      /* @__PURE__ */ jsx15("circle", { cx: 256, cy: 29, r: 5, transform: "rotate(24 104.11 489.89)" })
    ] }),
    /* @__PURE__ */ jsxs8("g", { transform: "rotate(120 364.204 435.796)", children: [
      /* @__PURE__ */ jsx15("circle", { cx: 256, cy: 29, r: 5, transform: "translate(82 82)" }),
      /* @__PURE__ */ jsx15("circle", { cx: 256, cy: 29, r: 5, transform: "rotate(6 -485.327 1079.327)" }),
      /* @__PURE__ */ jsx15("circle", { cx: 256, cy: 29, r: 5, transform: "rotate(12 -93.089 687.089)" }),
      /* @__PURE__ */ jsx15("circle", { cx: 256, cy: 29, r: 5, transform: "rotate(18 38.136 555.864)" }),
      /* @__PURE__ */ jsx15("circle", { cx: 256, cy: 29, r: 5, transform: "rotate(24 104.11 489.89)" })
    ] }),
    /* @__PURE__ */ jsxs8("g", { transform: "rotate(150 383.387 416.613)", children: [
      /* @__PURE__ */ jsx15("circle", { cx: 256, cy: 29, r: 5, transform: "translate(82 82)" }),
      /* @__PURE__ */ jsx15("circle", { cx: 256, cy: 29, r: 5, transform: "rotate(6 -485.327 1079.327)" }),
      /* @__PURE__ */ jsx15("circle", { cx: 256, cy: 29, r: 5, transform: "rotate(12 -93.089 687.089)" }),
      /* @__PURE__ */ jsx15("circle", { cx: 256, cy: 29, r: 5, transform: "rotate(18 38.136 555.864)" }),
      /* @__PURE__ */ jsx15("circle", { cx: 256, cy: 29, r: 5, transform: "rotate(24 104.11 489.89)" })
    ] }),
    /* @__PURE__ */ jsxs8("g", { transform: "rotate(180 400 400)", children: [
      /* @__PURE__ */ jsx15("circle", { cx: 256, cy: 29, r: 5, transform: "translate(82 82)" }),
      /* @__PURE__ */ jsx15("circle", { cx: 256, cy: 29, r: 5, transform: "rotate(6 -485.327 1079.327)" }),
      /* @__PURE__ */ jsx15("circle", { cx: 256, cy: 29, r: 5, transform: "rotate(12 -93.089 687.089)" }),
      /* @__PURE__ */ jsx15("circle", { cx: 256, cy: 29, r: 5, transform: "rotate(18 38.136 555.864)" }),
      /* @__PURE__ */ jsx15("circle", { cx: 256, cy: 29, r: 5, transform: "rotate(24 104.11 489.89)" })
    ] }),
    /* @__PURE__ */ jsxs8("g", { transform: "rotate(-150 416.613 383.387)", children: [
      /* @__PURE__ */ jsx15("circle", { cx: 256, cy: 29, r: 5, transform: "translate(82 82)" }),
      /* @__PURE__ */ jsx15("circle", { cx: 256, cy: 29, r: 5, transform: "rotate(6 -485.327 1079.327)" }),
      /* @__PURE__ */ jsx15("circle", { cx: 256, cy: 29, r: 5, transform: "rotate(12 -93.089 687.089)" }),
      /* @__PURE__ */ jsx15("circle", { cx: 256, cy: 29, r: 5, transform: "rotate(18 38.136 555.864)" }),
      /* @__PURE__ */ jsx15("circle", { cx: 256, cy: 29, r: 5, transform: "rotate(24 104.11 489.89)" })
    ] }),
    /* @__PURE__ */ jsxs8("g", { transform: "rotate(-120 435.796 364.204)", children: [
      /* @__PURE__ */ jsx15("circle", { cx: 256, cy: 29, r: 5, transform: "translate(82 82)" }),
      /* @__PURE__ */ jsx15("circle", { cx: 256, cy: 29, r: 5, transform: "rotate(6 -485.327 1079.327)" }),
      /* @__PURE__ */ jsx15("circle", { cx: 256, cy: 29, r: 5, transform: "rotate(12 -93.089 687.089)" }),
      /* @__PURE__ */ jsx15("circle", { cx: 256, cy: 29, r: 5, transform: "rotate(18 38.136 555.864)" }),
      /* @__PURE__ */ jsx15("circle", { cx: 256, cy: 29, r: 5, transform: "rotate(24 104.11 489.89)" })
    ] }),
    /* @__PURE__ */ jsxs8("g", { transform: "rotate(-90 462 338)", children: [
      /* @__PURE__ */ jsx15("circle", { cx: 256, cy: 29, r: 5, transform: "translate(82 82)" }),
      /* @__PURE__ */ jsx15("circle", { cx: 256, cy: 29, r: 5, transform: "rotate(6 -485.327 1079.327)" }),
      /* @__PURE__ */ jsx15("circle", { cx: 256, cy: 29, r: 5, transform: "rotate(12 -93.089 687.089)" }),
      /* @__PURE__ */ jsx15("circle", { cx: 256, cy: 29, r: 5, transform: "rotate(18 38.136 555.864)" }),
      /* @__PURE__ */ jsx15("circle", { cx: 256, cy: 29, r: 5, transform: "rotate(24 104.11 489.89)" })
    ] }),
    /* @__PURE__ */ jsxs8("g", { transform: "rotate(-60 507.387 292.613)", children: [
      /* @__PURE__ */ jsx15("circle", { cx: 256, cy: 29, r: 5, transform: "translate(82 82)" }),
      /* @__PURE__ */ jsx15("circle", { cx: 256, cy: 29, r: 5, transform: "rotate(6 -485.327 1079.327)" }),
      /* @__PURE__ */ jsx15("circle", { cx: 256, cy: 29, r: 5, transform: "rotate(12 -93.089 687.089)" }),
      /* @__PURE__ */ jsx15("circle", { cx: 256, cy: 29, r: 5, transform: "rotate(18 38.136 555.864)" }),
      /* @__PURE__ */ jsx15("circle", { cx: 256, cy: 29, r: 5, transform: "rotate(24 104.11 489.89)" })
    ] }),
    /* @__PURE__ */ jsxs8("g", { transform: "rotate(-30 631.387 168.613)", children: [
      /* @__PURE__ */ jsx15("circle", { cx: 256, cy: 29, r: 5, transform: "translate(82 82)" }),
      /* @__PURE__ */ jsx15("circle", { cx: 256, cy: 29, r: 5, transform: "rotate(6 -485.327 1079.327)" }),
      /* @__PURE__ */ jsx15("circle", { cx: 256, cy: 29, r: 5, transform: "rotate(12 -93.089 687.089)" }),
      /* @__PURE__ */ jsx15("circle", { cx: 256, cy: 29, r: 5, transform: "rotate(18 38.136 555.864)" }),
      /* @__PURE__ */ jsx15("circle", { cx: 256, cy: 29, r: 5, transform: "rotate(24 104.11 489.89)" })
    ] })
  ] }),
  /* @__PURE__ */ jsxs8("g", { transform: "translate(-94 -94)", children: [
    /* @__PURE__ */ jsx15("rect", { width: 10, height: 50, x: 251, y: 24, rx: 5, transform: "translate(94 94)" }),
    /* @__PURE__ */ jsx15("rect", { width: 10, height: 50, x: 251, y: 24, rx: 5, transform: "rotate(30 127.594 478.406)" }),
    /* @__PURE__ */ jsx15("rect", { width: 10, height: 50, x: 251, y: 24, rx: 5, transform: "rotate(60 221.594 384.406)" }),
    /* @__PURE__ */ jsx15("rect", { width: 10, height: 50, x: 251, y: 24, rx: 5, transform: "rotate(90 256 350)" }),
    /* @__PURE__ */ jsx15("rect", { width: 10, height: 50, x: 251, y: 24, rx: 5, transform: "rotate(120 275.865 330.135)" }),
    /* @__PURE__ */ jsx15("rect", { width: 10, height: 50, x: 251, y: 24, rx: 5, transform: "rotate(150 290.406 315.594)" }),
    /* @__PURE__ */ jsx15("rect", { width: 10, height: 50, x: 251, y: 24, rx: 5, transform: "rotate(180 303 303)" }),
    /* @__PURE__ */ jsx15("rect", { width: 10, height: 50, x: 251, y: 24, rx: 5, transform: "rotate(-150 315.594 290.406)" }),
    /* @__PURE__ */ jsx15("rect", { width: 10, height: 50, x: 251, y: 24, rx: 5, transform: "rotate(-120 330.135 275.865)" }),
    /* @__PURE__ */ jsx15("rect", { width: 10, height: 50, x: 251, y: 24, rx: 5, transform: "rotate(-90 350 256)" }),
    /* @__PURE__ */ jsx15("rect", { width: 10, height: 50, x: 251, y: 24, rx: 5, transform: "rotate(-60 384.406 221.594)" }),
    /* @__PURE__ */ jsx15("rect", { width: 10, height: 50, x: 251, y: 24, rx: 5, transform: "rotate(-30 478.406 127.594)" }),
    /* @__PURE__ */ jsx15("rect", { width: 10, height: 50, x: 251, y: 24, rx: 5, transform: "rotate(-90 350 256)" })
  ] }),
  /* @__PURE__ */ jsx15("path", { d: "M256 0c141.38 0 256 114.62 256 256S397.38 512 256 512 0 397.38 0 256 114.62 0 256 0Zm0 9C119.59 9 9 119.59 9 256s110.59 247 247 247 247-110.59 247-247S392.41 9 256 9Z" })
] }) })), "SvgCenter02");
var center_02_default = SvgCenter02;

// src/resources/clock/guide-02.svg?react
import * as React10 from "react";
import { jsx as jsx16, jsxs as jsxs9 } from "react/jsx-runtime";
var SvgGuide02 = /* @__PURE__ */ __name((props) => /* @__PURE__ */ jsx16("svg", __spreadProps(__spreadValues({ xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 512 512" }, props), { children: /* @__PURE__ */ jsxs9("g", { fillRule: "evenodd", children: [
  /* @__PURE__ */ jsx16("rect", { width: 512, height: 1, y: 256 }),
  /* @__PURE__ */ jsx16("rect", { width: 512, height: 1, y: 128 }),
  /* @__PURE__ */ jsx16("rect", { width: 512, height: 1, y: 384 }),
  /* @__PURE__ */ jsx16("rect", { width: 1, height: 512, x: 256, transform: "matrix(1 0 0 -1 0 512)" }),
  /* @__PURE__ */ jsx16("rect", { width: 1, height: 512, x: 128, transform: "matrix(1 0 0 -1 0 512)" }),
  /* @__PURE__ */ jsx16("rect", { width: 1, height: 512, x: 384, transform: "matrix(1 0 0 -1 0 512)" })
] }) })), "SvgGuide02");
var guide_02_default = SvgGuide02;

// src/resources/clock.tsx
import { jsx as jsx17 } from "react/jsx-runtime";
var clockComponent2 = {
  // SecondHand: (props: SvgProps): JSX.Element => (
  //   <SVG {...props} src={secondHand} />
  // ),
  // SecondHand: (props: any) => <img {...props} src={YourIcon} />,
  SecondHand: (props) => /* @__PURE__ */ jsx17(second_hand_02_default, __spreadValues({}, props)),
  // SecondHand: (props: any) => <SVG {...props} src={YourIcon} />,
  MinuteHand: (props) => /* @__PURE__ */ jsx17(minute_hand_02_default, __spreadValues({}, props)),
  HourHand: (props) => /* @__PURE__ */ jsx17(hour_hand_02_default, __spreadValues({}, props)),
  Face: (props) => /* @__PURE__ */ jsx17(face_02_default, __spreadValues({}, props)),
  Center: (props) => /* @__PURE__ */ jsx17(center_02_default, __spreadValues({}, props)),
  Guide: (props) => /* @__PURE__ */ jsx17(guide_02_default, __spreadValues({}, props))
};

// src/components/svg/svg-pos.tsx
import { jsx as jsx18 } from "react/jsx-runtime";
function SvgLayer(props) {
  return /* @__PURE__ */ jsx18(Center, { width: props.size, height: props.height, children: Object.values(props.svgs).map((SvgA, i) => {
    return /* @__PURE__ */ jsx18(
      SvgA.element,
      {
        style: {
          height: SvgA.size,
          width: SvgA.size,
          position: "absolute",
          fill: SvgA.fill,
          transform: SvgA.transform
        }
      },
      i
    );
  }) });
}
__name(SvgLayer, "SvgLayer");

// src/components/clock/ticker/ClockTicker.tsx
import { jsx as jsx19 } from "react/jsx-runtime";
function ClockTicker(p) {
  return /* @__PURE__ */ jsx19(SvgLayer, { svgs: p.elements, size: p.width, height: p.height });
}
__name(ClockTicker, "ClockTicker");

// src/components/clock/ticker/big/get-date-degrees.tsx
import moment7 from "moment";

// src/components/clock/support/clock-degrees.ts
function getSecDeg(mm) {
  return mm * 6;
}
__name(getSecDeg, "getSecDeg");
function getMinDeg(mm) {
  return mm * 6;
}
__name(getMinDeg, "getMinDeg");
function getHourDeg1(hh) {
  if (hh == 12 || hh == 24) {
    return 0;
  }
  if (hh > 12) {
    return (hh - 12) * 30;
  }
  return hh * 30;
}
__name(getHourDeg1, "getHourDeg1");
function getHourDeg(hh, mm) {
  const degreeHours = getHourDeg1(hh);
  const degreeMinutes = getMinDeg(mm) / 12;
  return degreeHours + degreeMinutes;
}
__name(getHourDeg, "getHourDeg");

// src/components/clock/ticker/big/get-date-degrees.tsx
function getDateDegrees(p) {
  const x = {
    ss: () => parseInt(`${moment7(p.date).format("ss")}`),
    ssDeg: () => getSecDeg(x.ss()),
    ssTransform: () => `rotate(${x.ssDeg()}deg)`,
    mm: () => parseInt(`${moment7(p.date).format("mm")}`),
    mmDeg: () => getMinDeg(x.mm()),
    mmTransform: () => `rotate(${x.mmDeg()}deg)`,
    hh: () => parseInt(`${moment7(p.date).format("HH")}`),
    hhDeg: () => getHourDeg(x.hh(), x.mm()),
    hhTransform: () => `rotate(${x.hhDeg()}deg)`
  };
  return x;
}
__name(getDateDegrees, "getDateDegrees");

// src/components/clock/date/DateBox.tsx
import moment8 from "moment";
import { useTheme as useTheme2 } from "styled-components";
import { jsx as jsx20 } from "react/jsx-runtime";
function useDateStyle(cellSize, offset) {
  const theme = useTheme2();
  const _offset = offset || 1;
  return {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: `calc(${cellSize} * 0.009) solid ${theme.colors.background.gr2}`,
    borderRadius: `calc(${cellSize} * 0.02)`,
    color: theme.colors.background.gr3,
    backgroundColor: theme.colors.background.gr0,
    width: `calc(${cellSize} * 0.12)`,
    height: `calc(${cellSize} * 0.12)`,
    fontSize: `calc(calc(${cellSize} * 0.08) * ${_offset})`
  };
}
__name(useDateStyle, "useDateStyle");
function DateBox(p) {
  const dd = parseInt(`${moment8(p.date).format("DD")}`);
  const cellSize = p.size;
  const offset = p.offset;
  const theme = useTheme2();
  const style = useDateStyle(cellSize, offset);
  return /* @__PURE__ */ jsx20(
    "span",
    {
      style: {
        // height: p.size,
        // width: p.size,
      },
      children: /* @__PURE__ */ jsx20("div", { style, children: String(dd).padStart(2, "0") })
    }
  );
}
__name(DateBox, "DateBox");
function DateBox2(p) {
  const dd = parseInt(`${moment8(p.date).format("MM")}`);
  const cellSize = p.size;
  const offset = p.offset;
  const theme = useTheme2();
  const style = useDateStyle(cellSize, offset);
  return /* @__PURE__ */ jsx20(
    "span",
    {
      style: {
        // height: p.size,
        // width: p.size,
      },
      children: /* @__PURE__ */ jsx20("div", { style, children: String(dd).padStart(2, "0") })
    }
  );
}
__name(DateBox2, "DateBox2");

// src/components/clock/ticker/big/DateBoxWrapper.tsx
import styled2, { css, useTheme as useTheme3 } from "styled-components";

// src/components/base/rangeFromCount.tsx
function rangeFromCount(params) {
  const { count, start } = params;
  return Array.from({ length: count }, (_, index) => index + start);
}
__name(rangeFromCount, "rangeFromCount");

// src/components/clock/ticker/big/DateBoxWrapper.tsx
import { jsx as jsx21 } from "react/jsx-runtime";
var GridClockFace = css`
  display: grid;
  place-items: center;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  gap: 0px 0px;
  grid-template-areas:
    '. . . . . . . .'
    '. . . . . . . .'
    '. . . . . . . .'
    '. . . . . . . .'
    '. . . . . . . .'
    '. . . . . . . .'
    '. . . . . . . .'
    '. . . . . . . .';
`;
var StyledFace = styled2.div`
  ${GridClockFace}
`;
var faceItems = rangeFromCount({ count: 56, start: 1 });
function DateBoxWrapper(p) {
  const theme = useTheme3();
  const { size, boxOffset } = p;
  const cellSize = `calc(${size} * 0.116)`;
  const faces = faceItems.map((value, index) => {
    const dateBoxSize = `calc(${cellSize} * 5.8)`;
    if (index === 29) {
      return /* @__PURE__ */ jsx21(
        DateBox,
        {
          offset: boxOffset,
          size: dateBoxSize,
          date: p.date
        },
        index
      );
    }
    if (index === 37) {
      return /* @__PURE__ */ jsx21(
        DateBox2,
        {
          offset: boxOffset,
          size: dateBoxSize,
          date: p.date
        },
        index
      );
    }
    return /* @__PURE__ */ jsx21(
      "div",
      {
        style: {
          width: cellSize,
          height: cellSize
          // backgroundColor: 'blue',
          // border: '1px solid red',
        }
      },
      index
    );
  });
  return /* @__PURE__ */ jsx21(Center, { children: /* @__PURE__ */ jsx21(StyledFace, { children: faces }) });
}
__name(DateBoxWrapper, "DateBoxWrapper");

// src/components/clock/ticker/big/ClockTickerBig.tsx
import { useTheme as useTheme4 } from "styled-components";
import { jsx as jsx22 } from "react/jsx-runtime";
function ClockTickerBig(props) {
  const theme = useTheme4();
  const dateDegree = getDateDegrees({ date: props.date });
  const size = props.size;
  const offset = props.offset;
  return /* @__PURE__ */ jsx22(
    ClockTicker,
    {
      width: size,
      height: size,
      seconds: dateDegree.ss(),
      elements: [
        {
          element: () => /* @__PURE__ */ jsx22(DateBoxWrapper, { boxOffset: offset, size, date: props.date }),
          size
        },
        {
          element: clockComponent2.Center,
          fill: theme.colors.background.gr4,
          size
        },
        {
          element: clockComponent2.HourHand,
          fill: theme.colors.background.gr4,
          transform: dateDegree.hhTransform(),
          size
        },
        {
          element: clockComponent2.MinuteHand,
          fill: theme.colors.background.gr4,
          transform: dateDegree.mmTransform(),
          size
        },
        {
          element: clockComponent2.SecondHand,
          fill: theme.colors.mark.mark,
          transform: dateDegree.ssTransform(),
          size
        }
      ]
    }
  );
}
__name(ClockTickerBig, "ClockTickerBig");

// src/util/computed.ts
function elementById(s) {
  return window.document.getElementById(s);
}
__name(elementById, "elementById");
function getComputedStyleFromElement(element) {
  if (element) {
    return window.getComputedStyle(element);
  }
  return null;
}
__name(getComputedStyleFromElement, "getComputedStyleFromElement");
function getComputedStyleFromId(id) {
  return getComputedStyleFromElement(elementById(id));
}
__name(getComputedStyleFromId, "getComputedStyleFromId");

// src/components/clock/Clocker.tsx
import { jsx as jsx23 } from "react/jsx-runtime";
var ClockBig2 = /* @__PURE__ */ __name((p) => {
  const computedSize2 = getComputedStyleFromId(CalendarFull.idName);
  const height = p.size;
  const width = height;
  console.log("sss", height);
  const size = `calc(${width} * 0.64)`;
  return /* @__PURE__ */ jsx23(Center, { children: /* @__PURE__ */ jsx23(
    BackgroundComponent,
    {
      size: height,
      children: /* @__PURE__ */ jsx23(Center, { children: /* @__PURE__ */ jsx23(ClockTickerBig, { date: p.date, size, offset: 1 }) })
    }
  ) });
}, "ClockBig2");

// src/hooks/date-ref.tsx
import React11, { useCallback as useCallback2 } from "react";
import { useEffect as useEffect2, useState as useState4 } from "react";
import { jsx as jsx24 } from "react/jsx-runtime";
function useDateRef(p) {
  const [date, setDate] = useState4(p.mockDate ? p.mockDate : p.date);
  useEffect2(() => {
    const timerID = setInterval(() => tick(), p.interval);
    return /* @__PURE__ */ __name(function cleanup() {
      clearInterval(timerID);
    }, "cleanup");
  });
  return { date };
  function tick() {
    if (p.mockDate) {
      setDate(p.mockDate + p.interval);
    } else {
      setDate((/* @__PURE__ */ new Date()).valueOf());
    }
  }
  __name(tick, "tick");
}
__name(useDateRef, "useDateRef");

// src/components/layout/Row.tsx
import { jsx as jsx25 } from "react/jsx-runtime";
function Row(props) {
  const { id, children, style } = props;
  return /* @__PURE__ */ jsx25(
    WrapDirection,
    {
      id,
      style: __spreadValues({
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "row"
      }, style),
      children
    }
  );
}
__name(Row, "Row");

// src/components/layout/Column.tsx
import { jsx as jsx26 } from "react/jsx-runtime";
function Column(p) {
  const { id, children, style } = p;
  return /* @__PURE__ */ jsx26(
    WrapDirection,
    {
      id,
      style: __spreadValues({
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column"
      }, style),
      children
    }
  );
}
__name(Column, "Column");

// src/app/domains/modes/mode-2/Mode2.tsx
import { Fragment, jsx as jsx27, jsxs as jsxs10 } from "react/jsx-runtime";
function Mode2() {
  useStart(fonts2.font1);
  const date = useDateRef({
    date: (/* @__PURE__ */ new Date()).valueOf(),
    //mockDate: new Date('2021-12-04T01:45:00').valueOf(),
    interval: 200
  }).date;
  return /* @__PURE__ */ jsx27(Fragment, { children: /* @__PURE__ */ jsx27(DateColumn, { date }) });
}
__name(Mode2, "Mode2");
function DateColumn(p) {
  return /* @__PURE__ */ jsx27("div", { style: { display: "flex" }, children: /* @__PURE__ */ jsx27(
    BackgroundComponent,
    {
      size: "1rem",
      children: /* @__PURE__ */ jsxs10(
        Row,
        {
          children: [
            /* @__PURE__ */ jsx27(CalendarFull, { width: 3, date: p.date }),
            /* @__PURE__ */ jsxs10(Column, { children: [
              /* @__PURE__ */ jsx27(BackgroundComponent, { size: "1rem", children: /* @__PURE__ */ jsx27(Center, { children: /* @__PURE__ */ jsx27(DisplayFor1080, { date: p.date }) }) }),
              /* @__PURE__ */ jsx27(Row, { children: /* @__PURE__ */ jsx27(ClockBig2, { size: "1rem", date: p.date }) })
            ] }),
            /* @__PURE__ */ jsx27(Row, {})
          ]
        }
      )
    }
  ) });
}
__name(DateColumn, "DateColumn");

// src/app/domains/modes/mode-3/Mode3.tsx
import moment10 from "moment";

// src/components/clock/ticker/small/use-div-ref.tsx
import { useEffect as useEffect3, useRef, useState as useState5 } from "react";
function useDivRef(p) {
  const rootRef = useRef();
  const [rootSize, setRootSize] = useState5(p.size);
  useEffect3(() => {
    if (rootRef && rootRef.current) {
      setRootSize(p.size);
    }
    return () => {
    };
  }, [p.size, rootRef]);
  return {
    rootSize,
    setRef: (ref) => {
      if (ref) {
        rootRef.current = ref;
      }
    }
  };
}
__name(useDivRef, "useDivRef");

// src/components/clock/ticker/small/get-dates.tsx
import moment9 from "moment";
function getDates(p) {
  return {
    ss: parseInt(`${moment9(p.date).format("ss")}`)
  };
}
__name(getDates, "getDates");

// src/components/clock/ticker/small/ClockTickerSmall.tsx
import { jsx as jsx28 } from "react/jsx-runtime";
function ClockTickerSmall(p) {
  const dates = getDates({ date: p.date });
  const divRef = useDivRef({ size: p.size });
  return /* @__PURE__ */ jsx28(
    "div",
    {
      ref: divRef.setRef,
      style: __spreadValues({
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: p.backgroundColor
      }, p.wrapStyle),
      children: /* @__PURE__ */ jsx28(
        ClockTicker,
        {
          style: __spreadValues({}, p.style),
          elements: [
            {
              element: clockComponent2.Center,
              size: p.size
            },
            {
              element: clockComponent2.SecondHand,
              // fill: colors.mark,
              transform: `rotate(${dates.ss * 6}deg)`,
              size: p.size
            }
          ],
          height: p.size,
          width: p.size,
          seconds: dates.ss
        }
      )
    }
  );
}
__name(ClockTickerSmall, "ClockTickerSmall");

// src/app/domains/modes/mode-3/Mode3.tsx
import { fonts as fonts3 } from "@mono/fonts";
import { jsx as jsx29, jsxs as jsxs11 } from "react/jsx-runtime";
function Mode3() {
  useStart(fonts3.font6);
  const date = useDateRef({
    date: (/* @__PURE__ */ new Date()).valueOf(),
    //mockDate: new Date('2021-12-04T01:45:00').valueOf(),
    interval: 50
  }).date;
  return /* @__PURE__ */ jsx29(DateColumn2, { date });
}
__name(Mode3, "Mode3");
function getDates2(p) {
  const _moment = moment10(p.date);
  return {
    hm: `${_moment.format("HH:mm")}`,
    ymd: `${_moment.format("YYYY-MM-DD")}`
  };
}
__name(getDates2, "getDates");
function getStyle() {
  const size = useWindowSize().width / 200;
  return {
    style: __spreadProps(__spreadValues({}, useFontStyle().fontStyle), {
      fontSize: useREM(size),
      padding: useREM(0.5),
      borderRadius: useREM(1)
    }),
    size
  };
}
__name(getStyle, "getStyle");
function DateColumn2(p) {
  const itemStyle = getStyle();
  return /* @__PURE__ */ jsx29(
    "div",
    {
      style: {
        margin: useREM(1)
      },
      children: /* @__PURE__ */ jsxs11(
        Row,
        {
          children: [
            /* @__PURE__ */ jsx29(Row, { children: /* @__PURE__ */ jsxs11(Column, { children: [
              /* @__PURE__ */ jsxs11(Row, { children: [
                /* @__PURE__ */ jsx29(CalendarFull, { width: itemStyle.size, date: p.date }),
                /* @__PURE__ */ jsx29(ClockBig2, { size: "1rem", date: p.date })
              ] }),
              /* @__PURE__ */ jsx29(Row, { children: /* @__PURE__ */ jsx29(DateLow, { date: p.date }) })
            ] }) }),
            /* @__PURE__ */ jsx29(Column, {})
          ]
        }
      )
    }
  );
}
__name(DateColumn2, "DateColumn");
var DateLow = /* @__PURE__ */ __name((p) => {
  const dates = getDates2({ date: p.date });
  const itemStyle = getStyle();
  const calsizeWidth = "1rem";
  return /* @__PURE__ */ jsx29(BackgroundComponent, { size: "1rem", children: /* @__PURE__ */ jsxs11(
    Row,
    {
      children: [
        /* @__PURE__ */ jsx29("span", { style: itemStyle.style, children: dates.ymd }),
        /* @__PURE__ */ jsx29("div", {}),
        /* @__PURE__ */ jsxs11(Row, { children: [
          /* @__PURE__ */ jsx29("span", { style: itemStyle.style, children: dates.hm }),
          /* @__PURE__ */ jsx29(
            ClockTickerSmall,
            {
              style: itemStyle.style,
              size: calsizeWidth,
              date: p.date.valueOf()
            }
          )
        ] })
      ]
    }
  ) });
}, "DateLow");

// src/app/domains/modes/mode-5/Mode5.tsx
import { fonts as fonts4 } from "@mono/fonts";

// src/app/domains/modes/mode-5/Mode5Column.tsx
import { jsx as jsx30 } from "react/jsx-runtime";
function Mode5Column(p) {
  const switchId = "switch";
  const computedSwitch = getComputedStyleFromId(switchId);
  const switchWidth = parseInt((computedSwitch == null ? void 0 : computedSwitch.width) || "0");
  const windowWidth = useWindowSize().width;
  const windowHeight = useWindowSize().height;
  const isBigger = /* @__PURE__ */ __name(() => {
    return windowWidth < windowHeight;
  }, "isBigger");
  const remainingSize = "1rem";
  return /* @__PURE__ */ jsx30(Center, { margin: useREM(1), height: "100vh", width: "100vw", children: /* @__PURE__ */ jsx30(Column, { alignItems: "center", children: /* @__PURE__ */ jsx30(Row, { children: /* @__PURE__ */ jsx30(Row, { children: /* @__PURE__ */ jsx30(Column, { children: /* @__PURE__ */ jsx30(Row, { width: "100%", children: /* @__PURE__ */ jsx30(ClockBig2, { size: remainingSize, date: p.date }) }) }) }) }) }) });
}
__name(Mode5Column, "Mode5Column");

// src/app/domains/modes/mode-5/Mode5.tsx
import { jsx as jsx31 } from "react/jsx-runtime";
function Mode5() {
  useStart(fonts4.font6);
  const date = useDateRef({
    date: (/* @__PURE__ */ new Date()).valueOf(),
    //mockDate: new Date('2021-12-04T01:45:00').valueOf(),
    interval: 50
  }).date;
  return /* @__PURE__ */ jsx31(Mode5Column, { date });
}
__name(Mode5, "Mode5");

// src/app/domains/modes/mode-6/Mode6.tsx
import { fonts as fonts5 } from "@mono/fonts";

// src/util/get-dates.tsx
import moment11 from "moment";
function getDates3(p) {
  const _moment = moment11(p.date);
  return {
    hm: `${_moment.format("HH:mm")}`,
    ymd: `${_moment.format("YYYY-MM-DD")}`
  };
}
__name(getDates3, "getDates");

// src/components/pack-clock/DateLow.tsx
import { jsx as jsx32 } from "react/jsx-runtime";
function getStyle2() {
  return __spreadProps(__spreadValues({}, useFontStyle().fontStyle), {
    padding: useREM(0.5),
    paddingLeft: useREM(1.5),
    paddingRight: useREM(1.5),
    borderRadius: useREM(11)
  });
}
__name(getStyle2, "getStyle");
var DateTimeComponent = /* @__PURE__ */ __name((p) => {
  const dates = getDates3({ date: p.date });
  return /* @__PURE__ */ jsx32(
    "span",
    {
      style: __spreadProps(__spreadValues({}, p.itemStyle), {
        color: p.color
      }),
      children: dates.ymd
    }
  );
}, "DateTimeComponent");
var ClockTimeComponent = /* @__PURE__ */ __name((p) => {
  const dates = getDates3({ date: p.date });
  return /* @__PURE__ */ jsx32(
    "span",
    {
      style: __spreadProps(__spreadValues({}, p.itemStyle), {
        color: p.color
      }),
      children: dates.hm
    }
  );
}, "ClockTimeComponent");

// src/app/domains/modes/mode-6/Mode6Column.tsx
import { jsx as jsx33, jsxs as jsxs12 } from "react/jsx-runtime";
function Mode6Column(p) {
  const switchId = "switch";
  const computedSwitch = getComputedStyleFromId(switchId);
  const switchWidth = parseInt((computedSwitch == null ? void 0 : computedSwitch.width) || "0");
  const windowWidth = useWindowSize().width;
  const windowHeight = useWindowSize().height;
  const isBigger = /* @__PURE__ */ __name(() => {
    return windowWidth < windowHeight;
  }, "isBigger");
  const remainingSize = "1rem";
  const screenWidth = remainingSize;
  const itemStyle = getStyle2();
  itemStyle.fontSize = "1rem";
  return /* @__PURE__ */ jsx33(Center, { margin: useREM(1), height: "100vh", width: "100vw", children: /* @__PURE__ */ jsx33(Column, { alignItems: "center", children: /* @__PURE__ */ jsx33(Row, { children: /* @__PURE__ */ jsx33(Row, { children: /* @__PURE__ */ jsxs12(Column, { children: [
    /* @__PURE__ */ jsx33(
      DateTimeComponent,
      {
        color: "red",
        date: p.date,
        itemStyle: __spreadProps(__spreadValues({}, itemStyle), {
          fontSize: "1rem"
          //useREM(screenWidth),
        })
      }
    ),
    /* @__PURE__ */ jsx33(
      ClockTimeComponent,
      {
        color: "blue",
        date: p.date,
        itemStyle: __spreadProps(__spreadValues({}, itemStyle), {
          fontSize: "1rem"
          // useREM(screenWidth * 8),
        })
      }
    ),
    /* @__PURE__ */ jsx33(
      ClockTickerSmall,
      {
        style: __spreadValues({}, itemStyle),
        size: screenWidth,
        date: p.date.valueOf(),
        wrapStyle: {
          paddingLeft: 0,
          paddingRight: 0
        }
      }
    )
  ] }) }) }) }) });
}
__name(Mode6Column, "Mode6Column");

// src/app/domains/modes/mode-6/Mode6.tsx
import { jsx as jsx34 } from "react/jsx-runtime";
function Mode6() {
  useStart(fonts5.font6);
  const date = useDateRef({
    date: (/* @__PURE__ */ new Date()).valueOf(),
    //mockDate: new Date('2021-12-04T01:45:00').valueOf(),
    interval: 50
  }).date;
  return /* @__PURE__ */ jsx34(Mode6Column, { date });
}
__name(Mode6, "Mode6");

// src/app/domains/modes/mode-8/Mode8.tsx
import { useReducer, useState as useState9 } from "react";
import { fonts as fonts6 } from "@mono/fonts";

// src/app/domains/modes/mode-8/components/field-set/FieldSet.tsx
import { useState as useState7 } from "react";

// src/app/domains/modes/mode-8/styles.tsx
var inputLabelStyle = {
  display: "flex",
  flexDirection: "column",
  padding: "10px"
};
var radioLabelStyle = {
  display: "flex",
  flexDirection: "row",
  margin: "10px",
  alignItems: "center"
};
function getLabelStyle(p) {
  if (p.type === "radio") {
    return __spreadProps(__spreadValues({}, radioLabelStyle), {
      // backgroundColor: 'red',
      alignItems: "center"
    });
  }
  return inputLabelStyle;
}
__name(getLabelStyle, "getLabelStyle");
function getInputStyle(p) {
  if (p.type === "radio") {
    return {
      width: "20px",
      height: "20px",
      backgroundColor: "red",
      alignItems: "center"
    };
  }
  return {
    width: "100%",
    justifyContent: "center",
    alignItems: "center"
  };
}
__name(getInputStyle, "getInputStyle");

// src/app/domains/modes/mode-8/components/field-set/Groups.tsx
import { jsx as jsx35, jsxs as jsxs13 } from "react/jsx-runtime";
function isGroupError(group) {
  if (group) {
    return group.error.onValidity;
  }
  throw new Error("A group error is needed");
}
__name(isGroupError, "isGroupError");
function GroupError(p) {
  return p.groupError ? /* @__PURE__ */ jsx35(
    "span",
    {
      style: {
        color: "red"
      },
      children: p.groupError
    }
  ) : /* @__PURE__ */ jsx35("span", { children: "\xA0 " });
}
__name(GroupError, "GroupError");
function GroupLabelWithError(p) {
  return /* @__PURE__ */ jsxs13("label", { style: { display: "flex", justifyContent: "space-between" }, children: [
    p.groupName,
    " ",
    /* @__PURE__ */ jsx35(GroupError, { groupError: p.groupError })
  ] });
}
__name(GroupLabelWithError, "GroupLabelWithError");

// src/app/domains/modes/mode-8/components/field-set/Input.tsx
import { useState as useState6 } from "react";
import { jsx as jsx36, jsxs as jsxs14 } from "react/jsx-runtime";
function InputError(p) {
  return p.error ? /* @__PURE__ */ jsx36("span", { style: { color: "red" }, children: p.error }) : /* @__PURE__ */ jsx36("span", { children: "\xA0" });
}
__name(InputError, "InputError");
function InputWithLabel(p) {
  const [error, setError] = useState6();
  return /* @__PURE__ */ jsxs14(
    "label",
    {
      style: p.labelStyle,
      title: `${p.input.label.text}`,
      children: [
        /* @__PURE__ */ jsxs14("span", { style: { display: "flex", justifyContent: "space-between" }, children: [
          p.input.label.text,
          " ",
          /* @__PURE__ */ jsx36(InputError, { error })
        ] }),
        /* @__PURE__ */ jsx36("div", { children: /* @__PURE__ */ jsx36(
          Input,
          {
            inputStyle: p.inputStyle,
            field: p.input.field,
            onInvalid: (event) => {
              event.preventDefault();
              setError(
                p.handleValidity({
                  valid: false,
                  error: p.input.error.onValidity
                })
              );
            },
            onBlur: (e) => {
              setError(
                p.handleValidity({
                  valid: e.target.validity.valid,
                  error: p.input.error.onChange
                })
              );
            },
            onChange: (e) => {
              setError(
                p.handleValidity({
                  valid: e.target.validity.valid,
                  error: p.input.error.onChange
                })
              );
              p.onChange(e);
            }
          }
        ) })
      ]
    },
    p.input.field.id
  );
}
__name(InputWithLabel, "InputWithLabel");
function Input(p) {
  const fontFamily = useStyle().style.fontFamily;
  return /* @__PURE__ */ jsx36(
    "input",
    __spreadProps(__spreadValues({
      style: __spreadProps(__spreadValues({}, p.inputStyle), { fontFamily, color: "black" })
    }, p.field), {
      onInvalid: p.onInvalid,
      onBlur: p.onBlur,
      onChange: p.onChange,
      onKeyDown: (e) => {
        if (p.field.type === "number") {
          const isBackspace = e.key === "Backspace";
          if (Number.isNaN(parseInt(e.key)) && !isBackspace) {
            e.preventDefault();
          }
        }
      }
    })
  );
}
__name(Input, "Input");

// src/app/domains/modes/mode-8/components/field-set/FieldSet.tsx
import { Fragment as Fragment2, jsx as jsx37, jsxs as jsxs15 } from "react/jsx-runtime";
function FieldSet(p) {
  return /* @__PURE__ */ jsx37("fieldset", { style: p.inputColumnStyle, children: p.collection.group ? /* @__PURE__ */ jsx37(
    Group,
    {
      collection: p.collection,
      onChange: p.onChange,
      inputColumnStyle: p.inputColumnStyle
    }
  ) : /* @__PURE__ */ jsx37(
    Input2,
    {
      collection: p.collection,
      onChange: p.onChange,
      inputColumnStyle: p.inputColumnStyle
    }
  ) });
}
__name(FieldSet, "FieldSet");
function Group(p) {
  var _a;
  const [groupError, setGroupError] = useState7(null);
  return /* @__PURE__ */ jsxs15(Fragment2, { children: [
    p.collection.group && /* @__PURE__ */ jsx37(
      GroupLabelWithError,
      {
        groupName: (_a = p.collection.group) == null ? void 0 : _a.name,
        groupError
      }
    ),
    /* @__PURE__ */ jsx37(
      Input2,
      {
        collection: p.collection,
        onChange: p.onChange,
        inputColumnStyle: p.inputColumnStyle,
        handleValidity: (p2) => {
          handleGroupValidity(p2.valid);
          return null;
        }
      },
      p.collection.id
    )
  ] });
  function handleGroupValidity(valid) {
    if (valid) {
      setGroupError(null);
    } else {
      setGroupError(isGroupError(p.collection.group));
    }
  }
  __name(handleGroupValidity, "handleGroupValidity");
}
__name(Group, "Group");
function Input2(p) {
  return /* @__PURE__ */ jsx37(Fragment2, { children: p.collection.inputs.map((input) => /* @__PURE__ */ jsx37(
    InputWithLabel,
    {
      labelStyle: getLabelStyle({ type: input.field.type }),
      inputStyle: getInputStyle({ type: input.field.type }),
      group: p.collection.group != null,
      input,
      onChange: p.onChange,
      handleValidity: p.handleValidity ? p.handleValidity : handleValidity
    },
    input.field.id
  )) });
  function handleValidity(pr) {
    if (pr.valid) {
      return null;
    } else {
      return pr.error;
    }
  }
  __name(handleValidity, "handleValidity");
}
__name(Input2, "Input");

// src/lib/other/clone-deep.ts
import cloneDeep from "lodash.clonedeep";
function cloneDeeper(obj) {
  const cloned = cloneDeep(obj);
  if (cloned == null) {
    throw new Error("Cloned obj cannot be null");
  }
  return cloned;
}
__name(cloneDeeper, "cloneDeeper");

// src/app/domains/modes/mode-8/components/form/form-reducer.tsx
function formReducer(state, action) {
  switch (action.type) {
    case "reset" /* reset */: {
      return {};
    }
    case "change" /* change */: {
      const element = getInputElement(action.payload);
      return __spreadProps(__spreadValues({}, state), {
        [element.target.name]: { value: element.target.value }
      });
    }
  }
}
__name(formReducer, "formReducer");
function getInputElement(element) {
  if (isInputEvent(element)) {
    return element;
  }
  throw new Error("Could not verify element");
}
__name(getInputElement, "getInputElement");
function isInputEvent(element) {
  if (element && element.target) {
    if (typeof element.target.id === "string") {
      return true;
    }
  }
  throw new Error("Element should have an id");
}
__name(isInputEvent, "isInputEvent");

// src/app/domains/modes/mode-8/components/form/Form.tsx
import { jsx as jsx38, jsxs as jsxs16 } from "react/jsx-runtime";
function MyForm8(p) {
  return /* @__PURE__ */ jsx38("div", { style: { display: "flex" }, children: /* @__PURE__ */ jsxs16("div", { children: [
    /* @__PURE__ */ jsx38("label", { children: p.name }),
    /* @__PURE__ */ jsxs16(
      "form",
      {
        onReset: handleReset,
        onSubmit: handleSubmit,
        onInvalid: handleInvalid,
        style: {
          color: "black",
          width: "300px"
        },
        children: [
          p.inputCollections.map((f, i) => /* @__PURE__ */ jsx38(FieldSet, { onChange: handleChange, collection: f }, i)),
          /* @__PURE__ */ jsxs16("div", { children: [
            /* @__PURE__ */ jsx38("button", { type: "submit", children: "Submit" }),
            /* @__PURE__ */ jsx38("button", { type: "reset", children: "Reset" })
          ] })
        ]
      }
    )
  ] }) });
  function handleChange(event) {
    p.setFormData({ type: "change" /* change */, payload: event });
  }
  __name(handleChange, "handleChange");
  function handleSubmit(event) {
    event.preventDefault();
    p.setSubmitted(handleEvent(p.inputCollections, event));
  }
  __name(handleSubmit, "handleSubmit");
  function handleReset(event) {
    p.setFormData({ type: "reset" /* reset */, payload: event });
  }
  __name(handleReset, "handleReset");
  function handleInvalid(event) {
  }
  __name(handleInvalid, "handleInvalid");
}
__name(MyForm8, "MyForm8");
function handleEvent(inputCollections2, event) {
  return inputCollections2.map(
    (inputCollection) => inputCollection.inputs.map((input) => {
      const elementFound = Object.values(event.currentTarget.elements).find(
        (element) => element.id === input.field.id
      );
      if (elementFound && elementFound.id === input.field.id) {
        return cloneDeeper(__spreadProps(__spreadValues({}, input), {
          submitted: __spreadValues({}, elementFound)
          // must spread, clone deep wont work othervise
        }));
      }
      return null;
    }).filter((e) => e != null)
  ).filter((e) => e != null);
}
__name(handleEvent, "handleEvent");

// src/components/modal/modal.tsx
import { jsx as jsx39, jsxs as jsxs17 } from "react/jsx-runtime";
var Modal = /* @__PURE__ */ __name((p) => {
  const showStyle = p.show ? __spreadValues(__spreadValues({}, modal), displayBlock) : __spreadValues(__spreadValues({}, modal), displayNone);
  return /* @__PURE__ */ jsx39("div", { style: showStyle, children: /* @__PURE__ */ jsxs17("section", { style: __spreadValues({}, modalMain), children: [
    p.children,
    /* @__PURE__ */ jsx39("button", { type: "button", onClick: p.handleClose, children: "Close" })
  ] }) });
}, "Modal");
var modalMain = {
  position: "fixed",
  background: "white",
  width: "80%",
  height: "50%",
  top: "50%",
  left: "50%",
  transform: "translate(-50%,-50%)"
};
var modal = {
  position: "fixed",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  background: "rgba(0, 0, 0, 0.6)"
};
var displayBlock = {
  display: "block"
};
var displayNone = {
  display: "none"
};

// src/components/switch/switch.tsx
import { useState as useState8 } from "react";
import { jsx as jsx40 } from "react/jsx-runtime";
var Switch = /* @__PURE__ */ __name((p) => {
  const [state, setState] = useState8(p.enabled ? 1 : 0);
  return /* @__PURE__ */ jsx40("div", { children: /* @__PURE__ */ jsx40(
    "input",
    {
      className: `switch ${p.enabled ? "on" : "off"}`,
      style: {
        width: "3rem",
        background: "none"
      },
      step: 0.1,
      min: 0,
      max: 1,
      value: p.enabled ? 1 : 0,
      onChange: () => null,
      onClick: p.onChange,
      type: "range",
      children: p.children
    }
  ) });
}, "Switch");

// src/app/domains/modes/mode-8/fields.ts
import moment12 from "moment";
var oneYearAgo = moment12(Date.now()).subtract(1, "year").valueOf();
var fieldDate = {
  field: {
    id: "date",
    name: "date",
    type: "date",
    required: true,
    defaultValue: moment12(oneYearAgo).format("YYYY-MM-DD")
  },
  label: {
    text: "date"
  },
  error: {
    onChange: "onchange",
    onValidity: "validity"
  }
};
var fieldFirstName = {
  field: {
    id: "firstName",
    name: "firstName",
    type: "text",
    required: true
  },
  label: {
    text: "First name"
  },
  error: {
    onChange: "onchange",
    onValidity: "validity"
  }
};
var fieldTheNumber = {
  field: {
    id: "number",
    name: "number",
    type: "number",
    required: true
    // pattern: '[0-9]*',
  },
  label: {
    text: "Number"
  },
  error: {
    onChange: "onchange",
    onValidity: "validity"
  }
};
var fieldSlide = {
  field: {
    id: "slide",
    name: "slide",
    type: "range",
    min: 0,
    max: 10,
    step: 0.1,
    defaultValue: 5,
    required: true
  },
  label: {
    text: "Slider"
  },
  error: {
    onChange: "onchange",
    onValidity: "validity"
  }
};
var radio1 = {
  field: {
    id: "radio-1",
    name: "radio-option",
    type: "radio",
    required: true
    // defaultValue: 'radio-val-1',
  },
  label: {
    text: "label-1"
  },
  error: {
    onChange: "onchange",
    onValidity: "validity"
  }
};
var radio2 = {
  field: {
    id: "radio-2",
    name: "radio-option",
    type: "radio",
    required: true
    // defaultValue: 'radio-val-2',
  },
  label: {
    text: "label-2"
  },
  error: {
    onChange: "onchange",
    onValidity: "validity"
  }
};
var radio3 = {
  field: {
    id: "radio-3",
    name: "radio-option",
    type: "radio",
    required: true
    // defaultValue: 'radio-val-3',
  },
  label: {
    text: "label-3"
  },
  error: {
    onChange: "onchange",
    onValidity: "validity"
  }
};
var fieldsA = {
  id: "fields-a",
  radio: false,
  inputs: [fieldDate, fieldSlide, fieldFirstName, fieldTheNumber]
};
var radiosA = {
  id: "radios-11",
  radio: true,
  group: {
    name: "Group Radio",
    error: {
      onValidity: "Select one option"
    }
  },
  inputs: [radio1, radio2, radio3]
};

// src/app/domains/modes/mode-8/components/FormState.tsx
import { Fragment as Fragment3, jsx as jsx41, jsxs as jsxs18 } from "react/jsx-runtime";
function FormState(p) {
  const sty = useStyle();
  return /* @__PURE__ */ jsxs18(Fragment3, { children: [
    /* @__PURE__ */ jsx41("label", { children: FormState.idName }),
    /* @__PURE__ */ jsx41("fieldset", { style: { width: 250 }, children: /* @__PURE__ */ jsx41("pre", { style: __spreadValues({}, sty.style), children: p.formData && JSON.stringify(p.formData, null, 2) }) })
  ] });
}
__name(FormState, "FormState");
FormState.idName = "FormState";

// src/app/domains/modes/mode-8/components/SubmittedData.tsx
import { Fragment as Fragment4, jsx as jsx42, jsxs as jsxs19 } from "react/jsx-runtime";
function SubmittedData(p) {
  const lengths = p.inputCollection.map(
    (e) => e.map((e2) => e2.field.name.length)
  );
  const sty = useStyle();
  return /* @__PURE__ */ jsxs19(Fragment4, { children: [
    /* @__PURE__ */ jsx42("label", { children: SubmittedData.idName }),
    /* @__PURE__ */ jsx42("fieldset", { style: { width: 300 }, children: /* @__PURE__ */ jsx42("pre", { style: __spreadValues({}, sty.style), children: p.inputCollection.map((inputs) => {
      return inputs.map((input) => {
        var _a, _b;
        return /* @__PURE__ */ jsxs19(
          "div",
          {
            style: {
              display: "flex"
            },
            children: [
              /* @__PURE__ */ jsx42(
                "div",
                {
                  style: {
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center"
                  },
                  children: input.field.type === "radio" ? ((_a = input.submitted) == null ? void 0 : _a.checked) ? /* @__PURE__ */ jsxs19(Fragment4, { children: [
                    /* @__PURE__ */ jsx42(
                      Label,
                      {
                        name: input.field.name,
                        type: guardType(input.field.type),
                        length: getMaxFromNested({ lengths })
                      }
                    ),
                    /* @__PURE__ */ jsx42("span", { children: input.field.id })
                  ] }) : null : /* @__PURE__ */ jsxs19(Fragment4, { children: [
                    /* @__PURE__ */ jsx42(
                      Label,
                      {
                        name: input.field.name,
                        type: guardType(input.field.type),
                        length: getMaxFromNested({ lengths })
                      }
                    ),
                    /* @__PURE__ */ jsx42("span", { children: (_b = input.submitted) == null ? void 0 : _b.value })
                  ] })
                }
              ),
              /* @__PURE__ */ jsx42("br", {})
            ]
          },
          input.field.id
        );
      });
    }) }) })
  ] });
}
__name(SubmittedData, "SubmittedData");
SubmittedData.idName = "submitted-data";
function guardType(type) {
  if (typeof type === "string") {
    return type;
  }
  throw new Error("Type should be string");
}
__name(guardType, "guardType");
function getRemainingLength(p) {
  return Array.from(Array(p.maxLength - p.name.length).keys());
}
__name(getRemainingLength, "getRemainingLength");
function padRemaining(p) {
  return p.lengths.map((_) => ".").join("");
}
__name(padRemaining, "padRemaining");
function Label(p) {
  const remainig = getRemainingLength({
    maxLength: p.length,
    name: p.name
  });
  const pads = padRemaining({
    lengths: remainig
  });
  return /* @__PURE__ */ jsxs19(
    "label",
    {
      style: __spreadProps(__spreadValues({}, getLabelStyle({
        type: p.type
      })), {
        display: "flex"
      }),
      children: [
        p.name + pads,
        ": ",
        p.children
      ]
    }
  );
}
__name(Label, "Label");
function flattenNested(items) {
  return [...items.reduce((flat, val) => flat.concat(val), [])];
}
__name(flattenNested, "flattenNested");
function getMaxFromNested(p) {
  return Math.max(...flattenNested(p.lengths));
}
__name(getMaxFromNested, "getMaxFromNested");

// src/app/domains/modes/mode-8/Mode8.tsx
import { jsx as jsx43, jsxs as jsxs20 } from "react/jsx-runtime";
var inputCollections = [fieldsA, radiosA];
var name = "Form";
function Mode8() {
  useStart(fonts6.font2);
  const [formData, setFormData] = useReducer(formReducer, {});
  const [submitted, setSubmitted] = useState9([]);
  return /* @__PURE__ */ jsxs20("div", { style: { padding: 10 }, children: [
    /* @__PURE__ */ jsx43(MyComponent, {}),
    /* @__PURE__ */ jsxs20("div", { style: { padding: 10, display: "flex" }, children: [
      /* @__PURE__ */ jsx43(
        MyForm8,
        {
          name,
          inputCollections,
          setFormData,
          setSubmitted
        }
      ),
      /* @__PURE__ */ jsx43("div", { children: /* @__PURE__ */ jsx43(FormState, { formData }) }),
      /* @__PURE__ */ jsx43("div", { children: /* @__PURE__ */ jsx43(SubmittedData, { inputCollection: submitted || [] }) })
    ] })
  ] });
}
__name(Mode8, "Mode8");
function MyComponent() {
  const [show, setShow] = useState9(false);
  const [switchState, setSwitchState] = useState9(false);
  return /* @__PURE__ */ jsxs20("div", { children: [
    /* @__PURE__ */ jsx43("button", { onClick: () => setShow(!show), children: "modal" }),
    /* @__PURE__ */ jsx43(Modal, { show, handleClose: () => setShow(false), children: /* @__PURE__ */ jsx43("div", { children: "hejfdsagfsagfsdagfsa" }) }),
    /* @__PURE__ */ jsx43(
      Switch,
      {
        enabled: switchState,
        onChange: () => setSwitchState(!switchState)
      }
    )
  ] });
}
__name(MyComponent, "MyComponent");

// src/fonts/import-fonts.ts
var fonts7 = {
  font1: "myfont1",
  font2: "myfont2",
  font3: "myfont3",
  font4: "myfont4",
  font5: "myfont5",
  font6: "myfont6",
  font7: "sf1"
};

// src/app/AppProvider.tsx
import { Provider } from "react-redux";

// src/redux/store.ts
import {
  combineReducers,
  configureStore
} from "@reduxjs/toolkit";
import { reducer as themeReducer } from "@mono/styles";

// src/redux/state/other/slice.ts
import { createSlice } from "@reduxjs/toolkit";

// src/redux/state/other/name.ts
var name2 = "other";

// src/redux/state/other/state.ts
function getState() {
  return {
    value: null,
    status: "initial" /* initial */
  };
}
__name(getState, "getState");

// src/redux/state/other/slice.ts
var slice = createSlice({
  name: name2,
  initialState: getState(),
  reducers: {
    setBrightness: (state, action) => {
      const value = action.payload.value;
      state.value = value;
    }
  }
});
var { setBrightness } = slice.actions;
var { reducer } = slice;

// src/redux/store.ts
var rootReducer = combineReducers({
  theme: themeReducer,
  other: reducer
});
var store = configureStore({
  reducer: rootReducer
});

// src/app/AppProvider.tsx
import { ThemeProviderWrapper } from "@mono/styles";

// package.json
var package_default = {
  name: "@mono/display-clock",
  publicName: "0011000-clock",
  private: true,
  version: "0.0.0",
  main: "./dist/lib/index.js",
  module: "./dist/lib/index.mjs",
  types: "./dist/lib/index.d.ts",
  files: [
    "dist/lib/**"
  ],
  scripts: {
    dev: "vite",
    test: "VITE_WS_PORT=103 vitest --run",
    build: "pnpm run build:app && pnpm run build:lib",
    "build:app": "tsc && vite build",
    "build:lib": "pnpm run clean:build && tsup",
    "clean:build": "rm -rf dist/lib",
    lint: "eslint . --ext ts,tsx --report-unused-disable-directives --max-warnings 0",
    preview: "vite preview"
  },
  dependencies: {
    "@mono/aaa": "workspace:*",
    "@mono/fonts": "workspace:*",
    "@mono/styles": "workspace:*",
    "@reduxjs/toolkit": "2.2.3",
    "@storybook/react": "8.0.8",
    "@storybook/test": "8.0.8",
    "@testing-library/jest-dom": "6.4.2",
    "@types/lodash.clonedeep": "4.5.9",
    csstype: "3.1.3",
    "date-fns": "3.6.0",
    "@svgr/plugin-jsx": "8.1.0",
    "esbuild-plugin-svgr": "2.1.0",
    "lodash.clonedeep": "4.5.0",
    moment: "2.30.1",
    react: "^18.2.0",
    "react-dom": "^18.2.0",
    "react-inlinesvg": "4.1.3",
    "react-redux": "9.1.1",
    "react-router-dom": "6.22.3",
    "styled-components": "6.1.8",
    "vite-plugin-checker": "0.6.4",
    "vite-plugin-svgr": "4.2.0",
    vitest: "1.3.1"
  },
  devDependencies: {
    "@mono/components": "workspace:*",
    "@mono/eslint-config": "workspace:*",
    "@mono/tsconfig": "workspace:*",
    "@types/react": "^18.2.66",
    "@types/react-dom": "^18.2.22",
    "@typescript-eslint/eslint-plugin": "^7.2.0",
    "@typescript-eslint/parser": "^7.2.0",
    "@vitejs/plugin-react": "^4.2.1",
    eslint: "^8.57.0",
    "eslint-plugin-react-hooks": "^4.6.0",
    "eslint-plugin-react-refresh": "^0.4.6",
    tsup: "8.0.2",
    typescript: "^5.2.2",
    vite: "^5.2.0"
  }
};

// src/constants/constants.ts
var APP_NAME = package_default.publicName;

// src/app/AppProvider.tsx
import { jsx as jsx44 } from "react/jsx-runtime";
var StoreProvider = /* @__PURE__ */ __name((props) => {
  const { children } = props;
  return /* @__PURE__ */ jsx44(Provider, { store, children });
}, "StoreProvider");
function AppProvider(p) {
  return /* @__PURE__ */ jsx44(StoreProvider, { children: /* @__PURE__ */ jsx44(ThemeProviderWrapper, { appName: APP_NAME, children: /* @__PURE__ */ jsx44(StyleProvider, { children: p.children }) }) });
}
__name(AppProvider, "AppProvider");

// src/app/domains/modes/mode-4/Mode4.tsx
import { useEffect as useEffect5, useState as useState10 } from "react";

// src/components/BrightnessButtons.tsx
import { Brightness, ThemeEnum, setBrightness as setBrightness2 } from "@mono/styles";

// ../../packages/components/src/buttons/base/Base.tsx
import { jsx as jsx45 } from "react/jsx-runtime";
var BaseButton = /* @__PURE__ */ __name((props) => {
  const _a = props, { children } = _a, buttonProps = __objRest(_a, ["children"]);
  return /* @__PURE__ */ jsx45("button", __spreadProps(__spreadValues({}, buttonProps), { style: { backgroundColor: "blue" }, children }));
}, "BaseButton");

// ../../packages/components/src/buttons/neutral/Neutral.tsx
import { jsx as jsx46 } from "react/jsx-runtime";
var NeutralButton = /* @__PURE__ */ __name((props) => {
  const _a = props, { children } = _a, buttonProps = __objRest(_a, ["children"]);
  return /* @__PURE__ */ jsx46(
    BaseButton,
    __spreadProps(__spreadValues({}, buttonProps), {
      style: {
        backgroundColor: "red"
      },
      children
    })
  );
}, "NeutralButton");

// src/redux/hooks.ts
import { useDispatch, useSelector } from "react-redux";
var useAppDispatch = useDispatch;

// src/components/BrightnessButtons.tsx
import { Fragment as Fragment5, jsx as jsx47, jsxs as jsxs21 } from "react/jsx-runtime";
var BrightnessButtons = /* @__PURE__ */ __name(() => {
  const dispatch = useAppDispatch();
  return /* @__PURE__ */ jsxs21(Fragment5, { children: [
    /* @__PURE__ */ jsx47(
      NeutralButton,
      {
        onClick: () => dispatch(
          setBrightness2({
            appName: APP_NAME,
            brightness: Brightness.bright,
            theme: ThemeEnum.red
          })
        ),
        children: "Bright"
      }
    ),
    /* @__PURE__ */ jsx47(
      NeutralButton,
      {
        onClick: () => dispatch(
          setBrightness2({
            appName: APP_NAME,
            brightness: Brightness.dark,
            theme: ThemeEnum.red
          })
        ),
        children: "Dark"
      }
    )
  ] });
}, "BrightnessButtons");

// src/components/base/Preview.tsx
import styled3, { css as css2, useTheme as useTheme5 } from "styled-components";
import moment13 from "moment";
import { getDate as getDate2, getWeek, isToday } from "date-fns";
import { sv } from "date-fns/locale";
import { getFontWithSize } from "@mono/fonts";
import "@mono/fonts/dist/lib/index.css";
import { jsx as jsx48, jsxs as jsxs22 } from "react/jsx-runtime";
var GridTop = css2`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: normal;
  align-content: stretch;
`;
var GridSingle = css2`
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  gap: 0px 0px;
  grid-template-areas: '.';
`;
var GridDays = css2`
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr;
  gap: 0px 0px;
  grid-template-areas:
    '. . . . . . .'
    '. . . . . . .'
    '. . . . . . .'
    '. . . . . . .'
    '. . . . . . .'
    '. . . . . . .';
`;
var GridWeekDays = css2`
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr;
  gap: 0px 0px;
  grid-template-areas:
    '.'
    '.'
    '.'
    '.'
    '.'
    '.';
`;
var GridWeekNames = css2`
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 0px 0px;
  grid-template-areas: '. . . . . . . .';
  /* background-color: aliceblue; */
`;
var CenterCell = css2`
  display: grid;
  place-items: center; /* Centers in both dimensions */
`;
var StyledGridTop = styled3.div`
  ${GridTop}
`;
var StyledGridSingle = styled3.div`
  ${GridSingle}
`;
var StyledGridWeekDays = styled3.div`
  ${GridWeekDays}
`;
var StyledGridWeekNames = styled3.div`
  ${GridWeekNames}
`;
var StyledGridDays = styled3.div`
  ${GridDays}
`;
var StyledFullSize = styled3.div`
  width: ${({ $size: v }) => v};
  height: ${({ $size: v }) => v};
`;
var StyledBackground = styled3.div`
  /* padding: 1rem; */
  border-radius: calc(100% * 0.1);
  padding: calc(100% * 0.04);
  margin: calc(100% * 0.01);

  /* width: 100%;
  height: 100%; */
  background-color: ${({ $backgroundColor: bg }) => bg};
`;
var StyledCell = styled3.div`
  ${CenterCell}

  color: ${({ $color: $p }) => $p};
  padding: ${({ $size: v }) => `calc(${v} * 0.02)`};
  border-radius: ${({ $size: v }) => `calc(${v} * 0.02)`};
  border-radius: ${({ $size, $isActive }) => $isActive ? `100%` : `calc(${$size} * 0.02)`};
  /* background-color: red; */
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: ${({ $size, $isActive }) => $isActive ? `calc(${$size} * 0.1)` : `calc(${$size} * 0.12)`};
  height: ${({ $size, $isActive }) => $isActive ? `calc(${$size} * 0.1)` : `calc(${$size} * 0.12)`};
  font-size: ${({ $fsize: v, $size: s }) => `calc(${v || s} * 0.1)`};

  background-color: ${({ $backgroundColor: $p }) => $p || "unset"};
`;
var _days = rangeFromCount({ count: 42, start: 1 });
var days = _days.map((day, index) => {
  return {
    value: day,
    active: index === 27,
    // weekend: index % 6 === 5 || index % 6 === 0,
    weekend: index % 7 === 5 || index % 7 === 6,
    isOtherMonth: index < 4
  };
});
var weekNumbers = Array.from({ length: 6 }, (_, i) => {
  return { number: i + 1, active: i + 1 === 4 };
});
function getTodayWeekday(locale2 = "en-US") {
  const today = /* @__PURE__ */ new Date();
  return today.toLocaleDateString(locale2, { weekday: "short" });
}
__name(getTodayWeekday, "getTodayWeekday");
function getWeekdayNames(locale2 = "en-US") {
  const baseDate = new Date(Date.UTC(2018, 0, 1));
  const weekdays = [];
  for (let i = 0; i < 7; i++) {
    const nextDay = new Date(baseDate);
    nextDay.setDate(baseDate.getDate() + i);
    weekdays.push(nextDay.toLocaleDateString(locale2, { weekday: "short" }));
  }
  return weekdays;
}
__name(getWeekdayNames, "getWeekdayNames");
var _weekdayNames = getWeekdayNames("sv-SE");
_weekdayNames.unshift("");
var todayWeekdayName = getTodayWeekday("sv-SE");
var weekdayNames = _weekdayNames.map((x, index) => {
  return {
    name: x,
    active: x === todayWeekdayName
  };
});
function isWeekend(date) {
  const dayOfWeek = new Date(date).getDay();
  return dayOfWeek === 0 || dayOfWeek === 6;
}
__name(isWeekend, "isWeekend");
function BackgroundComponent_Preview(props) {
  const { date } = props;
  const theme = useTheme5();
  const rootSize = "28vw";
  const yy = useDateGeneration({ date });
  const total = yy.days;
  const weeks = yy.weeks;
  const clockFrameSize = `calc(${rootSize} * 1.278)`;
  const clockFaceSize = `calc(${rootSize} * 1.08)`;
  const aaa = getFontWithSize(rootSize);
  const smallerTextSize = `calc(${rootSize} * 0.5)`;
  const scale = 0.6;
  const fob = aaa.g;
  const offsetSize = fob.offset;
  return /* @__PURE__ */ jsx48(
    "div",
    {
      style: {
        fontFamily: fob.font,
        fontSize: fob.size
      },
      children: /* @__PURE__ */ jsxs22(Row, { children: [
        /* @__PURE__ */ jsx48(StyledBackground, { $backgroundColor: theme.colors.background.gr1, children: /* @__PURE__ */ jsxs22(Column, { children: [
          /* @__PURE__ */ jsx48(CalendarTitle, { size: rootSize, date }),
          /* @__PURE__ */ jsx48(Row, { children: /* @__PURE__ */ jsx48(WeekNames, { size: rootSize, scale }) }),
          /* @__PURE__ */ jsxs22(Row, { children: [
            /* @__PURE__ */ jsx48(WeekNumbers, { size: rootSize, scale, weeks }),
            /* @__PURE__ */ jsx48(Days, { size: rootSize, days: total })
          ] })
        ] }) }),
        /* @__PURE__ */ jsx48(
          StyledBackground,
          {
            $backgroundColor: theme.colors.background.gr1,
            style: {
              // fontFamily: fonts.font7,
            },
            children: /* @__PURE__ */ jsx48(
              "div",
              {
                style: {
                  width: clockFrameSize,
                  height: clockFrameSize
                },
                children: /* @__PURE__ */ jsx48(Center, { children: /* @__PURE__ */ jsx48(
                  ClockTickerBig,
                  {
                    date,
                    size: clockFaceSize,
                    offset: offsetSize
                  }
                ) })
              }
            )
          }
        )
      ] })
    }
  );
}
__name(BackgroundComponent_Preview, "BackgroundComponent_Preview");
var WeekNumbers = /* @__PURE__ */ __name((props) => {
  const { size, weeks, scale } = props;
  const theme = useTheme5();
  return /* @__PURE__ */ jsx48(StyledGridWeekDays, { children: weeks.map((week, index) => {
    const color = week.active ? theme.colors.corporate.primary.color : theme.colors.background.gr4;
    return /* @__PURE__ */ jsx48(
      StyledCell,
      {
        $size: size,
        $fsize: `calc(${size} * ${scale})`,
        $color: color,
        children: week.wNumber
      },
      index
    );
  }) });
}, "WeekNumbers");
function useDateGeneration(params) {
  const { date } = params;
  const previousCells = getPreviousCells({
    date
  });
  const dayInMonthCells = getThisMonthDays({
    currentDay: date
  });
  const nextCells = getNextMonthDays({
    total: previousCells.length + dayInMonthCells.length,
    date
  });
  const _days2 = [...previousCells, ...dayInMonthCells, ...nextCells];
  const days2 = _days2.map((day) => {
    return __spreadProps(__spreadValues({}, day), { weekend: isWeekend(day.date) });
  });
  const weeks = days2.reduce(
    (accumulator, currentValue) => {
      const currDate = currentValue.date;
      const weekNumber = getWeek(currDate, { locale: sv });
      const isCurrentDateToday = isToday(new Date(currDate));
      if (accumulator.length >= 6) {
        return accumulator;
      }
      const existingWeekIndex = accumulator.findIndex(
        (item) => item.wNumber === weekNumber
      );
      if (existingWeekIndex === -1) {
        accumulator.push({
          wNumber: weekNumber,
          active: isCurrentDateToday
        });
      } else if (isCurrentDateToday) {
        accumulator[existingWeekIndex].active = true;
      }
      return accumulator;
    },
    []
  );
  return { days: days2, weeks };
}
__name(useDateGeneration, "useDateGeneration");
var Days = /* @__PURE__ */ __name((props) => {
  const { size, days: days2 } = props;
  const theme = useTheme5();
  return /* @__PURE__ */ jsx48(StyledGridDays, { children: days2.map((day, index) => {
    const defaultColor = theme.colors.text.text;
    const activeColor = theme.colors.text.inverted;
    const weekendColor = theme.colors.background.gr4;
    const otherColor = theme.colors.background.gr2;
    const isActive = day.today;
    const isWeekend2 = day.weekend;
    const isOther = !!(day.nextMonth || day.previousMonth);
    const dayColor = isWeekend2 ? weekendColor : defaultColor;
    const oColor = isOther ? otherColor : dayColor;
    const color = isActive ? activeColor : oColor;
    const backgroundColor = isActive ? theme.colors.corporate.primary.color : "unset";
    return /* @__PURE__ */ jsx48(
      StyledCell,
      {
        $size: size,
        $color: color,
        $backgroundColor: backgroundColor,
        $isActive: isActive,
        children: getDate2(day.date)
      },
      index
    );
  }) });
}, "Days");
var WeekNames = /* @__PURE__ */ __name((props) => {
  const { size, scale } = props;
  const theme = useTheme5();
  return /* @__PURE__ */ jsx48(StyledGridWeekNames, { children: weekdayNames.map((day, index) => {
    const color = day.active ? theme.colors.corporate.primary.color : theme.colors.background.gr4;
    return /* @__PURE__ */ jsx48(
      StyledCell,
      {
        $size: size,
        $fsize: `calc(${size} * ${scale})`,
        $color: color,
        children: weekdayNames[index].name.slice(0, 1).toUpperCase()
      },
      index
    );
  }) });
}, "WeekNames");
var CalendarTitle = /* @__PURE__ */ __name((props) => {
  const { size, date } = props;
  const theme = useTheme5();
  const month = `${moment13(date).format("MMMM")}`;
  const year = `${moment13(date).format("YYYY")}`;
  const titles = [
    {
      value: "",
      justify: "flex-start",
      active: true
    },
    {
      value: month,
      justify: "flex-start",
      active: true
    },
    {
      value: "",
      justify: "center",
      active: false
    },
    {
      value: "",
      justify: "center",
      active: false
    },
    {
      value: "",
      justify: "center",
      active: false
    },
    {
      value: "",
      justify: "center",
      active: false
    },
    {
      value: "",
      justify: "center",
      active: false
    },
    {
      value: "",
      justify: "flex-end",
      active: false
    }
  ];
  return /* @__PURE__ */ jsx48(StyledGridTop, { children: titles.map((title, index) => {
    const color = title.active ? theme.colors.corporate.primary.color : theme.colors.text.text;
    return /* @__PURE__ */ jsx48(
      StyledCell,
      {
        $color: color,
        $size: size,
        style: {
          justifyContent: title.justify,
          margin: "unset"
        },
        children: title.value
      },
      index
    );
  }) });
}, "CalendarTitle");

// src/app/domains/modes/mode-4/Mode4.tsx
import { jsx as jsx49, jsxs as jsxs23 } from "react/jsx-runtime";
var HoverComponent = /* @__PURE__ */ __name((props) => {
  const { children, hideDelay } = props;
  const [isHovering, setIsHovering] = useState10(true);
  useEffect5(() => {
    let timeoutId;
    if (hideDelay) {
      timeoutId = setTimeout(() => {
        setIsHovering(false);
      }, hideDelay);
    }
    return () => clearTimeout(timeoutId);
  }, [hideDelay]);
  return /* @__PURE__ */ jsx49(
    "div",
    {
      onMouseEnter: () => setIsHovering(true),
      onMouseLeave: () => setIsHovering(false),
      style: {
        position: "relative",
        width: "30px",
        height: "30px"
      },
      children: isHovering && /* @__PURE__ */ jsx49(
        "div",
        {
          style: {
            // position: 'absolute',
            // width: '100%',
            // height: '100%',
            top: "0",
            left: "0",
            backgroundColor: "rgba(0,0,0,0.5)",
            color: "white",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          },
          children
        }
      )
    }
  );
}, "HoverComponent");
function Mode4() {
  const date = useDateRef({
    date: (/* @__PURE__ */ new Date()).valueOf(),
    //mockDate: new Date('2021-12-04T01:45:00').valueOf(),
    interval: 50
  }).date;
  const randomNumberX = useRandomNumberAfterDelay({
    minNum: -260,
    maxNum: 260,
    delay: 36e4
  });
  const randomNumberY = useRandomNumberAfterDelay({
    minNum: -200,
    maxNum: 200,
    delay: 36e4
  });
  const divStyle = {
    position: "absolute",
    left: `${randomNumberX}px`,
    top: `${randomNumberY}px`
    // backgroundColor: 'red',
  };
  return (
    // <div style={divStyle}>
    /* @__PURE__ */ jsxs23(Column, { children: [
      /* @__PURE__ */ jsx49("div", { style: { backgroundColor: "red" } }),
      /* @__PURE__ */ jsx49(HoverComponent, { hideDelay: 3e3, children: /* @__PURE__ */ jsx49(BrightnessButtons, {}) }),
      /* @__PURE__ */ jsx49(Center, { children: /* @__PURE__ */ jsx49(
        "div",
        {
          style: {
            display: "grid",
            placeItems: "center",
            height: "80vh"
          },
          children: /* @__PURE__ */ jsx49(BackgroundComponent_Preview, { date })
        }
      ) })
    ] })
  );
}
__name(Mode4, "Mode4");
function useRandomNumberAfterDelay(params) {
  const { maxNum, minNum, delay } = params;
  const [randomNumber, setRandomNumber] = useState10(0);
  useEffect5(() => {
    const timerId = setInterval(() => {
      const newRandomNumber = Math.floor(Math.random() * (maxNum - minNum + 1)) + minNum;
      setRandomNumber(newRandomNumber);
    }, delay);
    return () => clearInterval(timerId);
  }, [minNum, maxNum, delay]);
  return randomNumber;
}
__name(useRandomNumberAfterDelay, "useRandomNumberAfterDelay");
export {
  AppProvider,
  Mode1,
  Mode2,
  Mode3,
  Mode4,
  Mode5,
  Mode6,
  Mode8,
  fonts7 as fonts
};
