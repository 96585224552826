'use client'
var __defProp = Object.defineProperty;
var __name = (target, value) => __defProp(target, "name", { value, configurable: true });

// src/index.ts
var fonts = {
  font1: "myfont1",
  font2: "myfont2",
  font3: "myfont3",
  font4: "myfont4",
  font5: "myfont5",
  font6: "myfont6",
  font7: "sf1"
};
var scale = 1.2;
var getFontWithSize = /* @__PURE__ */ __name((size) => {
  return {
    a: {
      font: fonts.font1,
      size: `calc(${size} * 0.08)`,
      offset: scale * 0.6
    },
    b: {
      font: fonts.font2,
      size: `calc(${size} * 0.1)`,
      offset: scale * 0.8
    },
    c: {
      font: fonts.font3,
      size: `calc(${size} * 0.1)`,
      offset: scale * 0.7
    },
    d: {
      font: fonts.font4,
      size: `calc(${size} * 0.06)`,
      offset: scale * 0.5
    },
    e: {
      font: fonts.font5,
      size: `calc(${size} * 0.1)`,
      offset: scale * 0.85
    },
    f: {
      font: fonts.font6,
      size: `calc(${size} * 0.1)`,
      offset: scale * 0.8
    },
    g: {
      font: fonts.font7,
      size: `calc(${size} * 0.1)`,
      offset: scale * 0.8
    }
  };
}, "getFontWithSize");
export {
  fonts,
  getFontWithSize
};
