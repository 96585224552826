'use client'
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __name = (target, value) => __defProp(target, "name", { value, configurable: true });
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};

// src/app/domains/modes/mode-8/Mode8.tsx
import { useEffect, useReducer, useState as useState3 } from "react";
import { useDebounce } from "@mono/utils";
import { fonts } from "@mono/fonts";
import "@mono/fonts/dist/lib/index.css";

// src/app/domains/modes/mode-8/types.ts
var isGroupCollection = /* @__PURE__ */ __name((fieldSet) => {
  return fieldSet.group != null;
}, "isGroupCollection");

// src/components/form/form-reducer.tsx
function formReducer(state, action) {
  const handlers = {
    ["reset" /* reset */]: () => {
      return {};
    },
    ["change" /* change */]: () => {
      const element = getInputElement(action.payload);
      return __spreadProps(__spreadValues({}, state), {
        [element.target.name]: { value: element.target.value }
      });
    }
  };
  return handlers[action.type] ? handlers[action.type]() : state;
}
__name(formReducer, "formReducer");
function getInputElement(element) {
  if (isInputEvent(element)) {
    return element;
  }
  throw new Error("Could not verify element");
}
__name(getInputElement, "getInputElement");
function isInputEvent(element) {
  if (element && element.target) {
    if (typeof element.target.id === "string") {
      return true;
    }
  }
  throw new Error("Element should have an id");
}
__name(isInputEvent, "isInputEvent");

// src/components/form/input-fields/Groups.tsx
import { useState as useState2 } from "react";

// src/components/form/input-fields/Input.tsx
import { useState } from "react";

// src/app/domains/modes/mode-8/styles.tsx
var labelStyle = {
  display: "flex"
};
var inputLabelStyle = __spreadProps(__spreadValues({}, labelStyle), {
  flexDirection: "column",
  padding: "10px"
});
var radioLabelStyle = __spreadProps(__spreadValues({}, labelStyle), {
  flexDirection: "row-reverse",
  justifyContent: "flex-end",
  alignItems: "center",
  margin: "10px"
  // marginLeft: '0px',
});
function getLabelStyle(p) {
  if (p.type === "radio") {
    return __spreadValues({}, radioLabelStyle);
  }
  return inputLabelStyle;
}
__name(getLabelStyle, "getLabelStyle");
function getInputStyle(p) {
  if (p.type === "radio") {
    return {
      width: "20px",
      height: "20px",
      backgroundColor: "red",
      alignItems: "center"
    };
  }
  return {
    width: "100%",
    justifyContent: "center",
    alignItems: "center"
  };
}
__name(getInputStyle, "getInputStyle");

// src/components/form/input-fields/Input.tsx
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
var InputError = /* @__PURE__ */ __name((props) => {
  const { error } = props;
  return error ? /* @__PURE__ */ jsx("span", { style: { color: "red" }, children: error }) : /* @__PURE__ */ jsx("span", { children: "\xA0" });
}, "InputError");
function InputWithLabel(props) {
  const { inputProps, handleInvalid, labelStyle: labelStyle2, field, inputStyle } = props;
  const [error, setError] = useState();
  const getError = handleInvalid ? handleInvalid : defaultHandleValidation;
  return /* @__PURE__ */ jsx("div", { children: /* @__PURE__ */ jsxs(
    "label",
    {
      style: labelStyle2,
      htmlFor: field.element.id,
      title: field.label,
      children: [
        /* @__PURE__ */ jsxs("span", { style: { display: "flex", justifyContent: "space-between" }, children: [
          field.label,
          " ",
          /* @__PURE__ */ jsx(InputError, { error })
        ] }),
        /* @__PURE__ */ jsx(
          InputWithStyle,
          {
            inputStyle,
            inputProps: __spreadProps(__spreadValues(__spreadValues({}, field.element), inputProps), {
              onInvalid: (event) => {
                event.preventDefault();
                setError(
                  getError({
                    valid: false,
                    error: field.error.onValidity
                  })
                );
              },
              onBlur: (event) => {
                setError(
                  getError({
                    valid: event.currentTarget.validity.valid,
                    error: field.error.onChange
                  })
                );
              },
              onChange: (event) => {
                setError(
                  getError({
                    // valid: event.target.validity.valid,
                    valid: event.currentTarget.validity.valid,
                    error: field.error.onChange
                  })
                );
                const onEvent = inputProps["onChange"];
                onEvent && onEvent(event);
              }
            })
          }
        )
      ]
    },
    field.element.id
  ) });
  function defaultHandleValidation(params) {
    const { valid, error: error2 } = params;
    if (valid) {
      return null;
    } else {
      return error2;
    }
  }
  __name(defaultHandleValidation, "defaultHandleValidation");
}
__name(InputWithLabel, "InputWithLabel");
var InputWithStyle = /* @__PURE__ */ __name((props) => {
  const { inputProps, inputStyle } = props;
  const type = inputProps.type;
  return /* @__PURE__ */ jsx(
    "input",
    __spreadProps(__spreadValues({
      style: __spreadValues({}, inputStyle)
    }, inputProps), {
      onKeyDown: (event) => handleKeyDown(event, type)
    })
  );
}, "InputWithStyle");
function handleKeyDown(event, type) {
  if (type === "number") {
    preventNonNumericOnNumberInput(event);
  }
}
__name(handleKeyDown, "handleKeyDown");
function preventNonNumericOnNumberInput(event) {
  const isBackspace = event.key === "Backspace";
  if (Number.isNaN(parseInt(event.key)) && !isBackspace) {
    event.preventDefault();
  }
}
__name(preventNonNumericOnNumberInput, "preventNonNumericOnNumberInput");
function Input(props) {
  const { inputProps, options, handleValidity } = props;
  return /* @__PURE__ */ jsx(Fragment, { children: options == null ? void 0 : options.map((input) => /* @__PURE__ */ jsx(
    InputWithLabel,
    {
      labelStyle: getLabelStyle({ type: input.element.type }),
      inputStyle: getInputStyle({ type: input.element.type }),
      field: input,
      inputProps,
      handleInvalid: handleValidity
    },
    input.element.id
  )) });
}
__name(Input, "Input");

// src/components/form/input-fields/Groups.tsx
import { Fragment as Fragment2, jsx as jsx2, jsxs as jsxs2 } from "react/jsx-runtime";
function isGroupError(group) {
  if (group) {
    return group.error.onValidity;
  }
  throw new Error("A group error is needed");
}
__name(isGroupError, "isGroupError");
function GroupError(p) {
  return p.groupError ? /* @__PURE__ */ jsx2(
    "span",
    {
      style: {
        color: "red"
      },
      children: p.groupError
    }
  ) : /* @__PURE__ */ jsx2("span", { children: "\xA0 " });
}
__name(GroupError, "GroupError");
function GroupLabelWithError(p) {
  return /* @__PURE__ */ jsxs2("label", { style: { display: "flex", justifyContent: "space-between" }, children: [
    p.groupName,
    " ",
    /* @__PURE__ */ jsx2(GroupError, { groupError: p.groupError })
  ] });
}
__name(GroupLabelWithError, "GroupLabelWithError");
var Group = /* @__PURE__ */ __name((props) => {
  var _a, _b;
  const { inputSet, inputProps } = props;
  const [groupError, setGroupError] = useState2(null);
  return /* @__PURE__ */ jsxs2(Fragment2, { children: [
    inputSet.group && /* @__PURE__ */ jsx2(
      GroupLabelWithError,
      {
        groupName: (_a = inputSet.group) == null ? void 0 : _a.name,
        groupError
      }
    ),
    /* @__PURE__ */ jsx2(
      Input,
      {
        options: (_b = inputSet.group) == null ? void 0 : _b.options,
        inputProps,
        handleValidity: (props2) => {
          handleGroupValidity(props2.valid);
          return null;
        }
      },
      inputSet.fieldSetId
    )
  ] });
  function handleGroupValidity(valid) {
    if (valid) {
      setGroupError(null);
    } else {
      setGroupError(isGroupError(inputSet.group));
    }
  }
  __name(handleGroupValidity, "handleGroupValidity");
}, "Group");

// src/components/form/getFieldsData.tsx
function getFieldsData(inputCollections, event) {
  return inputCollections.map((inputCollection) => {
    var _a, _b;
    if (isGroupCollection(inputCollection)) {
      return (_a = inputCollection.group.options) == null ? void 0 : _a.map((input) => doMap(input, event)).filter((e) => e != null);
    } else {
      return (_b = inputCollection.options) == null ? void 0 : _b.map((input) => doMap(input, event)).filter((e) => e != null);
    }
  }).filter((e) => e != null);
}
__name(getFieldsData, "getFieldsData");
function doMap(input, event) {
  const elementFound = Object.values(event.currentTarget.elements).find(
    (element) => element.id === input.element.id
  );
  if (elementFound && elementFound.id === input.element.id) {
    return __spreadProps(__spreadValues({}, input), {
      submitted: __spreadValues({}, elementFound)
    });
  }
  return null;
}
__name(doMap, "doMap");

// src/components/form/Form.tsx
import { jsx as jsx3, jsxs as jsxs3 } from "react/jsx-runtime";
function MyForm8(props) {
  const { label, setFormData, setSubmitted, formFields } = props;
  const { fields, formId } = formFields;
  return /* @__PURE__ */ jsxs3("div", { style: { display: "flex", flexDirection: "column", width: "300px" }, children: [
    /* @__PURE__ */ jsx3("label", { htmlFor: formId, children: label }),
    /* @__PURE__ */ jsxs3(
      "form",
      __spreadProps(__spreadValues({}, {
        onReset: handleReset,
        onSubmit: handleSubmit,
        onInvalid: handleInvalid,
        onChange: handleFormChange
      }), {
        children: [
          fields.map((inputSet, index) => /* @__PURE__ */ jsx3("fieldset", { children: isGroupCollection(inputSet) ? /* @__PURE__ */ jsx3(
            Group,
            {
              inputSet,
              inputProps: { onChange: handleChange }
            }
          ) : /* @__PURE__ */ jsx3(
            Input,
            {
              options: inputSet.options,
              inputProps: { onChange: handleChange }
            }
          ) }, index)),
          /* @__PURE__ */ jsx3("button", { type: "submit", children: "Submit" }),
          /* @__PURE__ */ jsx3("button", { type: "reset", children: "Reset" })
        ]
      })
    )
  ] });
  function getFieldFromId(id, formFields2) {
    for (const fieldSet of formFields2.fields) {
      if (isGroupCollection(fieldSet)) {
        const found = fieldSet.group.options.find(
          (option) => option.element.id === id
        );
        if (found)
          return found;
      } else {
        const found = fieldSet.options.find(
          (option) => option.element.id === id
        );
        if (found)
          return found;
      }
    }
    return void 0;
  }
  __name(getFieldFromId, "getFieldFromId");
  function handleFormChange(event) {
    setFormData({ type: "change" /* change */, payload: event });
  }
  __name(handleFormChange, "handleFormChange");
  function handleChange(event) {
    console.warn("id", event.currentTarget);
    const yy = getFieldFromId(event.currentTarget.id, formFields);
    console.log("yy", yy);
  }
  __name(handleChange, "handleChange");
  function handleSubmit(event) {
    event.preventDefault();
    setSubmitted(getFieldsData(fields, event));
  }
  __name(handleSubmit, "handleSubmit");
  function handleReset(event) {
    setFormData({ type: "reset" /* reset */, payload: event });
  }
  __name(handleReset, "handleReset");
  function handleInvalid(event) {
    console.warn("INVALID", event.target.validationMessage);
  }
  __name(handleInvalid, "handleInvalid");
}
__name(MyForm8, "MyForm8");

// ../../packages/components/dist/index.mjs
import { jsx as jsx4 } from "react/jsx-runtime";
import { jsx as jsx22 } from "react/jsx-runtime";
import { jsx as jsx32 } from "react/jsx-runtime";
import { jsx as jsx42 } from "react/jsx-runtime";
import { jsx as jsx5 } from "react/jsx-runtime";
import { jsx as jsx6 } from "react/jsx-runtime";
import { jsx as jsx7 } from "react/jsx-runtime";
import { jsx as jsx8 } from "react/jsx-runtime";
import { jsx as jsx9, jsxs as jsxs4 } from "react/jsx-runtime";
import { jsx as jsx10 } from "react/jsx-runtime";
var __defProp2 = Object.defineProperty;
var __defProps2 = Object.defineProperties;
var __getOwnPropDescs2 = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols2 = Object.getOwnPropertySymbols;
var __hasOwnProp2 = Object.prototype.hasOwnProperty;
var __propIsEnum2 = Object.prototype.propertyIsEnumerable;
var __defNormalProp2 = /* @__PURE__ */ __name((obj, key, value) => key in obj ? __defProp2(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value, "__defNormalProp");
var __spreadValues2 = /* @__PURE__ */ __name((a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp2.call(b, prop))
      __defNormalProp2(a, prop, b[prop]);
  if (__getOwnPropSymbols2)
    for (var prop of __getOwnPropSymbols2(b)) {
      if (__propIsEnum2.call(b, prop))
        __defNormalProp2(a, prop, b[prop]);
    }
  return a;
}, "__spreadValues");
var __spreadProps2 = /* @__PURE__ */ __name((a, b) => __defProps2(a, __getOwnPropDescs2(b)), "__spreadProps");
var __name2 = /* @__PURE__ */ __name((target, value) => __defProp2(target, "name", { value, configurable: true }), "__name");
var __objRest2 = /* @__PURE__ */ __name((source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp2.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols2)
    for (var prop of __getOwnPropSymbols2(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum2.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
}, "__objRest");
var BaseButton = /* @__PURE__ */ __name2((props) => {
  const _a = props, { children } = _a, buttonProps = __objRest2(_a, ["children"]);
  return /* @__PURE__ */ jsx4("button", __spreadProps2(__spreadValues2({}, buttonProps), { style: { backgroundColor: "blue" }, children }));
}, "BaseButton");
var NeutralButton = /* @__PURE__ */ __name2((props) => {
  const _a = props, { children } = _a, buttonProps = __objRest2(_a, ["children"]);
  return /* @__PURE__ */ jsx42(
    BaseButton,
    __spreadProps2(__spreadValues2({}, buttonProps), {
      style: {
        backgroundColor: "red"
      },
      children
    })
  );
}, "NeutralButton");
function WrapDirection(props) {
  const { id, style, children } = props;
  return /* @__PURE__ */ jsx5("div", { id, style, children });
}
__name(WrapDirection, "WrapDirection");
__name2(WrapDirection, "WrapDirection");
function Center(props) {
  const _a = props, { id, children } = _a, rest = __objRest2(_a, ["id", "children"]);
  return /* @__PURE__ */ jsx6(
    WrapDirection,
    {
      id,
      style: __spreadValues2({
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        // display: grid;
        placeItems: "center",
        height: "100%"
      }, rest),
      children
    }
  );
}
__name(Center, "Center");
__name2(Center, "Center");
function Column(p) {
  const { id, children, style } = p;
  return /* @__PURE__ */ jsx7(
    WrapDirection,
    {
      id,
      style: __spreadValues2({
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column"
      }, style),
      children
    }
  );
}
__name(Column, "Column");
__name2(Column, "Column");
function Row(props) {
  const { id, children, style } = props;
  return /* @__PURE__ */ jsx8(
    WrapDirection,
    {
      id,
      style: __spreadValues2({
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "row"
      }, style),
      children
    }
  );
}
__name(Row, "Row");
__name2(Row, "Row");
var Modal = /* @__PURE__ */ __name2((p) => {
  const showStyle = p.show ? __spreadValues2(__spreadValues2({}, modal), displayBlock) : __spreadValues2(__spreadValues2({}, modal), displayNone);
  return /* @__PURE__ */ jsx9("div", { style: showStyle, children: /* @__PURE__ */ jsxs4("section", { style: __spreadValues2({}, modalMain), children: [
    p.children,
    /* @__PURE__ */ jsx9("button", { type: "button", onClick: p.handleClose, children: "Close" })
  ] }) });
}, "Modal");
var modalMain = {
  position: "fixed",
  background: "white",
  width: "80%",
  height: "50%",
  top: "50%",
  left: "50%",
  transform: "translate(-50%,-50%)"
};
var modal = {
  position: "fixed",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  background: "rgba(0, 0, 0, 0.6)"
};
var displayBlock = {
  display: "block"
};
var displayNone = {
  display: "none"
};
var Switch = /* @__PURE__ */ __name2((p) => {
  return /* @__PURE__ */ jsx10("div", { children: /* @__PURE__ */ jsx10(
    "input",
    {
      className: `switch ${p.enabled ? "on" : "off"}`,
      style: {
        width: "3rem",
        background: "none"
      },
      step: 0.1,
      min: 0,
      max: 1,
      value: p.enabled ? 1 : 0,
      onChange: () => null,
      onClick: p.onChange,
      type: "range",
      children: p.children
    }
  ) });
}, "Switch");

// src/components/inputs/fields/FieldDate.tsx
import moment from "moment";
import { jsx as jsx11 } from "react/jsx-runtime";
var oneYearAgo = moment(Date.now()).subtract(1, "year").valueOf();
var fieldDate = {
  element: {
    id: "date",
    name: "date",
    type: "date",
    required: true,
    defaultValue: moment(oneYearAgo).format("YYYY-MM-DD")
  },
  label: "date",
  error: {
    onChange: "onchange",
    onValidity: "validity"
  }
};

// src/app/domains/modes/mode-8/fields.ts
var fieldFirstName = {
  element: {
    id: "firstName",
    name: "firstName",
    type: "text",
    required: true
  },
  label: "First name",
  error: {
    onChange: "onchange",
    onValidity: "validity"
  }
};
var fieldTheNumber = /* @__PURE__ */ __name((params) => {
  const { id, label, value } = params;
  return {
    element: {
      id,
      name: id,
      type: "number",
      required: true,
      defaultValue: value
      // pattern: 'd{4}',
    },
    label,
    error: {
      onChange: "onchange",
      onValidity: "validity"
    }
  };
}, "fieldTheNumber");
var fieldSlide = /* @__PURE__ */ __name((params) => {
  const { id, label } = params;
  return {
    element: {
      id,
      name: id,
      type: "range",
      min: 0,
      max: 10,
      step: 0.1,
      defaultValue: 5,
      required: true
    },
    label,
    error: {
      onChange: "onchange",
      onValidity: "validity"
    }
  };
}, "fieldSlide");
var radio1 = {
  element: {
    id: "radio-1",
    name: "radio-option",
    type: "radio",
    required: true
    // defaultValue: 'radio-val-1',
  },
  label: "label-1",
  error: {
    onChange: "onchange",
    onValidity: "validity"
  }
};
var radio2 = {
  element: {
    id: "radio-2",
    name: "radio-option",
    type: "radio",
    required: true
    // defaultValue: 'radio-val-2',
  },
  label: "label-2",
  error: {
    onChange: "onchange",
    onValidity: "validity"
  }
};
var radio3 = {
  element: {
    id: "radio-3",
    name: "radio-option",
    type: "radio",
    required: true
    // defaultValue: 'radio-val-3',
  },
  label: "label-3",
  error: {
    onChange: "onchange",
    onValidity: "validity"
  }
};
var radio4 = {
  element: {
    id: "radio-4",
    name: "radio-option-2",
    type: "radio",
    required: true
    // defaultValue: 'radio-val-3',
  },
  label: "label-4",
  error: {
    onChange: "onchange",
    onValidity: "validity"
  }
};
var fieldsA = {
  fieldSetId: "input-fieldset",
  options: [
    radio4,
    fieldDate,
    fieldSlide({ id: "id-1", label: "label-1", value: void 0 }),
    fieldSlide({ id: "id-2", label: "label-2", value: void 0 }),
    fieldFirstName,
    fieldTheNumber({ id: "id-number", label: "label-number", value: 8 })
  ]
};
var fieldsB = {
  fieldSetId: "input-fieldset",
  options: [
    radio4,
    fieldDate,
    fieldSlide({ id: "id-1", label: "label-1", value: void 0 }),
    fieldSlide({ id: "id-2", label: "label-2", value: void 0 }),
    fieldFirstName,
    fieldTheNumber({
      id: "id-number",
      label: "label-number",
      value: void 0
    })
  ]
};
var radiosA = {
  fieldSetId: "radio-fieldset",
  group: {
    name: "Group Radio",
    error: {
      onValidity: "Select one option"
    },
    options: [radio1, radio2, radio3]
  }
  // options: [radio1, radio2, radio3],
};

// src/components/form/FormState.tsx
import { Fragment as Fragment3, jsx as jsx12, jsxs as jsxs5 } from "react/jsx-runtime";
var name = "FormState";
function FormState(p) {
  return /* @__PURE__ */ jsxs5(Fragment3, { children: [
    /* @__PURE__ */ jsx12("label", { children: name }),
    /* @__PURE__ */ jsx12("fieldset", { style: { width: 250 }, children: /* @__PURE__ */ jsx12("pre", { children: p.formData && JSON.stringify(p.formData, null, 2) }) })
  ] });
}
__name(FormState, "FormState");

// src/components/form/SubmittedData.tsx
import { Fragment as Fragment4, jsx as jsx13, jsxs as jsxs6 } from "react/jsx-runtime";
function SubmittedData(p) {
  const lengths = p.inputCollection.map(
    (e) => e.map((e2) => {
      var _a, _b;
      return ((_b = (_a = e2.element) == null ? void 0 : _a.name) == null ? void 0 : _b.length) || 0;
    })
  );
  return /* @__PURE__ */ jsxs6(Fragment4, { children: [
    /* @__PURE__ */ jsx13("label", { children: SubmittedData.idName }),
    /* @__PURE__ */ jsx13("fieldset", { style: { width: 300 }, children: /* @__PURE__ */ jsx13("pre", { children: p.inputCollection.map((inputs) => {
      return inputs.map((input) => {
        var _a, _b;
        return /* @__PURE__ */ jsxs6(
          "div",
          {
            style: {
              display: "flex"
            },
            children: [
              /* @__PURE__ */ jsx13(
                "div",
                {
                  style: {
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center"
                  },
                  children: input.element.type === "radio" ? ((_a = input.submitted) == null ? void 0 : _a.checked) ? /* @__PURE__ */ jsxs6(Fragment4, { children: [
                    /* @__PURE__ */ jsx13(
                      Label,
                      {
                        name: input.element.name,
                        type: guardType(input.element.type),
                        length: getMaxFromNested({ lengths })
                      }
                    ),
                    /* @__PURE__ */ jsx13("span", { children: input.element.id })
                  ] }) : null : /* @__PURE__ */ jsxs6(Fragment4, { children: [
                    /* @__PURE__ */ jsx13(
                      Label,
                      {
                        name: input.element.name,
                        type: guardType(input.element.type),
                        length: getMaxFromNested({ lengths })
                      }
                    ),
                    /* @__PURE__ */ jsx13("span", { children: (_b = input.submitted) == null ? void 0 : _b.value })
                  ] })
                }
              ),
              /* @__PURE__ */ jsx13("br", {})
            ]
          },
          input.element.id
        );
      });
    }) }) })
  ] });
}
__name(SubmittedData, "SubmittedData");
SubmittedData.idName = "submitted-data";
function guardType(type) {
  if (typeof type === "string") {
    return type;
  }
  throw new Error("Type should be string");
}
__name(guardType, "guardType");
function getRemainingLength(p) {
  var _a;
  return Array.from(Array(p.maxLength - (((_a = p.name) == null ? void 0 : _a.length) || 0)).keys());
}
__name(getRemainingLength, "getRemainingLength");
function padRemaining(p) {
  return p.lengths.map((_) => ".").join("");
}
__name(padRemaining, "padRemaining");
function Label(p) {
  const remainig = getRemainingLength({
    maxLength: p.length,
    name: p.name
  });
  const pads = padRemaining({
    lengths: remainig
  });
  return /* @__PURE__ */ jsxs6(
    "label",
    {
      style: __spreadProps(__spreadValues({}, getLabelStyle({
        type: p.type
      })), {
        display: "flex"
      }),
      children: [
        p.name + pads,
        ": ",
        p.children
      ]
    }
  );
}
__name(Label, "Label");
function flattenNested(items) {
  return [...items.reduce((flat, val) => flat.concat(val), [])];
}
__name(flattenNested, "flattenNested");
function getMaxFromNested(p) {
  return Math.max(...flattenNested(p.lengths));
}
__name(getMaxFromNested, "getMaxFromNested");

// src/app/AppProvider.tsx
import { Provider } from "react-redux";

// src/redux/store.ts
import {
  combineReducers,
  configureStore
} from "@reduxjs/toolkit";
import { reducer as themeReducer } from "@mono/styles";

// src/redux/state/other/slice.ts
import { createSlice } from "@reduxjs/toolkit";

// src/redux/state/other/name.ts
var name2 = "other";

// src/redux/state/other/state.ts
function getState() {
  return {
    value: null,
    status: "initial" /* initial */
  };
}
__name(getState, "getState");

// src/redux/state/other/slice.ts
var slice = createSlice({
  name: name2,
  initialState: getState(),
  reducers: {
    setOther: (state, action) => {
      const newBrightness = action.payload.other;
      state.value = newBrightness;
    }
  }
});
var { setOther } = slice.actions;
var { reducer } = slice;

// src/app/domains/modes/mode-8/state/form-state/slice.ts
import { createSlice as createSlice2 } from "@reduxjs/toolkit";

// src/app/domains/modes/mode-8/state/form-state/name.ts
var name3 = "form-state";

// src/app/domains/modes/mode-8/state/form-state/state.ts
function getState2() {
  return {
    value: {},
    status: "initial" /* initial */
  };
}
__name(getState2, "getState");

// src/app/domains/modes/mode-8/state/form-state/slice.ts
var slice2 = createSlice2({
  name: name3,
  initialState: getState2(),
  reducers: {
    setForm: (state, action) => {
      const data = action.payload.data;
      const formId = action.payload.formId;
      state.value[formId] = data;
    }
  }
});
var { setForm } = slice2.actions;
var { reducer: reducer2 } = slice2;

// src/redux/store.ts
var rootReducer = combineReducers({
  other: reducer,
  theme: themeReducer,
  form: reducer2
});
var store = configureStore({
  reducer: rootReducer
});

// src/components/brightness/BrightnessButtons.tsx
import { Brightness, ThemeEnum, setBrightness } from "@mono/styles";

// ../../packages/components/src/buttons/base/Base.tsx
import { jsx as jsx14 } from "react/jsx-runtime";
var BaseButton2 = /* @__PURE__ */ __name((props) => {
  const _a = props, { children } = _a, buttonProps = __objRest(_a, ["children"]);
  return /* @__PURE__ */ jsx14("button", __spreadProps(__spreadValues({}, buttonProps), { style: { backgroundColor: "blue" }, children }));
}, "BaseButton");

// ../../packages/components/src/buttons/neutral/Neutral.tsx
import { jsx as jsx15 } from "react/jsx-runtime";
var NeutralButton2 = /* @__PURE__ */ __name((props) => {
  const _a = props, { children } = _a, buttonProps = __objRest(_a, ["children"]);
  return /* @__PURE__ */ jsx15(
    BaseButton2,
    __spreadProps(__spreadValues({}, buttonProps), {
      style: {
        backgroundColor: "red"
      },
      children
    })
  );
}, "NeutralButton");

// package.json
var package_default = {
  name: "@mono/form",
  publicName: "0011000-form",
  private: true,
  version: "0.0.0",
  main: "./dist/lib/index.js",
  module: "./dist/lib/index.mjs",
  types: "./dist/lib/index.d.ts",
  files: [
    "dist/lib/**"
  ],
  scripts: {
    dev: "vite",
    test: "VITE_WS_PORT=103 vitest --run",
    build: "pnpm run build:app && pnpm run build:lib",
    "build:app": "tsc && vite build",
    "build:lib": "pnpm run clean:build && tsup",
    "clean:build": "rm -rf dist/lib",
    lint: "eslint . --ext ts,tsx --report-unused-disable-directives --max-warnings 0",
    preview: "vite preview"
  },
  dependencies: {
    "@mono/utils": "workspace:*",
    "@mono/aaa": "workspace:*",
    "@mono/fonts": "workspace:*",
    "@mono/styles": "workspace:*",
    "@mono/display-clock": "workspace:*",
    "@reduxjs/toolkit": "2.2.3",
    "@storybook/react": "8.0.8",
    "@storybook/test": "8.0.8",
    "@svgr/plugin-jsx": "8.1.0",
    "@testing-library/jest-dom": "6.4.2",
    "@types/lodash.clonedeep": "4.5.9",
    csstype: "3.1.3",
    "date-fns": "3.6.0",
    "esbuild-plugin-svgr": "2.1.0",
    "lodash.clonedeep": "4.5.0",
    moment: "2.30.1",
    react: "^18.2.0",
    "react-dom": "^18.2.0",
    "react-inlinesvg": "4.1.3",
    "react-redux": "9.1.1",
    "react-router-dom": "6.22.3",
    "styled-components": "6.1.8",
    "vite-plugin-checker": "0.6.4",
    "vite-plugin-svgr": "4.2.0",
    vitest: "1.3.1"
  },
  devDependencies: {
    "@mono/components": "workspace:*",
    "@mono/eslint-config": "workspace:*",
    "@mono/tsconfig": "workspace:*",
    "@types/react": "^18.2.66",
    "@types/react-dom": "^18.2.22",
    "@typescript-eslint/eslint-plugin": "^7.2.0",
    "@typescript-eslint/parser": "^7.2.0",
    "@vitejs/plugin-react": "^4.2.1",
    eslint: "^8.57.0",
    "eslint-plugin-react-hooks": "^4.6.0",
    "eslint-plugin-react-refresh": "^0.4.6",
    tsup: "8.0.2",
    typescript: "^5.2.2",
    vite: "^5.2.0"
  }
};

// src/constants/constants.ts
var APP_NAME = package_default.publicName;

// src/redux/hooks.ts
import { useDispatch, useSelector } from "react-redux";
var useAppDispatch = useDispatch;
var useAppSelector = useSelector;

// src/components/brightness/BrightnessButtons.tsx
import { Fragment as Fragment5, jsx as jsx16, jsxs as jsxs7 } from "react/jsx-runtime";
var BrightnessButtons = /* @__PURE__ */ __name(() => {
  const dispatch = useAppDispatch();
  return /* @__PURE__ */ jsxs7(Fragment5, { children: [
    /* @__PURE__ */ jsx16(
      NeutralButton2,
      {
        onClick: () => dispatch(
          setBrightness({
            appName: APP_NAME,
            brightness: Brightness.bright,
            theme: ThemeEnum.red
          })
        ),
        children: "Bright"
      }
    ),
    /* @__PURE__ */ jsx16(
      NeutralButton2,
      {
        onClick: () => dispatch(
          setBrightness({
            appName: APP_NAME,
            brightness: Brightness.dark,
            theme: ThemeEnum.red
          })
        ),
        children: "Dark"
      }
    )
  ] });
}, "BrightnessButtons");

// src/redux/state/OtherButtons.tsx
import { Fragment as Fragment6, jsx as jsx17, jsxs as jsxs8 } from "react/jsx-runtime";
var OtherButtons = /* @__PURE__ */ __name(() => {
  const dispatch = useAppDispatch();
  const other = useAppSelector((state) => state.other);
  return /* @__PURE__ */ jsxs8(Fragment6, { children: [
    /* @__PURE__ */ jsx17(NeutralButton, { onClick: () => dispatch(setOther({ other: "string-1" })), children: "--1" }),
    /* @__PURE__ */ jsx17(NeutralButton, { onClick: () => dispatch(setOther({ other: "string-2" })), children: "--2" }),
    /* @__PURE__ */ jsx17("div", { children: other.value })
  ] });
}, "OtherButtons");

// src/app/AppProvider.tsx
import { ThemeProviderWrapper } from "@mono/styles";
import { jsx as jsx18, jsxs as jsxs9 } from "react/jsx-runtime";
var StoreProvider = /* @__PURE__ */ __name((props) => {
  const { children } = props;
  return /* @__PURE__ */ jsx18(Provider, { store, children });
}, "StoreProvider");
function AppProvider(p) {
  return (
    // <ThemeStoreProvider>
    // <ThemeProviderWrapper2 appName={APP_NAME}>
    /* @__PURE__ */ jsx18(StoreProvider, { children: /* @__PURE__ */ jsxs9(ThemeProviderWrapper, { appName: APP_NAME, children: [
      /* @__PURE__ */ jsx18(BrightnessButtons, {}),
      /* @__PURE__ */ jsx18(OtherButtons, {}),
      p.children
    ] }) })
  );
}
__name(AppProvider, "AppProvider");

// src/app/domains/modes/mode-8/Mode8.tsx
import { jsx as jsx19, jsxs as jsxs10 } from "react/jsx-runtime";
var Mode8With = /* @__PURE__ */ __name(() => {
  return /* @__PURE__ */ jsx19(AppProvider, { children: /* @__PURE__ */ jsx19(Mode8, {}) });
}, "Mode8With");
function Mode8() {
  const form_1 = {
    name: "Form-1",
    formId: "form-id-1",
    fields: [fieldsA, radiosA]
  };
  const form_2 = {
    name: "Form-2",
    formId: "form-id-2",
    fields: [fieldsB, radiosA]
  };
  const forms = [form_1, form_2];
  return /* @__PURE__ */ jsx19("div", { style: { display: "flex" }, children: forms.map((aForm, index) => /* @__PURE__ */ jsx19(Mode8_, { aForm }, index)) });
}
__name(Mode8, "Mode8");
function Mode8_(props) {
  const { aForm } = props;
  const { formId } = aForm;
  const [formData, setFormData] = useReducer(formReducer, {});
  const [submitted, setSubmitted] = useState3([]);
  const dispatch = useAppDispatch();
  const debouncedFormData = useDebounce(formData, 100);
  useEffect(() => {
    dispatch(setForm({ formId, data: debouncedFormData }));
  }, [dispatch, debouncedFormData, formId]);
  return /* @__PURE__ */ jsxs10("div", { style: { padding: 10, fontFamily: fonts.font6 }, children: [
    /* @__PURE__ */ jsx19(MyComponent, {}),
    /* @__PURE__ */ jsxs10("div", { style: { padding: 10, display: "flex", flexDirection: "column" }, children: [
      /* @__PURE__ */ jsx19(
        MyForm8,
        {
          label: aForm.name,
          formFields: aForm,
          setFormData: (x) => {
            setFormData(x);
          },
          setSubmitted
        }
      ),
      /* @__PURE__ */ jsx19("div", { children: /* @__PURE__ */ jsx19(FormState, { formData }) }),
      /* @__PURE__ */ jsx19("div", { children: /* @__PURE__ */ jsx19(SubmittedData, { inputCollection: submitted || [] }) })
    ] })
  ] });
}
__name(Mode8_, "Mode8_");
function MyComponent() {
  const [show, setShow] = useState3(false);
  const [switchState, setSwitchState] = useState3(false);
  return /* @__PURE__ */ jsxs10("div", { children: [
    /* @__PURE__ */ jsx19("button", { onClick: () => setShow(!show), children: "modal" }),
    /* @__PURE__ */ jsx19(Modal, { show, handleClose: () => setShow(false), children: /* @__PURE__ */ jsx19("div", { children: "hejfdsagfsagfsdagfsa" }) }),
    /* @__PURE__ */ jsx19(
      Switch,
      {
        enabled: switchState,
        onChange: () => setSwitchState(!switchState)
      }
    )
  ] });
}
__name(MyComponent, "MyComponent");
export {
  AppProvider,
  Mode8,
  Mode8With
};
