'use client'
import {
  __name,
  __spreadProps,
  __spreadValues
} from "./chunk-3HY23WZZ.mjs";

// src/locale.type.ts
var Locale = /* @__PURE__ */ ((Locale2) => {
  Locale2["sv"] = "sv";
  Locale2["en"] = "en";
  return Locale2;
})(Locale || {});

// src/setup/language/get-language-code.ts
function getLanguageCode() {
  const _navigator = navigator;
  return _navigator.languages && _navigator.languages[0] || _navigator.language || _navigator.userLanguage || "sv" /* sv */;
}
__name(getLanguageCode, "getLanguageCode");

// src/setup/language/get-language-code-without-region-code.ts
function getLanguagesWithoutRegionCode() {
  return getLanguageCode().toLowerCase().split(/[_-]+/)[0];
}
__name(getLanguagesWithoutRegionCode, "getLanguagesWithoutRegionCode");

// src/hooks/use-user-locale.ts
function useUserLocale() {
  return getLanguagesWithoutRegionCode();
}
__name(useUserLocale, "useUserLocale");

// src/LocaleProvider.tsx
import { IntlProvider } from "react-intl";

// src/setup/messages/get-messages.ts
function getMessages(translations) {
  return translations[getLanguagesWithoutRegionCode()] || translations[getLanguageCode()] || translations["en" /* en */];
}
__name(getMessages, "getMessages");

// src/LocaleProvider.tsx
import { jsx } from "react/jsx-runtime";
var LocaleProviderRoot = /* @__PURE__ */ __name((props) => {
  const { locale, defaultLocale: defaultLocale2, messages: messages2, children } = props;
  return /* @__PURE__ */ jsx(
    IntlProvider,
    {
      locale,
      defaultLocale: defaultLocale2,
      messages: messages2,
      children
    }
  );
}, "LocaleProviderRoot");
var defaultLocale = "sv" /* sv */;
var LocaleProvider = /* @__PURE__ */ __name((props) => {
  const { children, translations } = props;
  const locale = useUserLocale();
  const messages2 = getMessages(translations);
  return /* @__PURE__ */ jsx(
    LocaleProviderRoot,
    {
      locale,
      defaultLocale,
      messages: messages2,
      children
    }
  );
}, "LocaleProvider");

// src/hooks/format/formatMessage.tsx
import { useIntl } from "react-intl";
function formatMessage(intl, descriptor, values, options) {
  return intl.formatMessage(descriptor, values, options);
}
__name(formatMessage, "formatMessage");
var useMessage = /* @__PURE__ */ __name(() => {
  const intl = useIntl();
  return (...props) => String(formatMessage(intl, ...props));
}, "useMessage");

// src/messages/common/button.ts
import { defineMessages } from "react-intl";
var button = defineMessages({
  ok: {
    defaultMessage: "OK",
    id: "@locale.common.button.ok"
  },
  cancel: {
    defaultMessage: "Cancel",
    id: "@locale.common.button.cancel"
  },
  back: {
    defaultMessage: "Back",
    id: "@locale.common.button.back"
  },
  continue: {
    defaultMessage: "Continue",
    id: "@locale.common.button.continue"
  },
  yes: {
    defaultMessage: "Yes",
    id: "@locale.common.button.yes"
  },
  no: {
    defaultMessage: "No",
    id: "@locale.common.button.no"
  }
});

// src/messages/theme/theme.ts
import { defineMessages as defineMessages2 } from "react-intl";
var theme = defineMessages2({
  light: {
    defaultMessage: "Light",
    id: "@locale.common.theme.light"
  },
  dark: {
    defaultMessage: "Dark",
    id: "@locale.common.theme.dark"
  }
});

// src/messages/messages.ts
var messages = {
  button,
  theme
};

// src/setup/intl/create-intl-from-locale.ts
import { createIntl, createIntlCache } from "react-intl";
var cache = createIntlCache();
function createIntlFromLocale(params, translations) {
  const messages2 = getMessages(translations);
  return createIntl(
    __spreadProps(__spreadValues({}, params), {
      messages: messages2
    }),
    cache
  );
}
__name(createIntlFromLocale, "createIntlFromLocale");

// src/setup/intl/get-intl.ts
function getIntl(translations, locale) {
  const defaultLocale2 = getLanguagesWithoutRegionCode();
  return createIntlFromLocale(
    {
      locale: locale || defaultLocale2,
      defaultLocale: defaultLocale2
    },
    translations
  );
}
__name(getIntl, "getIntl");

// src/translations/languages/sv.json
var sv_default = {
  "@locale.common.button.back": "Tillbaka",
  "@locale.common.button.cancel": "Avbryt",
  "@locale.common.button.continue": "Forts\xE4tt",
  "@locale.common.button.no": "Nej",
  "@locale.common.button.ok": "Ok",
  "@locale.common.button.yes": "Ja",
  "@locale.common.theme.dark": "M\xF6rk",
  "@locale.common.theme.light": "Ljus",
  "@locale.demo.react-intl.heading.title": "React intl demo",
  "@locale.demo.react-intl.section.body": "Sida <code>React intl demo</code> body",
  "@locale.demo.react-intl.section.title": "Section React intl demo titel"
};

// src/translations/languages/en.json
var en_default = {
  "@locale.common.button.back": "Back",
  "@locale.common.button.cancel": "Cancel",
  "@locale.common.button.continue": "Continue",
  "@locale.common.button.no": "No",
  "@locale.common.button.ok": "OK",
  "@locale.common.button.yes": "Yes",
  "@locale.common.theme.dark": "Dark",
  "@locale.common.theme.light": "Light",
  "@locale.demo.react-intl.heading.title": "React intl demo",
  "@locale.demo.react-intl.section.body": "Page <code>React intl demo</code> body",
  "@locale.demo.react-intl.section.title": "Section React intl demo title"
};

// src/translations/get-locale-files.ts
function getLocaleFiles() {
  return {
    sv: sv_default,
    en: en_default
  };
}
__name(getLocaleFiles, "getLocaleFiles");
export {
  Locale,
  LocaleProvider,
  getIntl,
  getLocaleFiles,
  messages,
  useMessage,
  useUserLocale
};
