'use client'
var __defProp = Object.defineProperty;
var __name = (target, value) => __defProp(target, "name", { value, configurable: true });

// src/redux/status.ts
var Status = /* @__PURE__ */ ((Status2) => {
  Status2["initial"] = "initial";
  Status2["pending"] = "pending";
  Status2["fulfilled"] = "fulfilled";
  Status2["rejected"] = "rejected";
  return Status2;
})(Status || {});

// src/provider/ThemeProvider.tsx
import { ThemeProvider as StyledProvider } from "styled-components";

// src/styles/theme/theme.ts
import Color from "color";
var _baseDark = Color("#111");
var _baseWhite = Color("#eee");
var _baseRed = Color("#E1383D");
var _baseGreen = Color("#62BA47");
var _baseYellow = Color("#FFC726");
var _baseGray = Color("#989898");
var _baseBlue = Color("#007AFF");
var brightBase = _baseWhite.hex();
var brightVariantA = _baseWhite.darken(0.08).hex();
var brightVariantB = _baseWhite.darken(0.28).hex();
var brightVariantC = _baseWhite.darken(0.32).hex();
var brightVariantD = _baseWhite.darken(0.41).hex();
var brightText = _baseDark.hex();
var brightTextSoft = _baseDark.lighten(1).hex();
var darkBase = _baseDark.hex();
var darkVariantA = _baseDark.lighten(0.7).hex();
var darkVariantB = _baseDark.lighten(3.3).hex();
var darkVariantC = _baseDark.lighten(4).hex();
var darkVariantD = _baseDark.lighten(5).hex();
var darkText = _baseWhite.hex();
var darkTextSoft = _baseWhite.darken(1).hex();
var amount = 0.4;
function _themeBright(main) {
  const mainHex = main.hex();
  const mainVariation = main.lighten(amount).hex();
  return {
    colors: {
      corporate: {
        primary: {
          color: mainHex,
          background: mainVariation
        }
      },
      background: {
        base: brightBase,
        variantA: brightVariantB,
        gr0: brightBase,
        gr1: brightVariantA,
        gr2: brightVariantB,
        gr3: brightVariantC,
        gr4: brightVariantD
      },
      text: {
        base: darkVariantA,
        inverted: brightVariantA,
        variantA: darkVariantB,
        text: brightText,
        textSoft: brightTextSoft
      },
      border: {
        secondary: {
          color: darkVariantA
        }
      },
      mark: {
        mark: mainHex
      }
    }
  };
}
__name(_themeBright, "_themeBright");
function _themeDark(main) {
  const mainHex = main.hex();
  const mainVairiantion = main.darken(amount).hex();
  return {
    colors: {
      corporate: {
        primary: {
          color: mainHex,
          background: mainVairiantion
        }
      },
      background: {
        base: darkBase,
        variantA: darkVariantB,
        gr0: darkBase,
        gr1: darkVariantA,
        gr2: darkVariantB,
        gr3: darkVariantC,
        gr4: darkVariantD
      },
      text: {
        base: brightVariantA,
        inverted: darkVariantA,
        variantA: brightVariantB,
        text: darkText,
        textSoft: darkTextSoft
      },
      border: {
        secondary: {
          color: brightVariantA
        }
      },
      mark: {
        mark: mainHex
      }
    }
  };
}
__name(_themeDark, "_themeDark");
var themeBrightRed = _themeBright(_baseRed);
var themeDarkRed = _themeDark(_baseRed);

// src/styles/GlobalStyle.ts
import { createGlobalStyle, css } from "styled-components";
var baseTransition = css`
  transition:
    color 0.2s ease,
    background-color 0.2s ease,
    padding 0.2s ease;
`;
var baseColor = css`
  background-color: ${({ theme }) => theme.colors.background.base};
  color: ${({ theme }) => theme.colors.text.base};
`;
var baseBGColor = css`
  background-color: ${({ theme }) => theme.colors.background.base};
  color: ${({ theme }) => theme.colors.text.base};
`;
var baseColors = css`
  ${baseBGColor}
  ${baseColor}
`;
var baseHover = css`
  &:hover,
  &:hover * {
    background-color: ${({ theme }) => theme.colors.background.variantA};
    color: ${({ theme }) => theme.colors.text.variantA};
  }
`;
var GlobalStyle = createGlobalStyle`
:is(*):not(svg) {
  ${baseTransition}
  /* font-size: 8px; */

}

body {
  ${baseBGColor}
}

button {
  ${baseColors}
  ${baseHover}
}
`;

// src/brightness/type.ts
var Brightness = /* @__PURE__ */ ((Brightness2) => {
  Brightness2["bright"] = "bright";
  Brightness2["dark"] = "dark";
  return Brightness2;
})(Brightness || {});
var ThemeEnum = /* @__PURE__ */ ((ThemeEnum3) => {
  ThemeEnum3["red"] = "red";
  ThemeEnum3["yellow"] = "yellow";
  ThemeEnum3["blue"] = "blue";
  ThemeEnum3["green"] = "green";
  return ThemeEnum3;
})(ThemeEnum || {});

// src/brightness/local-storage.brightness.ts
var key = /* @__PURE__ */ __name((appName) => `${appName}.brightness`, "key");
function writeBrightnessToLocalStorage(params) {
  const { brightness, appName } = params;
  window.localStorage.setItem(key(appName), brightness);
}
__name(writeBrightnessToLocalStorage, "writeBrightnessToLocalStorage");
function readBrightnessFromLocalStorage(params) {
  const { appName } = params;
  return window.localStorage.getItem(key(appName)) || "bright" /* bright */;
}
__name(readBrightnessFromLocalStorage, "readBrightnessFromLocalStorage");

// src/redux/hooks.ts
import { useDispatch, useSelector } from "react-redux";
var useAppDispatch = useDispatch;
var useAppSelector = useSelector;

// src/redux/state/theme/selector.ts
var getBrightness = /* @__PURE__ */ __name((state) => state.theme.value, "getBrightness");

// src/use-brightness.ts
import { useEffect } from "react";

// src/redux/state/theme/slice.ts
import { createSlice } from "@reduxjs/toolkit";

// src/redux/state/theme/name.ts
var name = "brightness";

// src/redux/state/theme/state.ts
function getState() {
  return {
    value: "bright" /* bright */,
    theme: "red" /* red */,
    status: "initial" /* initial */
  };
}
__name(getState, "getState");

// src/brightness/local-storage.theme.ts
var key2 = /* @__PURE__ */ __name((appName) => `${appName}.theme`, "key");
function writeThemeToLocalStorage(params) {
  const { theme, appName } = params;
  window.localStorage.setItem(key2(appName), theme);
}
__name(writeThemeToLocalStorage, "writeThemeToLocalStorage");
function readThemeFromLocalStorage(params) {
  const { appName } = params;
  return window.localStorage.getItem(key2(appName)) || "red" /* red */;
}
__name(readThemeFromLocalStorage, "readThemeFromLocalStorage");

// src/redux/state/theme/slice.ts
var slice = createSlice({
  name,
  initialState: getState(),
  reducers: {
    setBrightness: (state, action) => {
      const newBrightness = action.payload.brightness;
      const newTheme = action.payload.theme;
      const appName = action.payload.appName;
      state.value = newBrightness;
      writeBrightnessToLocalStorage({
        appName,
        brightness: newBrightness
      });
      writeThemeToLocalStorage({
        appName,
        theme: newTheme
      });
    }
  }
});
var { setBrightness } = slice.actions;
var { reducer } = slice;

// src/use-brightness.ts
function useBrightness(appName) {
  const dispatch = useAppDispatch();
  const currentBrightness = useAppSelector(getBrightness);
  const currentLocalStorageBrightness = readBrightnessFromLocalStorage({
    appName
  });
  const currentLocalStorageTheme = readThemeFromLocalStorage({
    appName
  });
  useEffect(() => {
    dispatch(
      setBrightness({
        brightness: currentLocalStorageBrightness,
        theme: currentLocalStorageTheme,
        appName
      })
    );
  }, [
    appName,
    currentLocalStorageBrightness,
    currentLocalStorageTheme,
    dispatch
  ]);
  return currentBrightness;
}
__name(useBrightness, "useBrightness");

// src/provider/ThemeProvider.tsx
import { jsx, jsxs } from "react/jsx-runtime";
function useTheme(appName) {
  const currentBrightness = useBrightness(appName);
  const theme = currentBrightness === "dark" /* dark */ ? themeDarkRed : themeBrightRed;
  return theme;
}
__name(useTheme, "useTheme");
var ThemeProviderWrapper = /* @__PURE__ */ __name((props) => {
  const { children, appName } = props;
  const theme = useTheme(appName);
  return /* @__PURE__ */ jsxs(ThemeProvider, { theme, children: [
    /* @__PURE__ */ jsx(GlobalStyle, {}),
    children
  ] });
}, "ThemeProviderWrapper");
var ThemeProvider = /* @__PURE__ */ __name((props) => {
  const { theme, children } = props;
  return /* @__PURE__ */ jsx(StyledProvider, { theme, children });
}, "ThemeProvider");
export {
  Brightness,
  GlobalStyle,
  Status,
  ThemeEnum,
  ThemeProvider,
  ThemeProviderWrapper,
  readBrightnessFromLocalStorage,
  readThemeFromLocalStorage,
  reducer,
  setBrightness,
  themeBrightRed as themeBright,
  themeDarkRed as themeDark,
  useBrightness,
  writeBrightnessToLocalStorage,
  writeThemeToLocalStorage
};
