import React from 'react';

import { createRoot } from 'react-dom/client';
import { AppRouter } from './app/AppRouter';

const container = document.getElementById('root');
const root = createRoot(container!); // createRoot(container!) if you use TypeScript

// When you need to use the fonts and the styles
// async function setupFonts() {
//   const fonts = await loadFonts();
//   // Now you can use fonts and the styles are applied
//   console.log(fonts.font1); // Example usage
// }

// setupFonts();
root.render(
  <React.StrictMode>
    <AppRouter />
  </React.StrictMode>
);
